import * as React from "react";
import { useNavigate } from "react-router-dom";

import { TableCell, TableRow } from "@mui/material";

import moment from "moment";
import PropTypes from "prop-types";

export default function Row({ row }) {
  const navigate = useNavigate();

  const d = new Date(row.date);
  const utc = d.getTime() + d.getTimezoneOffset() * 60000;
  const convertedDate = new Date(utc);

  return (
    <TableRow
      hover
      onClick={() => navigate(`/view-incident/${row.id}`)}
      key={row.id}
      style={{ cursor: "pointer" }}
    >
      <TableCell component="th" scope="row">
        {row.id}
      </TableCell>
      <TableCell>{`${moment(convertedDate).format("MM/DD/YYYY")}`}</TableCell>
      <TableCell>{row.type}</TableCell>
      <TableCell>{row.submitter?.username}</TableCell>
      <TableCell>{row.isCompleted ? "Closed" : "Open"}</TableCell>
      <TableCell>
        {row.ownerSiteProject?.site?.name} [{row.ownerSiteProject?.owner?.name}]
      </TableCell>
      <TableCell>{row.ha ? `${row.ha.name} [${row.ha.date}]` : ""}</TableCell>
    </TableRow>
  );
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
};
