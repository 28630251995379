import React from "react";

import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { Grid, Typography, Tooltip, Button } from "@mui/material";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { authMutations } from "../../../../../../graphql/mutations";
import { MUTATE_SUPERVISION } from "../../../../../../graphql/mutations/admin/supervision";
import {
  GET_COMPANY_USERS_SIMPLE,
  GET_OSPs,
} from "../../../../../../graphql/queries/admin/company";
import { GET_SUPERVISORS } from "../../../../../../graphql/queries/admin/supervisors";
import removeDuplicates from "../../../../../../utils/removeDuplicates";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../../../CustomComponents/OnErrorFunction";
import SelectedUnselected from "../../../../../CustomComponents/SelectedUnselectedAdmin";
import { COMPANY_ADMIN_USERS } from "../../../companyAdminQueries";

export default function EditUserSupervision({
  d,
  open,
  setOpen,
  onClose,
  disabled,
}) {
  // Local State
  const [superviseArr, setSuperviseArr] = React.useState([]);
  const [userSup, setUserSup] = React.useState([]);
  const [projectSup, setProjectSup] = React.useState([]);
  const [siteSup, setSiteSup] = React.useState([]);
  const [compSup, setCompSup] = React.useState([]);
  const [selected, setSelected] = React.useState("Companies");
  const [unselectedUserSup, setUnselectedUserSup] = React.useState([]);
  const [unselectedCompSup, setUnselectedCompSup] = React.useState([]);
  const [unselectedSiteSup, setUnselectedSiteSup] = React.useState([]);
  const [unselectedProjectSup, setUnselectedProjectSup] = React.useState([]);

  // Queries
  const { data: osps } = useQuery(GET_OSPs);
  const [getUsers, { data: users }] = useLazyQuery(GET_COMPANY_USERS_SIMPLE);
  const userCompany = d.user ? d.user.company : d.company;
  const { updateLoading } = authMutations;

  React.useEffect(() => {
    if (open && userCompany) {
      getUsers({ variables: { companyId: Number(userCompany.id) } });
    }
  }, [open]);

  // Company, Site, Project Supervision Mutation
  const [mutateSup, { loading }] = useMutation(MUTATE_SUPERVISION, {
    onCompleted() {
      onCompletedFunc("Supervision has been updated.");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [
      { query: GET_SUPERVISORS },
      { query: COMPANY_ADMIN_USERS, variables: { id: Number(userCompany.id) } },
    ],
  });

  React.useEffect(() => {
    updateLoading(loading);
  }, [loading]);

  // CSS for selected Sup tab
  const isSelected = (value) => {
    if (value === selected) {
      return { color: "rgb(255, 183, 0)", cursor: "pointer" };
    } else {
      return { cursor: "pointer" };
    }
  };

  React.useEffect(() => {
    if (d) {
      let supervise = d && d.user ? d.user.supervise : d.supervise;
      setSuperviseArr(supervise ? supervise.filter((s) => s.isActive) : []);
    }
  }, [d]);

  // Set Local State
  React.useEffect(() => {
    if (superviseArr) {
      // Filter supervision by project, site, user, and company(owner)
      const users = [];
      const projects = [];
      const sites = [];
      const companies = [];

      // Loop through and push to arrays
      superviseArr.map((s) => {
        if (s.ownerSiteProject === null && s.supervisee !== null) {
          users.push(s);
        } else if (
          s.ownerSiteProject !== null &&
          s.ownerSiteProject.project !== null
        ) {
          projects.push(s);
        } else if (
          s.ownerSiteProject !== null &&
          s.ownerSiteProject.project === null &&
          s.ownerSiteProject.site !== null
        ) {
          sites.push(s);
        } else {
          companies.push(s);
        }
      });

      setProjectSup(removeDuplicates(projects, "ownerSiteProject", "id"));
      setSiteSup(removeDuplicates(sites, "ownerSiteProject", "id"));
      setCompSup(removeDuplicates(companies, "ownerSiteProject", "id"));
      setUserSup(users);
    }
  }, [d, superviseArr]);

  // Set Local State
  React.useEffect(() => {
    if (osps && superviseArr) {
      // Filter osps by project, site, or company(owner)
      const projects = [];
      const sites = [];
      const companies = [];
      // Loop through and push to arrays
      osps.ownerSiteProjects.forEach((osp) => {
        // Remove already selected first
        if (
          superviseArr.findIndex(
            (s) =>
              s.ownerSiteProject !== null && s.ownerSiteProject?.id === osp.id
          ) < 0
        ) {
          if (osp.isActive && osp.project !== null) {
            projects.push(osp);
          } else if (
            osp.isActive &&
            osp.project === null &&
            osp.site !== null
          ) {
            sites.push(osp);
          } else if (osp.isActive) {
            companies.push(osp);
          }
        }
      });
      setUnselectedProjectSup(projects);
      setUnselectedSiteSup(sites);
      setUnselectedCompSup(companies);
    }
  }, [osps, superviseArr]);

  // Set Local State
  React.useEffect(() => {
    if (users && superviseArr) {
      const filteredUsers = [];

      // Loop through and push to arrays
      users.users.map((user) => {
        // remove already selected first
        if (
          superviseArr.findIndex(
            (s) =>
              s.ownerSiteProject === null &&
              s.supervisee !== null &&
              s.supervisee.id === user.id
          ) < 0
        ) {
          filteredUsers.push(user);
        }
      });
      setUnselectedUserSup(filteredUsers);
    }
  }, [users, superviseArr]);

  const withTypography = (text) => {
    return <Typography style={{ maxWidth: "90%" }}>{text}</Typography>;
  };

  // Check which sections is selected and display correct text
  const renderDisplayText = (co, type) => {
    switch (type) {
      case "Companies":
        return withTypography(
          co.ownerSiteProject
            ? `${co.ownerSiteProject?.owner?.name}`
            : `${co.owner?.name}`
        );
      case "Sites":
        return withTypography(
          co.ownerSiteProject
            ? `${co.ownerSiteProject?.site?.name} [${co.ownerSiteProject?.owner?.name}]`
            : `${co.site?.name} [${co.owner?.name}]`
        );
      case "Projects":
        return withTypography(
          co.ownerSiteProject
            ? `${co.ownerSiteProject?.project?.name} [${co.ownerSiteProject?.site?.name}] [${co.ownerSiteProject?.owner?.name}]`
            : `${co.project?.name} [${co.site?.name}] [${co.owner?.name}]`
        );
      case "Users":
        if (co.supervisee && co.supervisee.firstName !== "") {
          return withTypography(
            co.supervisee
              ? `${co.supervisee?.firstName} ${co.supervisee?.lastName}`
              : `${co.firstName} ${co.lastName}`
          );
        }
        return withTypography(
          co.supervisee ? `${co.supervisee.username}` : `${co.username}`
        );
      default:
        return null;
    }
  };

  const onSave = () => {
    const removeDuplicates = (arr) => {
      const uniqueIds = [];
      const filtered = arr.filter((osp) => {
        const isDuplicate = uniqueIds.includes(osp);
        if (!isDuplicate) {
          uniqueIds.push(osp);
          return true;
        }
        return false;
      });
      return filtered;
    };

    const superviseArr = d && d.user ? d.user.supervise : d.supervise;

    // Filter supervision by project, site, and company(owner)
    const projects = [];
    const sites = [];
    const companies = [];

    // Loop through and push to arrays
    const activeSup = superviseArr
      ? superviseArr.filter((s) => s.isActive)
      : [];

    activeSup.map((s) => {
      if (s.ownerSiteProject !== null && s.ownerSiteProject.project !== null) {
        projects.push(s);
      } else if (
        s.ownerSiteProject !== null &&
        s.ownerSiteProject.project === null &&
        s.ownerSiteProject.site !== null
      ) {
        sites.push(s);
      } else {
        companies.push(s);
      }
    });

    const newAssociatedOSPIds = [...compSup, ...siteSup, ...projectSup].map(
      (add) => Number(add.ownerSiteProject ? add.ownerSiteProject.id : add.id)
    );
    const prevAssociatedOSPIds = [...projects, ...sites, ...companies].map(
      (add) => Number(add.ownerSiteProject ? add.ownerSiteProject.id : add.id)
    );

    const getUserId = (user) => {
      if (user.supervisee) {
        return user.supervisee.id;
      }

      if (user.user) {
        return user.user.id;
      }

      return user.id;
    };

    mutateSup({
      variables: {
        supervisor: d.user ? Number(d.user.id) : Number(d.id),
        supervisees: [...userSup].map((add) => Number(getUserId(add))),
        associatedOSP: newAssociatedOSPIds,
        unassociatedOSP: removeDuplicates(
          prevAssociatedOSPIds.filter((id) => !newAssociatedOSPIds.includes(id))
        ),
      },
    });

    if (onClose) {
      onClose();
    }
  };

  const onDone = () => {
    if (onClose) {
      onClose();
    } else setOpen(false);
  };

  // Check which sections is selected and display select/unselect
  const renderEdits = () => {
    switch (selected) {
      case "Companies":
        return (
          <SelectedUnselected
            selected={compSup}
            unselected={[...unselectedCompSup].sort((a, b) => {
              const aName = a.ownerSiteProject
                ? a.ownerSiteProject.owner?.name
                : a.owner?.name;
              const bName = b.ownerSiteProject
                ? b.ownerSiteProject.owner?.name
                : b.owner?.name;

              return aName > bName ? 1 : -1;
            })}
            setSelected={setCompSup}
            setUnselected={setUnselectedCompSup}
            updateState={() => {}}
            idKey={"Companies"}
            label={"Company Supervision"}
            renderDisplay={renderDisplayText}
            searchItem={(item, searchTerm) => {
              const name = item.ownerSiteProject
                ? item.ownerSiteProject.owner?.name
                : item.owner?.name;

              return name.toLowerCase().includes(searchTerm.toLowerCase());
            }}
            disabled={disabled}
          />
        );
      case "Sites":
        return (
          <SelectedUnselected
            selected={siteSup}
            unselected={[...unselectedSiteSup].sort((a, b) =>
              a.site?.name > b.site?.name ? 1 : -1
            )}
            setSelected={setSiteSup}
            setUnselected={setUnselectedSiteSup}
            updateState={() => {}}
            idKey={"Sites"}
            label={"Site Supervision"}
            renderDisplay={renderDisplayText}
            searchItem={(item, searchTerm) => {
              const name = item.ownerSiteProject
                ? `${item.ownerSiteProject.site?.name} ${item.ownerSiteProject.owner?.name}`
                : `${item.site?.name} ${item.owner?.name}`;

              return name.toLowerCase().includes(searchTerm.toLowerCase());
            }}
            disabled={disabled}
          />
        );
      case "Projects":
        return (
          <SelectedUnselected
            selected={projectSup}
            unselected={[...unselectedProjectSup].sort((a, b) => {
              const aName = a.ownerSiteProject
                ? a.ownerSiteProject.project?.name
                : a.project?.name;

              const bName = b.ownerSiteProject
                ? b.ownerSiteProject.project?.name
                : b.project?.name;

              return aName > bName ? 1 : -1;
            })}
            setSelected={setProjectSup}
            setUnselected={setUnselectedProjectSup}
            updateState={() => {}}
            idKey={"Projects"}
            label={"Project Supervision"}
            renderDisplay={renderDisplayText}
            searchItem={(item, searchTerm) => {
              const name = item.ownerSiteProject
                ? `${item.ownerSiteProject.site?.name} ${item.ownerSiteProject.owner?.name} ${item.ownerSiteProject.project?.name}`
                : `${item.site?.name} ${item.owner?.name} ${item.project?.name}`;

              return name.toLowerCase().includes(searchTerm.toLowerCase());
            }}
            disabled={disabled}
          />
        );
      case "Users":
        return (
          <SelectedUnselected
            selected={userSup}
            unselected={unselectedUserSup.sort((a, b) => {
              let aName = a.supervisee
                ? a.supervisee.username
                : a.user
                ? a.user.username
                : a.username;
              let bName = b.supervisee
                ? b.supervisee.username
                : b.user
                ? b.user.username
                : b.username;

              return aName.toLowerCase() > bName.toLowerCase() ? 1 : -1;
            })}
            setSelected={setUserSup}
            setUnselected={setUnselectedUserSup}
            updateState={() => {}}
            idKey={"Users"}
            label={"User Supervision"}
            renderDisplay={renderDisplayText}
            searchItem={(item, searchTerm) => {
              const name = item.supervisee
                ? item.supervisee.username
                : item.user
                ? item.user.username
                : item.username;

              return name.toLowerCase().includes(searchTerm.toLowerCase());
            }}
            disabled={disabled}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={2}></Grid>

        <Grid item xs={6}>
          <Grid container item xs={12} sx={{ paddingTop: "10px" }}>
            <Grid
              item
              xs={3}
              sx={isSelected("Companies")}
              onClick={() => setSelected("Companies")}
            >
              <Typography sx={{ fontSize: "1.25rem", fontWeight: "bold" }}>
                {compSup.length}
              </Typography>
              <Tooltip title={"Companies"}>
                <Typography sx={{ fontSize: ".75rem", lineHeight: 0.9 }} noWrap>
                  Companies
                </Typography>
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={3}
              sx={isSelected("Sites")}
              onClick={() => setSelected("Sites")}
            >
              <Typography sx={{ fontSize: "1.25rem", fontWeight: "bold" }}>
                {siteSup.length}
              </Typography>
              <Typography sx={{ fontSize: ".75rem", lineHeight: 0.9 }} noWrap>
                Sites
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sx={isSelected("Projects")}
              onClick={() => setSelected("Projects")}
            >
              <Typography sx={{ fontSize: "1.25rem", fontWeight: "bold" }}>
                {projectSup.length}
              </Typography>
              <Tooltip title={"Projects"}>
                <Typography sx={{ fontSize: ".75rem", lineHeight: 0.9 }} noWrap>
                  Projects
                </Typography>
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={3}
              sx={isSelected("Users")}
              onClick={() => setSelected("Users")}
            >
              <Typography sx={{ fontSize: "1.25rem", fontWeight: "bold" }}>
                {userSup.length}
              </Typography>
              <Typography sx={{ fontSize: ".75rem", lineHeight: 0.9 }} noWrap>
                Users
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}></Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={10} sx={{ paddingTop: "30px" }}>
          {renderEdits()}
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
      {/* Buttons */}
      <Grid item xs={12} sx={{ textAlignLast: "end", paddingTop: "50px" }}>
        <LoadingButton
          variant="contained"
          color="yellow0"
          sx={{ mr: 1 }}
          onClick={onSave}
          disabled={disabled}
          loading={loading}
        >
          SAVE
        </LoadingButton>
        <Button
          variant="contained"
          style={{ marginRight: "10px" }}
          onClick={onDone}
        >
          DONE
        </Button>
      </Grid>
    </>
  );
}
