import React from "react";
import { useNavigate } from "react-router-dom";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  Badge,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/styles";

import { useQuery } from "@apollo/client";
import moment from "moment";

import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import useStyles from "./styles";

const ObsItem = ({ obs, currentUser }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const d = new Date(obs.date);
  const utc = d.getTime() + d.getTimezoneOffset() * 60000;
  const convertedDate = new Date(utc);

  let time = new Date();
  const timeArr = obs.time.split(":");
  time.setHours(timeArr[0], timeArr[1], timeArr[2], 0);

  return (
    <>
      <Grid
        style={{
          backgroundColor: "black",
          width: "100%",
          color: "white",
          textAlign: "center",
          borderRadius: 6,
          opacity: "0.75",
          margin: "10px 0px",
          cursor: "pointer",
        }}
        container
        onClick={handleClick}
      >
        <Grid item xs={12} container style={{ padding: "5px 20px" }}>
          <Grid item xs={12}>
            <Typography>
              {obs.observationOutcomes.length > 0
                ? obs.observationOutcomes.map((o) => o.outcome.name).join(", ")
                : "No Outcomes"}{" "}
              | {moment(convertedDate).format("MM/DD/YYYY")}{" "}
              {moment(time).format("hh:mm A")}
            </Typography>
            <Typography>
              <span style={{ fontSize: "0.8rem" }}>SUBMITTED BY:</span>{" "}
              {obs.submitter.username}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={() => navigate(`/view-obs/${obs.obsId}`)}>
          VIEW
        </MenuItem>
        {currentUser?.currentUser.id === obs.submitter.id && (
          <MenuItem onClick={() => navigate(`/edit-obs/${obs.obsId}`)}>
            EDIT
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default function RecentObs({ obs, refetch, loading }) {
  const theme = useTheme();
  const classes = useStyles()();
  const { data: currentUser } = useQuery(GET_CURRENT_USER);

  const [expanded, setExpanded] = React.useState("Today");
  const [today, setToday] = React.useState([]);
  const [yesterday, setYesterday] = React.useState([]);
  const [week, setWeek] = React.useState([]);
  const [sort, setSort] = React.useState("desc");

  const handleChange = (panel) => {
    setExpanded(panel);
  };

  const todayDate = moment(new Date()).format("YYYY-MM-DD");
  const lastWeek = moment(new Date()).subtract(7, "days").format("YYYY-MM-DD");
  const yesterdayDate = moment(new Date())
    .subtract(1, "days")
    .format("YYYY-MM-DD");

  const todayDateFormatted = moment(new Date()).format("ddd MMM D, YYYY");
  const yesterdayDateFormatted = moment(new Date())
    .subtract(1, "days")
    .format("ddd MMM D, YYYY");

  React.useEffect(() => {
    if (obs?.observations?.edges) {
      const obsList = obs.observations.edges.map((e) => e.node);
      setToday(obsList.filter((o) => o.date === todayDate));
      setYesterday(obsList.filter((o) => o.date === yesterdayDate));
      setWeek(obsList.filter((o) => o.date >= lastWeek));
    }
  }, [obs]);

  const changeSort = () => {
    setSort((prev) => (prev === "desc" ? "asc" : "desc"));
  };

  const outcomeOrder = {
    Injury: 1,
    "Unsafe Work Condition": 2,
    "Non-Issue - Requiring Correction": 3,
    Safe: 4,
  };

  const sortItems = (arr) => {
    return arr.sort((a, b) => {
      const aOutcome =
        a.observationOutcomes.length > 0
          ? a.observationOutcomes[0].outcome.name
          : "";
      const bOutcome =
        b.observationOutcomes.length > 0
          ? b.observationOutcomes[0].outcome.name
          : "";

      const aOrder = outcomeOrder[aOutcome] || 1;
      const bOrder = outcomeOrder[bOutcome] || 1;

      if (sort === "desc") {
        return aOrder - bOrder;
      } else {
        return bOrder - aOrder;
      }
    });
  };

  const accordions = [
    {
      name: "Today",
      date: todayDateFormatted,
      data: today,
    },

    {
      name: "Yesterday",
      date: yesterdayDateFormatted,
      data: yesterday,
    },
    {
      name: "Last 7 Days",
      date: "",
      data: week,
    },
  ];

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      className={classes.root}
      style={{
        backgroundColor: "inherit",
        borderRadius: "6px",
        boxShadow: `0px -5px 30px ${theme.palette.grey3.main}`,
        position: "relative",
      }}
    >
      {loading && (
        <Grid
          container
          justifyContent="center"
          style={{ position: "absolute", top: "50%" }}
        >
          <CircularProgress color="secondary" />
        </Grid>
      )}
      <Grid item xs={12} container>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="space-between"
          style={{ marginBottom: "5px" }}
        >
          <Typography
            className={classes.cardTitle}
            sx={{
              fontFamily: "barlowextrabold",
              paddingLeft: "10px",
            }}
          >
            RECENT OBS
          </Typography>
          <Grid item>
            <Tooltip title="Refresh Observations">
              <IconButton onClick={() => refetch()}>
                <RefreshIcon style={{ color: theme.palette.grey2.main }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Sort by Outcomes">
              <IconButton onClick={changeSort}>
                {sort === "desc" ? (
                  <ArrowDownwardIcon
                    style={{ color: theme.palette.grey2.main }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    style={{ color: theme.palette.grey2.main }}
                  />
                )}
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {accordions.map((accordion) => (
            <Accordion
              key={accordion.name}
              expanded={expanded === accordion.name}
              onChange={() => handleChange(accordion.name)}
              disableGutters
              style={{
                backgroundColor: "inherit",
                boxShadow:
                  expanded === accordion.name
                    ? `0px -5px 30px ${theme.palette.grey3.main}`
                    : "",
                color: theme.palette.grey1.main,
              }}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                style={{
                  color:
                    expanded === accordion.name
                      ? theme.palette.yellow0.main
                      : theme.palette.grey1.main,
                  fontFamily: "barlowextrabold",
                }}
              >
                <Badge
                  badgeContent={accordion.data.length}
                  showZero
                  color="secondary"
                >
                  <div
                    style={{
                      width: "90px",
                      border: "1px solid #FFFFFF50",
                      borderRadius: 6,
                      textAlign: "center",
                    }}
                  >
                    {accordion.name}
                  </div>
                </Badge>
                {accordion.date !== "" && (
                  <Grid
                    container
                    alignContent="center"
                    style={{ marginLeft: "20px", fontSize: "12px" }}
                  >
                    [{accordion.date}]
                  </Grid>
                )}
              </AccordionSummary>
              <AccordionDetails style={{ height: "30vh", overflowY: "auto" }}>
                {accordion.data.length === 0 ? (
                  <Grid container justifyContent="center">
                    <Typography>No observations submitted</Typography>
                  </Grid>
                ) : (
                  sortItems(accordion.data).map((obs) => (
                    <ObsItem key={obs.id} obs={obs} currentUser={currentUser} />
                  ))
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
