import React from "react";
import { useNavigate } from "react-router-dom";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Menu,
  MenuItem,
  Tooltip,
  IconButton,
  Badge,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/styles";

import { useQuery } from "@apollo/client";
import moment from "moment";

import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import useStyles from "./styles";

const HAItem = ({ ha, currentUser }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function removeDuplicates(arr) {
    const uniqueIds = [];
    const filtered = arr.filter((item) => {
      let itemId = item;

      const isDuplicate = uniqueIds.includes(itemId);
      if (!isDuplicate) {
        uniqueIds.push(itemId);
        return true;
      }
      return false;
    });
    return filtered;
  }

  return (
    <>
      <Grid
        style={{
          backgroundColor: "black",
          width: "100%",
          color: "white",
          textAlign: "center",
          borderRadius: 6,
          opacity: "0.75",
          margin: "10px 0px",
          cursor: "pointer",
        }}
        container
        onClick={handleClick}
      >
        <Grid item xs={10} container style={{ padding: "5px 20px" }}>
          <Grid item xs={12}>
            <Typography>
              <span style={{ fontWeight: "bold" }}>{ha.name}</span> |{" "}
              <span style={{ fontSize: "0.8rem" }}>SUBMITTED BY:</span>{" "}
              {ha.submitter.username}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <span style={{ fontSize: "0.8rem" }}>SCORE:</span>{" "}
              {Number(ha.raScore).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <span style={{ fontSize: "0.8rem" }}>PROJECTS:</span>{" "}
              {ha.haOwnerSiteProject
                .map((p) =>
                  p.ownerSiteProject.project
                    ? p.ownerSiteProject.project.name
                    : ""
                )
                .join(", ")}
            </Typography>
            <Typography>
              <span style={{ fontSize: "0.8rem" }}>COMPANIES:</span>{" "}
              {removeDuplicates(
                ha.haOwnerSiteProject.map((c) => c.ownerSiteProject.owner.name)
              ).join(", ")}
            </Typography>
            {ha.comment && ha.comment !== "" && (
              <Typography style={{ color: theme.palette.yellow0.main }}>
                Additional Comments Present
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs={2} container alignContent="center">
          {ha.isCompleted ? (
            <LockOutlinedIcon style={{ color: theme.palette.grey3.main }} />
          ) : (
            <LockOpenOutlinedIcon
              style={{ color: theme.palette.yellow0.main }}
            />
          )}
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={() => navigate(`/view-ha/${ha.haId}`)}>
          VIEW
        </MenuItem>
        {currentUser?.currentUser.id === ha.submitter.id && !ha.isCompleted ? (
          <MenuItem onClick={() => navigate(`/edit-ha/${ha.haId}`)}>
            EDIT
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
};

export default function RecentHA({ ha, refetch, loading }) {
  const theme = useTheme();
  const classes = useStyles()();
  const { data: currentUser } = useQuery(GET_CURRENT_USER);

  const [expanded, setExpanded] = React.useState("Today");
  const [today, setToday] = React.useState([]);
  const [yesterday, setYesterday] = React.useState([]);
  const [tomorrow, setTomorrow] = React.useState([]);
  const [sort, setSort] = React.useState("desc");

  const handleChange = (panel) => {
    setExpanded(panel);
  };

  const yesterdayDate = moment(new Date())
    .subtract(1, "days")
    .format("YYYY-MM-DD");
  const todayDate = moment(new Date()).format("YYYY-MM-DD");
  const tomorrowDate = moment(new Date()).add(1, "days").format("YYYY-MM-DD");

  const todayDateFormatted = moment(new Date()).format("ddd MMM D, YYYY");
  const tomorrowDateFormatted = moment(new Date())
    .add(1, "days")
    .format("ddd MMM D, YYYY");
  const yesterdayDateFormatted = moment(new Date())
    .subtract(1, "days")
    .format("ddd MMM D, YYYY");

  React.useEffect(() => {
    if (ha?.hazardAnalyses?.edges) {
      const haList = ha.hazardAnalyses.edges.map((e) => e.node);
      setToday(haList.filter((ha) => ha.date === todayDate));
      setYesterday(haList.filter((ha) => ha.date === yesterdayDate));
      setTomorrow(haList.filter((ha) => ha.date === tomorrowDate));
    }
  }, [ha]);

  const changeSort = () => {
    setSort((prev) => (prev === "desc" ? "asc" : "desc"));
  };

  const sortByScore = (arr) => {
    return arr.sort((a, b) => {
      if (sort === "asc") {
        return b.raScore - a.raScore;
      } else {
        return a.raScore - b.raScore;
      }
    });
  };

  const accordions = [
    {
      name: "Today",
      date: todayDateFormatted,
      data: today,
    },
    {
      name: "Tomorrow",
      date: tomorrowDateFormatted,
      data: tomorrow,
    },
    {
      name: "Yesterday",
      date: yesterdayDateFormatted,
      data: yesterday,
    },
  ];

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      className={classes.root}
      style={{
        backgroundColor: "inherit",
        borderRadius: "6px",
        boxShadow: `0px -5px 30px ${theme.palette.grey3.main}`,
        position: "relative",
      }}
    >
      {loading && (
        <Grid
          container
          justifyContent="center"
          style={{ position: "absolute", top: "50%" }}
        >
          <CircularProgress color="secondary" />
        </Grid>
      )}
      <Grid item xs={12} container>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="space-between"
          style={{ marginBottom: "5px" }}
        >
          <Typography
            className={classes.cardTitle}
            sx={{
              fontFamily: "barlowextrabold",
              paddingLeft: "10px",
            }}
          >
            RECENT HAs
          </Typography>
          <Grid item>
            <Tooltip title="Refresh HAs">
              <IconButton onClick={() => refetch()}>
                <RefreshIcon style={{ color: theme.palette.grey2.main }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Sort by Risk Score">
              <IconButton onClick={changeSort}>
                {sort === "desc" ? (
                  <ArrowDownwardIcon
                    style={{ color: theme.palette.grey2.main }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    style={{ color: theme.palette.grey2.main }}
                  />
                )}
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {accordions.map((accordion) => (
            <Accordion
              key={accordion.name}
              expanded={expanded === accordion.name}
              onChange={() => handleChange(accordion.name)}
              disableGutters
              style={{
                backgroundColor: "inherit",
                boxShadow:
                  expanded === accordion.name
                    ? `0px -5px 30px ${theme.palette.grey3.main}`
                    : "",
                color: theme.palette.grey1.main,
              }}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                style={{
                  color:
                    expanded === accordion.name
                      ? theme.palette.yellow0.main
                      : theme.palette.grey1.main,
                  fontFamily: "barlowextrabold",
                }}
              >
                <Badge
                  badgeContent={accordion.data.length}
                  showZero
                  color="secondary"
                >
                  <div
                    style={{
                      width: "90px",
                      border: "1px solid #FFFFFF50",
                      borderRadius: 6,
                      textAlign: "center",
                    }}
                  >
                    {accordion.name}
                  </div>
                </Badge>
                <Grid
                  container
                  alignContent="center"
                  style={{ marginLeft: "20px", fontSize: "12px" }}
                >
                  [{accordion.date}]
                </Grid>
              </AccordionSummary>
              <AccordionDetails style={{ height: "30vh", overflowY: "auto" }}>
                {accordion.data.length === 0 ? (
                  <Grid container justifyContent="center">
                    <Typography>No HAs submitted</Typography>
                  </Grid>
                ) : (
                  sortByScore(accordion.data).map((ha) => (
                    <HAItem key={ha.id} ha={ha} currentUser={currentUser} />
                  ))
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
