import * as React from "react";

import { useQuery, useReactiveVar } from "@apollo/client";

import { onlineVar } from "../../../../../graphql/localVariables/user";
import { GET_PROJECTS } from "../../../../../graphql/queries/admin/planning-project";
import TableTemplate from "../../../../CustomComponents/TableTemplate/TableTemplate";
import Row from "./Row";
import TableFilterDialog from "./TableFilterDialog";
import { filterData } from "./searchFilterFunction";

export default function Table() {
  const online = useReactiveVar(onlineVar);
  const { data, loading } = useQuery(GET_PROJECTS, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  const onActiveChange = (event) => {
    setActiveFilter(event.target.checked);
  };

  const onSiteOwnerChange = (event, newValue) => {
    setSiteOwnerFilter(newValue || { name: "" });
  };

  const clearFilters = () => {
    setActiveFilter(true);
    setSiteOwnerFilter({ name: "" });
  };

  const [searchTerm, setSearchTerm] = React.useState("");
  const [active, setActiveFilter] = React.useState(true);
  const [siteOwner, setSiteOwnerFilter] = React.useState({ name: "" });

  const [filteredData, setFilteredData] = React.useState([]);

  // useEffect for filter update
  React.useEffect(() => {
    setFilteredData(
      filterData(data?.projects ?? [], searchTerm, active, siteOwner)
    );
  }, [searchTerm, active, siteOwner, data]);

  return (
    <TableTemplate
      hasPagination={true}
      data={filteredData}
      title="Projects"
      headCells={[
        {
          uid: "name",
          numeric: false,
          disablePadding: false,
          label: "Name",
          sort: true,
        },
        {
          uid: "timeCreated",
          numeric: true,
          disablePadding: false,
          label: "Date Created",
        },
        {
          uid: "numSites",
          numeric: true,
          disablePadding: false,
          label: "Number of Sites",
        },
        {
          uid: "dollarAmount",
          numeric: true,
          disablePadding: false,
          label: "Dollar Amount",
        },
        {
          uid: "active",
          numeric: false,
          disablePadding: false,
          label: "Active",
        },
        {
          uid: "action",
          numeric: false,
          disablePadding: false,
          label: "",
        },
      ]}
      initialOrderBy="name"
      getItemsFromOrderBy={(a, b, orderBy) => {
        let aItem, bItem;

        if (orderBy === "name") {
          aItem = a[orderBy].toLowerCase();
          bItem = b[orderBy].toLowerCase();
        } else {
          aItem = a[orderBy];
          bItem = b[orderBy];
        }

        return { aItem, bItem };
      }}
      renderFilterDialog={(open, setOpen) => (
        <TableFilterDialog
          open={open}
          setOpen={setOpen}
          clearFilters={clearFilters}
          active={active}
          onActiveChange={onActiveChange}
          siteOwner={siteOwner}
          onSiteOwnerChange={onSiteOwnerChange}
        />
      )}
      searchTerm={searchTerm}
      updateSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
      getBadgeCount={() => {}}
      renderRow={(row) => <Row key={row.id} row={row} />}
      loading={loading}
    />
  );
}
