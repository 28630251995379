import * as React from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import PropTypes from "prop-types";

import EditUserDialog from "../../Company/Dashboard/Carousel/Edit/EditUserDialog";

export default function Row({ row }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow
        hover
        onClick={() => setOpen(true)}
        key={row.id}
        style={{ cursor: "pointer" }}
      >
        <TableCell>{row.username}</TableCell>
        <TableCell component="th" scope="row">
          {`${row.firstName} ${row.lastName}`}
        </TableCell>
        <TableCell>{row.company?.name}</TableCell>
        <TableCell>
          {row.userPermissionGroups
            ?.map((pg) => pg.permissionGroup.name)
            .join(", ")}
        </TableCell>
      </TableRow>
      <EditUserDialog open={open} setOpen={setOpen} d={row} />
    </>
  );
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
};
