import React from "react";

import CheckIcon from "@mui/icons-material/Check";
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import PropTypes from "prop-types";

import RiskScoreInput from "../RiskScoreInput";
import ActionRiskMitigators from "./ActionRiskMitigator";

const getRange = (actionRiskScore) => {
  const scores = [0, 0.1, 0.3, 0.5, 0.7, 0.9];
  const range = [];

  if (actionRiskScore >= 0) {
    const scoreIndex = scores.findIndex((s) => s === actionRiskScore);
    for (let i = scoreIndex; i <= 5; i++) {
      range.push(i);
    }
  }
  return range;
};

export default function AssociatedRisk({
  risk,
  remove,
  changeValue,
  action,
  mitigators,
  user,
}) {
  const [checked, setChecked] = React.useState(risk.isExpected);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    changeValue(risk.r.id, "isExpected", event.target.checked);
  };

  const [openMit, setOpenMit] = React.useState(false);

  const handleOpen = () => {
    setOpenMit(true);
  };

  const handleClose = () => {
    setOpenMit(false);
  };

  const disableIsExpected = user ? !user.currentUser.isStaff : true;

  return (
    <Grid
      key={risk.r.id}
      container
      style={{
        padding: "10px",
        height: "fit-content",
      }}
      alignItems="center"
      justifyContent="flex-start"
    >
      <Grid item xs={1}>
        <IconButton
          style={{ marginRight: "10px" }}
          onClick={() => remove(risk.r)}
        >
          <CheckIcon />
        </IconButton>
      </Grid>
      <Grid item xs={3}>
        <Typography style={{ color: "black" }}>{risk.r.name}</Typography>
      </Grid>

      <Grid item xs={1.5} container alignItems="center">
        <Checkbox
          checked={checked}
          onChange={handleChange}
          disabled={disableIsExpected}
        />
        <Typography style={{ fontSize: "12px" }}>Is Expected?</Typography>
      </Grid>

      <Grid item xs={4.5}>
        <RiskScoreInput
          value={risk.riskScore}
          onChange={(newVal) => changeValue(risk.r.id, "riskScore", newVal)}
          range={risk.added ? getRange(action.riskScore) : risk.riskLevelRange}
        />
      </Grid>

      <Grid item xs={2} container justifyContent={"right"}>
        <Tooltip
          title={
            risk.added
              ? "Must save added risks before adding their mitigators."
              : ""
          }
        >
          <span>
            <Button
              onClick={handleOpen}
              variant="contained"
              color="yellow0"
              sx={{
                width: "100%",
                fontSize: "12px",
              }}
              disabled={risk.added}
            >
              MITIGATORS
            </Button>
          </span>
        </Tooltip>
      </Grid>
      <ActionRiskMitigators
        action={action}
        risk={risk}
        mitigators={mitigators}
        open={openMit}
        handleClose={handleClose}
        riskScore={risk.riskScore}
      />
    </Grid>
  );
}

AssociatedRisk.propTypes = {
  risk: PropTypes.shape({
    r: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    riskScore: PropTypes.number.isRequired,
    isExpected: PropTypes.bool.isRequired,
    added: PropTypes.bool.isRequired,
    riskLevelRange: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  remove: PropTypes.func.isRequired,
  changeValue: PropTypes.func.isRequired,
  action: PropTypes.shape({
    riskScore: PropTypes.number.isRequired,
  }).isRequired,
  mitigators: PropTypes.array.isRequired,
  user: PropTypes.shape({
    currentUser: PropTypes.shape({
      isStaff: PropTypes.bool.isRequired,
    }),
  }),
};
