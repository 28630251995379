import React from "react";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { CustomDialog } from "../../../../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../../../../CustomStyles/pageTitle";
import Table from "./Table";

export default function ViewObservedSites({ open, setOpen, title, data }) {
  const onDone = () => {
    setOpen(false);
  };

  return (
    <CustomDialog
      open={open}
      fullWidth
      maxWidth="md"
      sx={{ color: "#333" }}
      onClose={() => setOpen(false)}
    >
      <DialogTitle style={{ ...pageTitleStyles }}>
        VIEW {title.toUpperCase()}.
      </DialogTitle>
      <DialogContent>
        <Table data={data} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          style={{ marginRight: "10px" }}
          onClick={onDone}
        >
          DONE
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}
