import React from "react";

import { Box } from "@mui/material";

import OfflineBanner from "../CustomComponents/OfflineBanner";
import DesktopDashboard from "./DesktopDashboard/DesktopDashboard";
import MobileDashboard from "./MobileDashboard/MobileDashboard";

export default function Dashboard() {
  return (
    <>
      <OfflineBanner />
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <MobileDashboard />
      </Box>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <DesktopDashboard />
      </Box>
    </>
  );
}
