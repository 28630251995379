import * as React from "react";
import { useNavigate } from "react-router-dom";

import Masonry from "@mui/lab/Masonry";
import {
  Typography,
  Grid,
  Button,
  CircularProgress,
  Box,
  Paper,
} from "@mui/material";

import { useQuery, useReactiveVar } from "@apollo/client";

import { permissionsVar } from "../../../graphql/localVariables/user";
import { GET_SUPERVISORS } from "../../../graphql/queries/admin/supervisors";
import SearchField from "../../CustomComponents/SearchField";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Layout from "../../Layout";
import CreateSupervisorDialog from "../Company/Dashboard/Carousel/Create/CreateSupervisorDialog";
import Card from "./Card";

export default function SupervisorsView() {
  const navigate = useNavigate();
  const permissions = useReactiveVar(permissionsVar);
  const { data: users, loading } = useQuery(GET_SUPERVISORS);
  const [pinnedUsers, setPinnedUsers] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filteredPinned, setFilteredPinned] = React.useState([]);
  const [filteredUnpinned, setFilteredUnPinned] = React.useState([]);

  const searchFilter = (arr) => {
    return arr.filter((d) => {
      const string = d.company
        ? d.firstName + d.lastName + d.username + d.company.name
        : d.firstName + d.lastName + d.username;
      return string.toLowerCase().includes(String(searchTerm).toLowerCase());
    });
  };

  React.useEffect(() => {
    if (users?.users) {
      setFilteredPinned(searchFilter(pinnedUsers));
      setFilteredUnPinned(searchFilter(users.users));
    }
  }, [users, pinnedUsers, searchTerm]);

  React.useEffect(() => {
    if (
      permissions.length === 0 ||
      (permissions.length > 0 && !permissions.includes("SUPERVISION"))
    ) {
      navigate("/");
    }
  }, []);

  return (
    <Layout>
      <Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: "15px" }}
        >
          <Grid item xs={4}>
            <Typography sx={pageTitleStyles}>SUPERVISORS.</Typography>
          </Grid>
          <Grid
            item
            xs={8}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <SearchField value={searchTerm} setValue={setSearchTerm} />
            </Grid>
            <Button
              variant="contained"
              color="yellow0"
              sx={{ ml: 2 }}
              onClick={() => setOpen(true)}
            >
              ADD SUPERVISOR
            </Button>
            <CreateSupervisorDialog
              open={open}
              setOpen={setOpen}
              existingSupervisorIds={users ? users.users.map((u) => u.id) : []}
            />
          </Grid>
        </Grid>

        <Box sx={{ width: "100%", minHeight: 377 }}>
          {loading && (
            <Grid container style={{ width: "100%" }} justifyContent="center">
              <CircularProgress color="secondary" />
            </Grid>
          )}
          {!loading && users?.users && users.users.length > 0 && (
            <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={2}>
              {/* Map through pinned users first */}
              {filteredPinned.map((data) => (
                <Paper
                  key={data.id}
                  elevation={5}
                  style={{
                    backgroundColor: "inherit",
                    borderRadius: "10px",
                    boxShadow: "10px -10px 30px #4D4D4D",
                    overflow: "hidden",
                  }}
                >
                  <Card
                    d={data}
                    setPinnedUsers={setPinnedUsers}
                    isPinned={true}
                  />
                </Paper>
              ))}
              {/* Map through unpinned users */}
              {users?.users &&
                filteredUnpinned
                  .filter(
                    (data) =>
                      pinnedUsers.findIndex((pin) => pin.id === data.id) < 0
                  )
                  .sort((a, b) => {
                    const aName =
                      a.firstName !== ""
                        ? `${a.firstName} ${a.lastName}`
                        : a.username;
                    const bName =
                      b.firstName !== ""
                        ? `${b.firstName} ${b.lastName}`
                        : b.username;

                    return aName > bName ? 1 : -1;
                  })
                  .map((data) => (
                    <Paper
                      key={data.id}
                      elevation={5}
                      style={{
                        backgroundColor: "inherit",
                        borderRadius: "10px",
                        boxShadow: "10px -10px 30px #4D4D4D",
                        overflow: "hidden",
                      }}
                    >
                      <Card
                        d={data}
                        setPinnedUsers={setPinnedUsers}
                        isPinned={false}
                      />
                    </Paper>
                  ))}
            </Masonry>
          )}
          {!loading && users?.users && users.users.length == 0 && (
            <Typography style={{ color: "white" }}>
              No supervisors found.
            </Typography>
          )}
        </Box>
      </Grid>
    </Layout>
  );
}
