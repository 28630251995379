import * as React from "react";

import { FilterList } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { Badge, IconButton, Toolbar, Tooltip, Typography } from "@mui/material";

import PropTypes from "prop-types";

import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../CustomStyles/searchBar";

export default function TableToolbar({
  title,
  renderFilterDialog,
  searchTerm,
  updateSearchTerm,
  getBadgeCount,
  setPage,
}) {
  const [open, setOpen] = React.useState(false);

  return (
    <Toolbar
      sx={{
        pl: {
          sm: 2,
        },
        pr: {
          xs: 1,
          sm: 1,
        },
      }}
    >
      <Typography
        sx={{
          flex: "1 1 100%",
        }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {title ?? null}
      </Typography>
      {renderFilterDialog && (
        <>
          <Tooltip title="Filter">
            <Badge badgeContent={getBadgeCount()} color="secondary">
              <IconButton onClick={() => setOpen(true)}>
                <FilterList />
              </IconButton>
            </Badge>
          </Tooltip>
          {renderFilterDialog(open, setOpen, setPage)}
        </>
      )}
      <Tooltip title="Search">
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{
              "aria-label": "search",
            }}
            value={searchTerm || ""}
            onChange={(event) => updateSearchTerm(event.target.value)}
          />
        </Search>
      </Tooltip>
    </Toolbar>
  );
}

TableToolbar.propTypes = {
  title: PropTypes.string,
  renderFilterDialog: PropTypes.func,
  searchTerm: PropTypes.string,
  updateSearchTerm: PropTypes.func,
  getBadgeCount: PropTypes.func,
};
