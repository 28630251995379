import * as React from "react";

import { useQuery, useReactiveVar } from "@apollo/client";

import { onlineVar } from "../../../../../graphql/localVariables/user";
import { GET_TEMPLATES } from "../../../../../graphql/queries/admin/planning-template";
import TableTemplate from "../../../../CustomComponents/TableTemplate/TableTemplate";
import TableFilterDialog from "../../Project/Table/TableFilterDialog";
import Row from "./Row";
import { filterData } from "./searchFilterFunction";

export default function Table() {
  const online = useReactiveVar(onlineVar);
  const { data, loading } = useQuery(GET_TEMPLATES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  const onActiveChange = (event, newValue) => {
    setActiveFilter(newValue || "");
  };

  const clearFilters = () => {
    setActiveFilter("");
  };

  const [searchTerm, setSearchTerm] = React.useState("");
  const [active, setActiveFilter] = React.useState("");

  const [filteredData, setFilteredData] = React.useState([]);

  // useEffect for filter update
  React.useEffect(() => {
    setFilteredData(filterData(data?.templates ?? [], searchTerm, active));
  }, [searchTerm, active, data]);

  return (
    <TableTemplate
      hasPagination={true}
      data={filteredData}
      title="Templates"
      headCells={[
        {
          uid: "name",
          numeric: false,
          disablePadding: false,
          label: "Name",
          sort: true,
        },
        {
          uid: "timeCreated",
          numeric: false,
          disablePadding: false,
          label: "Date Created",
        },
        {
          uid: "lastUpdated",
          numeric: false,
          disablePadding: false,
          label: "Last Updated",
        },
        {
          uid: "editor",
          numeric: false,
          disablePadding: false,
          label: "Last Editor",
        },
        {
          uid: "revision",
          numeric: false,
          disablePadding: false,
          label: "Revision #",
        },
        {
          uid: "associatedTo",
          numeric: false,
          disablePadding: false,
          label: "Associated To",
        },
        {
          uid: "active",
          numeric: false,
          disablePadding: false,
          label: "Active",
        },
        {
          uid: "",
          numeric: false,
          disablePadding: false,
          label: "",
        },
      ]}
      initialOrderBy="name"
      getItemsFromOrderBy={(a, b, orderBy) => {
        let aItem, bItem;

        if (orderBy === "name") {
          aItem = a[orderBy].toLowerCase();
          bItem = b[orderBy].toLowerCase();
        } else {
          aItem = a[orderBy];
          bItem = b[orderBy];
        }

        return { aItem, bItem };
      }}
      renderFilterDialog={(open, setOpen) => (
        <TableFilterDialog
          open={open}
          setOpen={setOpen}
          clearFilters={clearFilters}
          active={active}
          onActiveChange={onActiveChange}
        />
      )}
      searchTerm={searchTerm}
      updateSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
      getBadgeCount={() => {}}
      renderRow={(row) => <Row key={row.id} row={row} />}
      loading={loading}
    />
  );
}
