import React from "react";
import { useNavigate } from "react-router-dom";

import { TextField, Button, Grid, Typography, Paper } from "@mui/material";

import axios from "axios";

import { authMutations } from "../../graphql/mutations";
import logo from "../../images/nixn-logo-square.png";
import { pageTitleStyles } from "../CustomStyles/pageTitle";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [username, setUsername] = React.useState("");

  // Mutations
  const { updateSnackbar } = authMutations;

  const submitForgot = () => {
    axios
      .post("/main/forgot-pw/", { username })
      .then((response) => {
        if (response.data.success) {
          updateSnackbar({
            open: true,
            message:
              "Check your email for a message on how to reset your password.",
            severity: "success",
          });
          navigate("/login");
        } else {
          updateSnackbar({
            open: true,
            message: response.data.message,
            severity: "error",
          });
        }
      })
      .catch(() => {
        updateSnackbar({
          open: true,
          message: "There was an issue trying to recover your password.",
          severity: "error",
        });
      });
  };

  return (
    <Grid container justifyContent="center">
      <Grid
        item
        xs={12}
        container
        style={{ backgroundColor: "#151711", height: 60, width: "100%" }}
        justifyContent="center"
      >
        <img src={logo} alt="NIXN logo" style={{ height: "100%" }} />
      </Grid>
      <Grid item style={{ marginTop: "30px" }} xs={10} md={6}>
        <Typography style={pageTitleStyles}>FORGOT PASSWORD.</Typography>
        <Paper style={{ width: "100%" }}>
          <Grid container direction="column" alignItems="center">
            <TextField
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              label="Username"
              variant="standard"
              style={{ width: "80%", marginTop: "10px" }}
            />
            <Grid item xs={12} style={{ margin: "20px" }}>
              <Button
                onClick={submitForgot}
                variant="contained"
                color="yellow0"
                sx={{ mr: 1 }}
              >
                SUBMIT
              </Button>
              <Button
                onClick={() => navigate("/login")}
                variant="contained"
                color="primary"
              >
                CANCEL
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
