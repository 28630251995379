import React from "react";

import { ExpandMore, InfoOutlined } from "@mui/icons-material";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Tooltip,
  CircularProgress,
} from "@mui/material";

import { useLazyQuery } from "@apollo/client";

import { GET_SINGLE_AUDIT_TOPICS } from "../../../../graphql/queries/admin/audits";

export default function AuditQuestions({ audit }) {
  const [getQuestions, { data, loading }] = useLazyQuery(
    GET_SINGLE_AUDIT_TOPICS
  );

  React.useEffect(() => {
    if (audit.id) {
      getQuestions({ variables: { id: Number(audit.id) } });
    }
  }, [audit.id]);

  if (loading) return <CircularProgress color="secondary" />;

  if (data && data.audit.topics.length > 0) {
    return data.audit.topics.map((t) => {
      return (
        <Accordion
          disableGutters
          key={`topic-accordion-${t.id}`}
          style={{ width: "100%", minWidth: "200px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="topic-content"
            key={`topic-summary-${t.id}`}
            style={{
              backgroundColor: "white",
            }}
          >
            {t.name}
          </AccordionSummary>
          <AccordionDetails
            key={`topic-details-${t.id}`}
            style={{
              backgroundColor: "white",
            }}
          >
            <Grid container alignItems="center">
              {t.questions.map((question) => {
                return (
                  <Grid
                    item
                    xs={12}
                    container
                    style={{ padding: "20px", border: "1px solid black" }}
                    alignItems="center"
                    key={`question-${question.id}`}
                  >
                    <Grid item xs={12} md={6}>
                      <Typography style={{ width: "85%", display: "contents" }}>
                        {question.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3} container alignItems="center">
                      <Tooltip
                        title={
                          question.standardNumber
                            ? `${question.standardNumber}`
                            : "No standard number given"
                        }
                      >
                        <InfoOutlined style={{ margin: "0px 20px" }} />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} md={3} container alignItems="center">
                      <Typography style={{ display: "contents" }}>
                        ${question.penalty}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    });
  } else {
    return <Typography>No Results</Typography>;
  }
}
