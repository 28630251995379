import * as React from "react";
import { useNavigate } from "react-router-dom";

import { TableCell, TableRow } from "@mui/material";

import moment from "moment";
import PropTypes from "prop-types";

export default function Row({ row }) {
  const navigate = useNavigate();

  const d = row.date ? new Date(row.date) : null;
  const utc = d ? d.getTime() + d.getTimezoneOffset() * 60000 : null;
  const convertedDate = utc ? new Date(utc) : null;

  let time = new Date();
  const arr = row.time ? row.time.split(":") : [0, 0, 0];
  time.setHours(arr[0], arr[1], arr[2], 0);

  return (
    <TableRow
      hover
      onClick={() => navigate(`/view-audit/${row.id}`)}
      key={row.id}
      style={{ cursor: "pointer" }}
    >
      <TableCell component="th" scope="row">
        {row.id}
      </TableCell>
      <TableCell>
        {convertedDate ? moment(convertedDate).format("MM/DD/YYYY") : ""}{" "}
        {row.time ? moment(time).format("hh:mm A") : ""}
      </TableCell>
      <TableCell>{`${moment(new Date(row.timeCreated)).format(
        "MM/DD/YYYY hh:mm A"
      )}`}</TableCell>
      <TableCell>{row.submitter?.username}</TableCell>
      <TableCell>
        {row.ownerSiteProject
          ? `${row.ownerSiteProject?.site?.name} [${row.ownerSiteProject?.owner?.name}]`
          : ""}
      </TableCell>
      <TableCell>{row.audit?.name}</TableCell>
    </TableRow>
  );
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
};
