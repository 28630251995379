import * as React from "react";

import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";

import PropTypes from "prop-types";

const styles = () => ({
  root: {},
  imageContainer: {
    position: "relative",
    zIndex: 10,
    textAlign: "center",
    "&:hover $image": {
      opacity: 0.3,
    },
    "&:hover $removeButton": {
      opacity: 1,
    },
    padding: "20px",
  },
  image: {
    maxWidth: "100%",
    width: "auto",
    maxHeight: "100%",
    height: "100px",
  },
  removeButton: {
    transition: ".5s ease",
    position: "absolute",
    top: 0,
    right: 0,
    opacity: 0,
    width: 40,
    height: 40,
    "&:focus": {
      opacity: 1,
    },
  },
});

function PreviewList({ fileObjects, classes }) {
  const getPreviewIcon = (fileObject, classes) => {
    if (fileObject.image) {
      return (
        <>
          <a
            href={fileObject.image}
            target="_blank"
            rel="noreferrer"
            download
            style={{ color: "white" }}
          >
            <img
              className={classes.image}
              src={fileObject.image}
              alt="Audit preview"
            />
          </a>
          <Typography style={{ fontSize: "12px" }}>
            {
              fileObject.image.split("/")[
                fileObject.image.split("/").length - 1
              ]
            }
          </Typography>
        </>
      );
    } else return null;
  };

  return (
    <Grid container>
      {fileObjects?.map((fileObject, i) => {
        return (
          <Grid
            item
            xs={3}
            key={`file-${i}`}
            className={classes.imageContainer}
          >
            {getPreviewIcon(fileObject, classes)}
          </Grid>
        );
      })}
    </Grid>
  );
}

PreviewList.propTypes = {
  fileObjects: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { name: "MuiDropzonePreviewList" })(
  PreviewList
);
