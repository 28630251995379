import * as React from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Autocomplete,
  TextField,
  Grid,
  Button,
  Checkbox,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import { useLazyQuery, useReactiveVar } from "@apollo/client";

import { viewHAFiltersVar } from "../../../../graphql/localVariables/ha";
import { onlineVar } from "../../../../graphql/localVariables/user";
import { haMutations } from "../../../../graphql/mutations";
import {
  GET_COMPANIES,
  GET_SITES,
  GET_USERNAMES,
} from "../../../../graphql/queries";
import {
  GET_ALL_PROJECTS,
  GET_SEARCH_TEMPLATES,
} from "../../../../graphql/queries/ha";

export default function TableFilterDialog({
  open,
  setOpen,
  clearFilters,
  onFilter,
}) {
  const online = useReactiveVar(onlineVar);
  const filters = useReactiveVar(viewHAFiltersVar);

  const { updateKeyViewHAFilters } = haMutations;

  const [getSites, { data: sites }] = useLazyQuery(GET_SITES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const [getUsernames, { data: usernames }] = useLazyQuery(GET_USERNAMES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const [getProjects, { data: projects }] = useLazyQuery(GET_ALL_PROJECTS, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const [getTemplates, { data: templates }] = useLazyQuery(
    GET_SEARCH_TEMPLATES,
    {
      fetchPolicy: online ? "network-only" : "cache-only",
    }
  );
  const [getCompanies, { data: companies }] = useLazyQuery(GET_COMPANIES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  React.useEffect(() => {
    if (open) {
      getSites();
      getUsernames();
      getProjects();
      getTemplates();
      getCompanies();
    }
  }, [open]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const filterProjects = () => {
    if (filters.site && filters.site.owner.name !== "") {
      return projects?.projects
        ? projects.projects.filter(
            (p) =>
              p.ownerSiteProject.findIndex(
                (osp) => osp.owner.id === filters.site.owner.id
              ) >= 0 &&
              p.ownerSiteProject.findIndex(
                (osp) => osp.site.id === filters.site.site.id
              ) >= 0
          )
        : [];
    }
    return projects?.projects ?? [];
  };

  const filter = () => {
    onFilter();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={4} style={{ padding: "5px" }}>
            <Autocomplete
              id="ha-filter-site"
              options={
                sites?.ownerSiteProjects
                  ? [...sites.ownerSiteProjects].sort((a, b) =>
                      a.site.name > b.site.name ? 1 : -1
                    )
                  : []
              }
              getOptionLabel={(option) =>
                option.site
                  ? `${option.site?.name} [${option.owner?.name}]`
                  : option.owner?.name
              }
              value={filters.site || { owner: { name: "" } }}
              onChange={(event, newValue) =>
                updateKeyViewHAFilters(
                  "site",
                  newValue || { owner: { name: "" } }
                )
              }
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.site
                      ? `${option.site?.name} [${option.owner?.name}]`
                      : option.owner?.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Site [Site Owner]"
                  value={params}
                  id={params.id}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "5px" }}>
            <Autocomplete
              id="filter-projects"
              options={filterProjects().sort((a, b) =>
                a.name > b.name ? 1 : -1
              )}
              value={filters.projects || []}
              getOptionLabel={(option) => {
                return option.name;
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.id}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              multiple
              limitTags={4}
              disableCloseOnSelect
              onChange={(event, newValue) =>
                updateKeyViewHAFilters("projects", newValue || [])
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={"Filter by Projects"}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "5px" }}>
            <Autocomplete
              id="filter-templates"
              options={
                templates
                  ? [...templates.templates].sort((a, b) =>
                      a.name > b.name ? 1 : -1
                    )
                  : []
              }
              value={filters.templates || []}
              getOptionLabel={(option) => {
                return option.name;
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              multiple
              limitTags={4}
              disableCloseOnSelect
              onChange={(event, newValue) =>
                updateKeyViewHAFilters("templates", newValue || [])
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={"Filter by Templates"}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "5px" }}>
            <Autocomplete
              id="filter-users"
              options={
                usernames?.users
                  ? usernames.users.toSorted((a, b) =>
                      a.username > b.username ? 1 : -1
                    )
                  : []
              }
              getOptionLabel={(option) => {
                return option.username;
              }}
              value={filters.usernames || []}
              onChange={(event, newValue) =>
                updateKeyViewHAFilters("usernames", newValue || [])
              }
              multiple
              limitTags={4}
              disableCloseOnSelect
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.username}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Submitter"
                  value={params}
                  id={params.id}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "5px" }}>
            <Autocomplete
              id="filter-companies"
              options={
                companies
                  ? companies.companies.toSorted((a, b) =>
                      a.name > b.name ? 1 : -1
                    )
                  : []
              }
              getOptionLabel={(option) => {
                return option.name;
              }}
              value={filters.company || { name: "" }}
              onChange={(event, newValue) =>
                updateKeyViewHAFilters("company", newValue || { name: "" })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Submitter Company"
                  value={params}
                  id={params.id}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "5px" }}>
            <Autocomplete
              id="filter-status"
              options={["Open", "Closed"]}
              value={filters.status || ""}
              onChange={(event, newValue) =>
                updateKeyViewHAFilters("status", newValue || "")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Status"
                  value={params}
                  id={params.id}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "5px" }}>
            <Autocomplete
              id="filter-incident"
              options={["With", "Without"]}
              value={filters.incident || ""}
              onChange={(event, newValue) =>
                updateKeyViewHAFilters("incident", newValue || "")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Incident"
                  value={params}
                  id={params.id}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} style={{ padding: "5px" }} container>
            <DesktopDatePicker
              label="Filter After This Date"
              inputFormat="MMM DD, yyyy"
              value={filters.afterDate || null}
              onChange={(date) =>
                updateKeyViewHAFilters("afterDate", date || null)
              }
              renderInput={(params) => (
                <TextField
                  sx={{ width: "47%", marginRight: "3%" }}
                  variant="standard"
                  {...params}
                />
              )}
              disableMaskedInput
            />
            <DesktopDatePicker
              label="Filter Before This Date"
              inputFormat="MMM DD, yyyy"
              value={filters.beforeDate || null}
              onChange={(date) =>
                updateKeyViewHAFilters("beforeDate", date || null)
              }
              renderInput={(params) => (
                <TextField
                  sx={{ width: "50%" }}
                  variant="standard"
                  {...params}
                />
              )}
              disableMaskedInput
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={filter} variant="contained" color="secondary">
          FILTER
        </Button>
        <Button onClick={clearFilters} variant="contained" color="primary">
          CLEAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}
