import React from "react";
import QRCode from "react-qr-code";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Badge,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import { useMutation, useLazyQuery, useReactiveVar } from "@apollo/client";
import moment from "moment";
import { DropzoneDialog } from "mui-file-dropzone";

import { apiUrl } from "../../../../../../config";
import { onlineVar } from "../../../../../../graphql/localVariables/user";
import { authMutations } from "../../../../../../graphql/mutations";
import {
  CREATE_SINGLE_CREWMEMBER,
  UPLOAD_CREWMEMBER_CSV,
} from "../../../../../../graphql/mutations/crewmembers";
import {
  CREW_MEMBER_INFO,
  GET_COMPANY_SITES,
} from "../../../../../../graphql/queries/admin/company";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../../../CustomComponents/OnErrorFunction";
import TabPanel from "../../../../../CustomComponents/TabPanels";
import {
  StyledTabs,
  StyledTabWhite,
} from "../../../../../CustomComponents/Tabs";
import {
  CustomDateField,
  CustomTextField,
} from "../../../../../CustomStyles/LightTextField";
import { CustomDialog } from "../../../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../../../CustomStyles/pageTitle";
import { COMPANY_ADMIN_CREWMEMBERS } from "../../../companyAdminQueries";
import CSV from "./crew_member_template.csv";

export default function CreateCrewDialog({ open, setOpen, title, company }) {
  const online = useReactiveVar(onlineVar);
  const [getSites, { data: sites }] = useLazyQuery(GET_COMPANY_SITES);
  const [crewMemberInfo, { data: info }] = useLazyQuery(CREW_MEMBER_INFO);

  const [csv, setCsv] = React.useState([]);
  const [openUpload, setOpenUpload] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [uploadErrors, setUploadErrors] = React.useState({
    invalid_company_list: [],
    invalid_contact_phone_number_list: [],
    invalid_craft_type_list: [],
    invalid_drug_test_expiry_list: [],
    invalid_hire_date_list: [],
    invalid_job_title_list: [],
    invalid_phone_number_list: [],
    invalid_yrs_of_exp_list: [],
  });

  const { updateSnackbar } = authMutations;

  const handleSave = (file) => {
    setOpenUpload(false);
    setCsv(file);
  };

  React.useEffect(() => {
    if (open) {
      getSites({
        fetchPolicy: online ? "network-only" : "cache-only",
      });
      crewMemberInfo({
        fetchPolicy: online ? "network-only" : "cache-only",
      });
    }
  }, [open]);

  const [createCrew, { loading: loadingCreate }] = useMutation(
    CREATE_SINGLE_CREWMEMBER,
    {
      onCompleted() {
        setOpen(false);
        onCompletedFunc("Crewmember has been created");
        reset();
      },
      onError(error) {
        onErrorFunc(error);
      },
      refetchQueries: [
        {
          query: COMPANY_ADMIN_CREWMEMBERS,
          variables: {
            id: Number(company.id),
          },
        },
      ],
    }
  );

  const [uploadCrew, { loading: loadingUpload }] = useMutation(
    UPLOAD_CREWMEMBER_CSV,
    {
      onCompleted(response) {
        if (
          response.uploadCrewMemberMutation &&
          response.uploadCrewMemberMutation.message
        ) {
          onCompletedFunc(response.uploadCrewMemberMutation.message.info);
          setUploadErrors(response.uploadCrewMemberMutation.message.errors);
        } else {
          setUploadErrors({
            invalid_company_list: [],
            invalid_contact_phone_number_list: [],
            invalid_craft_type_list: [],
            invalid_drug_test_expiry_list: [],
            invalid_hire_date_list: [],
            invalid_job_title_list: [],
            invalid_phone_number_list: [],
            invalid_yrs_of_exp_list: [],
          });
          setOpen(false);
        }
        reset();
      },
      onError(error) {
        onErrorFunc(error);
      },
      refetchQueries: [
        {
          query: COMPANY_ADMIN_CREWMEMBERS,
          variables: {
            id: Number(company.id),
          },
        },
      ],
    }
  );

  // Tabs Function: sets index
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [location, setLocation] = React.useState({
    owner: { name: "" },
    site: { name: "" },
  });

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [hireDate, setHireDate] = React.useState(null);
  const [expirationDate, setExpirationDate] = React.useState(null);
  const [contactName, setContactName] = React.useState("");
  const [contactPhone, setContactPhone] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState({ name: "" });
  const [craft, setCraft] = React.useState({ name: "" });
  const [years, setYears] = React.useState({ name: "" });
  const [other, setOther] = React.useState("");

  const reset = () => {
    setLocation({
      owner: { name: "" },
      site: { name: "" },
    });
    setFirstName("");
    setLastName("");
    setPhone("");
    setEmail("");
    setHireDate(null);
    setExpirationDate(null);
    setContactName("");
    setContactPhone("");
    setJobTitle({ name: "" });
    setCraft({ name: "" });
    setYears({ name: "" });
    setOther("");
    setCsv([]);
  };

  const onDone = () => {
    setOpen(false);
    reset();
  };

  const onCreate = () => {
    if (value === 0) {
      if (
        location.owner.name !== "" &&
        phone !== "" &&
        company.id !== null &&
        firstName !== "" &&
        lastName !== "" &&
        jobTitle.name !== "" &&
        craft.name !== "" &&
        years.name !== ""
      ) {
        createCrew({
          variables: {
            isActive: true,
            company: Number(company.id),
            phoneNumber: phone,
            OSP: Number(location.id),
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            ...(email !== "" && { email: email.trim() }),
            jobTitle: Number(jobTitle.id),
            craft: Number(craft.id),
            years: Number(years.id),
            ...(hireDate && {
              hireDate: moment(hireDate).format("YYYY-MM-DD"),
            }),
            ...(expirationDate && {
              expirationDate: moment(expirationDate).format("YYYY-MM-DD"),
            }),
            ...(other !== "" && { other }),
            ...(contactName !== "" && { contactName: contactName.trim() }),
            ...(contactPhone !== "" && { contactNumber: contactPhone }),
          },
        });
      } else {
        updateSnackbar({
          open: true,
          message:
            "Must enter phone number, company, first name, last name, craft type, job title, and years of experience.",
          severity: "error",
        });
      }
    } else if (value === 1) {
      if (csv.length !== 0 && Number(location.id)) {
        uploadCrew({ variables: { ospId: Number(location.id), file: csv[0] } });
      } else {
        onErrorFunc("Must upload a CSV and select a location.");
      }
    } else {
      setOpen(false);
    }
  };

  // setup download QR code
  const download = () => {
    const svg = document.getElementById("svg");
    if (svg) {
      // Create image
      const img = new Image();
      const serializer = new XMLSerializer();
      const svgStr = serializer.serializeToString(svg);
      img.src = "data:image/svg+xml;base64," + window.btoa(svgStr);
      img.onload = function () {
        // Draw svg
        const canvas = document.createElement("canvas");
        const w = 400;
        const h = 400;
        canvas.width = w;
        canvas.height = h;
        canvas.getContext("2d").drawImage(img, 0, 0, w, h);
        const imgURL = canvas.toDataURL("image/png");
        // Download
        const dlLink = document.createElement("a");
        dlLink.download = `${location.site.name} [${location.owner.name}] QR Code`;
        dlLink.href = imgURL;
        document.body.appendChild(dlLink);
        dlLink.click();
        document.body.removeChild(dlLink);
      };
    }
  };

  const displayInvalidData = (array, type) => {
    return array.map((data) => {
      return (
        <Typography key={data.phone_number}>
          {data.phone_number} - {data[type] === "" ? "empty" : data[type]}
        </Typography>
      );
    });
  };

  return (
    <>
      <CustomDialog
        open={open}
        fullWidth
        maxWidth="md"
        sx={{ color: "#333" }}
        onClose={() => setOpen(false)}
      >
        <DialogTitle style={{ ...pageTitleStyles }}>
          ADD {title.toUpperCase()}.
        </DialogTitle>
        <DialogContent>
          <StyledTabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            style={{ color: "white", paddingBottom: "20px" }}
          >
            <StyledTabWhite label="ADD SINGLE CREWMEMBER" />
            <StyledTabWhite label="UPLOAD CREWMEMBERS CSV" />
            <StyledTabWhite label="DOWNLOAD SITE QR CODE" />
          </StyledTabs>

          {/* Individual Create */}

          <TabPanel value={value} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  id="select-locations"
                  options={
                    sites && sites.ownerSiteProjects
                      ? sites.ownerSiteProjects
                      : []
                  }
                  value={location}
                  getOptionLabel={(option) => {
                    if (option.site.name === "") return "";
                    return `${option.site.name} [${option.owner.name}]`;
                  }}
                  onChange={(event, newVal) => setLocation(newVal)}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disableClearable
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      variant="standard"
                      label="Location:"
                      value={params}
                      id={params.id}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  variant="standard"
                  label="First Name:"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  variant="standard"
                  label="Last Name:"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomDateField
                  defaultCountry={"us"}
                  value={phone}
                  onChange={(val) => setPhone(val)}
                  label="Phone Number:"
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  variant="standard"
                  label="Email:"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  type="email"
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  id="select-craft-type"
                  options={info ? info.craftTypes : []}
                  value={craft}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newVal) => setCraft(newVal)}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disableClearable
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      variant="standard"
                      label="Craft Type:"
                      value={params}
                      id={params.id}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  id="select-job-title"
                  options={info ? info.jobTitles : []}
                  value={jobTitle}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newVal) => setJobTitle(newVal)}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disableClearable
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      variant="standard"
                      label="Job Title:"
                      value={params}
                      id={params.id}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  id="select-years"
                  options={info ? info.yearsOfExps : []}
                  value={years}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newVal) => setYears(newVal)}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disableClearable
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      variant="standard"
                      label="Years of Exp:"
                      value={params}
                      id={params.id}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MobileDatePicker
                  label="Hire Date:"
                  inputFormat="MM/DD/YYYY"
                  value={hireDate}
                  onChange={(date) => setHireDate(date)}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      style={{
                        width: "100%",
                      }}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MobileDatePicker
                  label="Expiration Date:"
                  inputFormat="MM/DD/YYYY"
                  value={expirationDate}
                  onChange={(date) => setExpirationDate(date)}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      style={{
                        width: "100%",
                      }}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} container spacing={2} alignItems="center">
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    variant="standard"
                    label="Emergency Contact Name:"
                    value={contactName}
                    onChange={(event) => setContactName(event.target.value)}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomDateField
                    defaultCountry={"us"}
                    value={contactPhone}
                    onChange={(val) => setContactPhone(val)}
                    label="Emergency Contact Phone Number:"
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  variant="standard"
                  label="Other:"
                  value={other}
                  onChange={(event) => setOther(event.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </TabPanel>

          {/* Bulk update */}

          <TabPanel value={value} index={1}>
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ marginBottom: "15px" }}
            >
              <Grid item>
                <Badge
                  badgeContent={csv.length}
                  color="primary"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Button
                    variant="contained"
                    color="yellow0"
                    onClick={() => setOpenUpload(true)}
                  >
                    Upload Crewmember CSV
                  </Button>
                </Badge>
              </Grid>
              <Grid item>
                <Button variant="contained" color="yellow0" sx={{ ml: 1 }}>
                  <a
                    href={CSV}
                    target="_blank"
                    type="text/csv"
                    download="crew_member_template.csv"
                    style={{ color: "black", textDecoration: "none" }}
                    rel="noreferrer"
                  >
                    Download Template
                  </a>
                </Button>
              </Grid>
              <Grid
                item
                sx={{
                  width: "50%",
                  margin: "auto",
                }}
              >
                <Autocomplete
                  id="select-locations"
                  options={
                    sites && sites.ownerSiteProjects
                      ? sites.ownerSiteProjects
                      : []
                  }
                  value={location}
                  getOptionLabel={(option) => {
                    if (option.site.name === "") return "";
                    return `${option.site.name} [${option.owner.name}]`;
                  }}
                  onChange={(event, newVal) => setLocation(newVal)}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disableClearable
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      variant="standard"
                      label="Location:"
                      value={params}
                      id={params.id}
                    />
                  )}
                />
              </Grid>
              <Grid container>
                {uploadErrors.invalid_company_list.length !== 0 && (
                  <Grid>
                    <Typography>Invalid Companies</Typography>
                    {displayInvalidData(
                      uploadErrors.invalid_company_list,
                      "company"
                    )}
                  </Grid>
                )}
                {uploadErrors.invalid_phone_number_list.length !== 0 && (
                  <Grid>
                    <Typography>Invalid Phone Numbers</Typography>
                    {displayInvalidData(
                      uploadErrors.invalid_phone_number_list,
                      "phone_number"
                    )}
                  </Grid>
                )}
                {uploadErrors.invalid_contact_phone_number_list.length !==
                  0 && (
                  <Grid>
                    <Typography>Invalid Contact Phone Numbers</Typography>
                    {displayInvalidData(
                      uploadErrors.invalid_contact_phone_number_list,
                      "contact_phone_number"
                    )}
                  </Grid>
                )}
                {uploadErrors.invalid_craft_type_list.length !== 0 && (
                  <Grid>
                    <Typography>Invalid Craft Types</Typography>
                    {displayInvalidData(
                      uploadErrors.invalid_craft_type_list,
                      "craft_type"
                    )}
                  </Grid>
                )}
                {uploadErrors.invalid_job_title_list.length !== 0 && (
                  <Grid>
                    <Typography>Invalid Job Titles</Typography>
                    {displayInvalidData(
                      uploadErrors.invalid_job_title_list,
                      "job_title"
                    )}
                  </Grid>
                )}
                {uploadErrors.invalid_drug_test_expiry_list.length !== 0 && (
                  <Grid>
                    <Typography>Invalid Drug Test Dates</Typography>
                    {displayInvalidData(
                      uploadErrors.invalid_drug_test_expiry_list,
                      "drug_test_expiry"
                    )}
                  </Grid>
                )}
                {uploadErrors.invalid_hire_date_list.length !== 0 && (
                  <Grid>
                    <Typography>Invalid Hire Dates</Typography>
                    {displayInvalidData(
                      uploadErrors.invalid_hire_date_list,
                      "hire_date"
                    )}
                  </Grid>
                )}
                {uploadErrors.invalid_yrs_of_exp_list.length !== 0 && (
                  <Grid>
                    <Typography>Invalid Years of Experience</Typography>
                    {displayInvalidData(
                      uploadErrors.invalid_craft_type_list,
                      "craft_type"
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
            <DropzoneDialog
              open={openUpload}
              onSave={handleSave}
              acceptedFiles={[
                ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
              ]}
              showPreviews={true}
              maxFileSize={5000000}
              filesLimit={1}
              onClose={() => setOpenUpload(false)}
            />
          </TabPanel>

          {/* QR code */}

          <TabPanel value={value} index={2}>
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ marginBottom: "15px" }}
            >
              <Grid item sx={{ margin: "auto" }}>
                {location.id ? (
                  <div
                    id="svg-container"
                    style={{
                      height: "auto",

                      maxWidth: 150,
                      width: "100%",
                    }}
                  >
                    <QRCode
                      size={256}
                      id="svg"
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={
                        location.id
                          ? `${apiUrl.replace("/api", "")}crewmembers/${
                              location.id
                            }/${location.site.id}/${location.site.name}/${
                              location.owner.name
                            }`.replace(/ /g, "%20")
                          : null
                      }
                      viewBox={"0 0 256 256"}
                    />
                  </div>
                ) : null}
                <Button
                  variant="contained"
                  color="yellow0"
                  sx={{ width: "150px" }}
                  onClick={() =>
                    location.id
                      ? download()
                      : onErrorFunc("Must selected a location")
                  }
                >
                  Download QR Code
                </Button>
              </Grid>
              <Grid
                item
                sx={{
                  width: "60%",
                  margin: "auto",
                }}
              >
                <Autocomplete
                  id="select-locations"
                  options={
                    sites && sites.ownerSiteProjects
                      ? sites.ownerSiteProjects
                      : []
                  }
                  value={location}
                  getOptionLabel={(option) => {
                    if (option.site.name === "") return "";
                    return `${option.site.name} [${option.owner.name}]`;
                  }}
                  onChange={(event, newVal) => setLocation(newVal)}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disableClearable
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      variant="standard"
                      label="Location:"
                      value={params}
                      id={params.id}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </TabPanel>
        </DialogContent>

        <DialogActions>
          {value < 2 && (
            <LoadingButton
              variant="contained"
              color="yellow0"
              sx={{ mr: 1 }}
              onClick={onCreate}
              loading={value === 0 ? loadingCreate : loadingUpload}
            >
              CREATE
            </LoadingButton>
          )}
          <Button variant="contained" onClick={onDone}>
            DONE
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
}
