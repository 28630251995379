import React from "react";

import { Check, CheckBoxOutlineBlank, InfoOutlined } from "@mui/icons-material";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import { useMutation, useLazyQuery } from "@apollo/client";

import { CREATE_PERMISSION_GROUP } from "../../../graphql/mutations/admin/permissions";
import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import { GET_PERMISSION_GROUPS } from "../../../graphql/queries/permissions";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import { CustomTextField } from "../../CustomStyles/LightTextField";
import { CustomDialog } from "../../CustomStyles/dialog";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";

export default function CreateGroup({
  open,
  handleClose,
  permissions: allPermissions,
}) {
  const [getCurrentUser, { data }] = useLazyQuery(GET_CURRENT_USER);

  React.useEffect(() => {
    if (open) {
      getCurrentUser();
    }
  }, [open]);

  const [createGroup] = useMutation(CREATE_PERMISSION_GROUP, {
    onCompleted() {
      onDone();
      onCompletedFunc("Permission group has been created");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [{ query: GET_PERMISSION_GROUPS }],
  });

  const [groupName, setGroupName] = React.useState("");
  const [addedPermissions, setAddedPermissions] = React.useState([]);

  const onCreateGroup = () => {
    createGroup({
      variables: {
        company: Number(data?.currentUser.company.id),
        name: groupName,
        permissions: addedPermissions.map((p) => Number(p.id)),
      },
    });
  };

  const onClear = () => {
    setGroupName("");
    setAddedPermissions([]);
  };

  const add = (p) => {
    setAddedPermissions((prev) => [...prev, p]);
  };

  const remove = (p) => {
    setAddedPermissions((prev) => prev.filter((prev) => prev.id !== p.id));
  };

  const onDone = () => {
    onClear();
    handleClose();
  };

  return (
    <CustomDialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle
        style={{
          ...pageTitleStyles,
        }}
      >
        CREATE PERMISSION GROUP.
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <CustomTextField
            label="Group Name:"
            variant="standard"
            style={{ margin: "10px 0px" }}
            value={groupName}
            onChange={(event) => setGroupName(event.target.value)}
          />
          <Grid container style={{ marginTop: "20px" }}>
            <Grid item xs={7}>
              <Typography style={{ color: "white" }}>
                INCLUDED PERMISSIONS:
              </Typography>
              <Grid
                container
                style={{
                  maxHeight: "300px",
                  overflowY: "auto",
                  backgroundColor: "white",
                  width: "80%",
                  marginTop: "10px",
                  borderRadius: 5,
                }}
              >
                {addedPermissions.length === 0 && (
                  <Typography style={{ padding: "20px" }}>
                    No added permissions.
                  </Typography>
                )}

                {addedPermissions.map((perm) => (
                  <Grid
                    item
                    xs={12}
                    key={perm.id}
                    style={{
                      padding: "10px",
                      height: "fit-content",
                    }}
                    container
                    alignItems="center"
                  >
                    <Grid item xs={2}>
                      <IconButton
                        style={{ marginRight: "10px" }}
                        onClick={() => remove(perm)}
                      >
                        <Check />
                      </IconButton>
                    </Grid>
                    <Grid item xs={10} container>
                      <Typography
                        style={{ marginRight: "15px", color: "black" }}
                      >
                        {perm.name}
                      </Typography>
                      <Tooltip title={perm.description}>
                        <InfoOutlined />
                      </Tooltip>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Typography style={{ color: "white" }}>
                UNINCLUDED PERMISSIONS:
              </Typography>
              <Grid
                container
                style={{
                  maxHeight: "300px",
                  overflowY: "auto",
                  backgroundColor: "white",
                  width: "80%",
                  marginTop: "10px",
                  borderRadius: 5,
                }}
              >
                {allPermissions?.permissions
                  .filter(
                    (p) =>
                      p.isActive &&
                      addedPermissions.findIndex((perm) => perm.id === p.id) < 0
                  )
                  .map((p) => (
                    <Grid
                      item
                      xs={12}
                      key={p.id}
                      style={{
                        padding: "10px",
                        height: "fit-content",
                      }}
                      container
                      alignItems="center"
                    >
                      <Grid item xs={2}>
                        <IconButton
                          style={{ marginRight: "10px" }}
                          onClick={() => add(p)}
                        >
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10} container>
                        <Typography
                          style={{ marginRight: "5px", color: "black" }}
                        >
                          {p.name}
                        </Typography>
                        <Tooltip title={p.description}>
                          <InfoOutlined />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ margin: "20px 0px" }}>
              <Button
                variant="contained"
                color="yellow0"
                sx={{ mr: 1 }}
                onClick={onCreateGroup}
              >
                CREATE
              </Button>
              <Button variant="contained" onClick={onClear}>
                CLEAR
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          style={{ marginRight: "10px" }}
          onClick={onDone}
        >
          DONE
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}
