import * as React from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import PropTypes from "prop-types";

import EditSite from "../../../Edit/EditSiteDialog";

export default function Row({ row, extraData }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow
        hover
        onClick={() => setOpen(true)}
        key={row.id}
        style={{ cursor: "pointer" }}
      >
        <TableCell component="th" scope="row">
          {row.site.name}
        </TableCell>
      </TableRow>
      <EditSite open={open} setOpen={setOpen} data={row} siteData={extraData} />
    </>
  );
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
  extraData: PropTypes.object,
};
