import React from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";

import { useReactiveVar } from "@apollo/client";
import PropTypes from "prop-types";

import {
  createAuditImagesVar,
  createAuditVar,
  editAuditImagesVar,
  editAuditVar,
} from "../../../graphql/localVariables/audit";
import { auditMutations } from "../../../graphql/mutations";
import { getAuditScores } from "../../../utils/raScore";
import Comment from "./Comment";
import PreviewImages from "./PreviewImages";
import Upload from "./Upload";

export default function Questions({ question, setAll, type, topics }) {
  // Apollo variables, mutations
  const editAudit = useReactiveVar(editAuditVar);
  const createAudit = useReactiveVar(createAuditVar);
  const updateAudit = type === "EDIT" ? editAudit : createAudit;
  const editAuditImages = useReactiveVar(editAuditImagesVar);
  const createAuditImages = useReactiveVar(createAuditImagesVar);
  const updateAuditImages =
    type === "EDIT" ? editAuditImages : createAuditImages;
  const {
    updateCreateResponsesAudit,
    updateEditResponsesAudit,
    updateCreateAuditImages,
    updateEditAuditImages,
    updateCreateAudit,
    updateEditAudit,
  } = auditMutations;
  const updateAuditFunction =
    type === "EDIT" ? updateEditResponsesAudit : updateCreateResponsesAudit;
  const updateAuditImagesFunction =
    type === "EDIT" ? updateEditAuditImages : updateCreateAuditImages;
  const updateKeyAuditFunction =
    type === "EDIT" ? updateEditAudit : updateCreateAudit;

  // Local response state
  const [response, setResponse] = React.useState({
    comment: "",
    response: null,
    auditTopicQuestion: null,
    penalty: 0,
  });
  const [images, setImages] = React.useState([]);

  // When responses update find response that matches the question
  React.useEffect(() => {
    if (
      updateAudit.auditResponses &&
      updateAudit.auditResponses.length !== 0 &&
      question.id
    ) {
      const found = updateAudit.auditResponses.find(
        (item) => question.id === item.auditTopicQuestion
      );
      if (found) {
        setResponse(found);
      } else {
        setResponse({
          comment: "",
          response: null,
          auditTopicQuestion: question.id,
          penalty: question.penalty,
          score: question.score,
        });
      }
    }
  }, [updateAudit.auditResponses, type]);

  React.useEffect(() => {
    setImages(
      updateAuditImages.filter(
        (i) => i.questionId === response.auditTopicQuestion
      )
    );
  }, [response, updateAuditImages]);

  // if id updates, set id of response
  React.useEffect(() => {
    changeResponse(question.id, "auditTopicQuestion");
    changeResponse(question.penalty, "penalty");
  }, [question.id]);

  // if setAll updates change all response to that value
  React.useEffect(() => {
    if (setAll !== null) {
      changeResponse(setAll, "response");
    }
  }, [setAll]);

  // when they update comments or response, updates local and global state
  const changeResponse = (value, type) => {
    updateAuditFunction(value, question.id, type);

    if (type === "response") {
      const scores = getAuditScores(topics, updateAudit.auditResponses);
      updateKeyAuditFunction("raScore", scores.raScore.toFixed(2) * 100);
    }
  };

  const changeFiles = (value) => {
    updateAuditImagesFunction([
      ...updateAuditImages.filter(
        (i) => i.questionId !== response.auditTopicQuestion
      ),
      ...value,
    ]);
  };

  return (
    <Grid container>
      <Grid
        item
        xs={6}
        sm={7}
        container
        style={{ padding: "20px", border: "1px solid black" }}
        alignItems="center"
      >
        <Grid item xs={12} md={9}>
          <Typography style={{ width: "85%", display: "contents" }}>
            {question.name}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} container alignItems="center">
          <Tooltip
            title={
              question.standardNumber
                ? `${question.standardNumber}`
                : "No standard number given"
            }
          >
            <InfoOutlinedIcon style={{ margin: "0px 20px" }} />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        sm={5}
        container
        style={{ padding: "30px", border: "1px solid black" }}
      >
        <FormControl style={{ width: "100%" }}>
          <RadioGroup
            row
            style={{ width: "100%", justifyContent: "space-between" }}
            value={response.response}
            onChange={(event) => changeResponse(event.target.value, "response")}
          >
            <FormControlLabel value="1" control={<Radio />} label="Yes" />
            <FormControlLabel value="-1" control={<Radio />} label="No" />
            <FormControlLabel value="0" control={<Radio />} label="N/A" />
          </RadioGroup>
        </FormControl>
        <Grid item xs={12} container justifyContent="space-around">
          <Upload
            fileObjects={images}
            handleFilesChange={changeFiles}
            questionId={question.id}
          />
          <Comment comment={response.comment} handleChange={changeResponse} />
        </Grid>
        <PreviewImages
          fileObjects={images}
          handleFilesChange={changeFiles}
          type={type}
        />
      </Grid>
    </Grid>
  );
}

Questions.propTypes = {
  question: PropTypes.object.isRequired,
  setAll: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  topics: PropTypes.array.isRequired,
};
