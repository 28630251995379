import * as React from "react";

import PropTypes from "prop-types";

import TableTemplate from "../../../../../../CustomComponents/TableTemplate/TableTemplate";
import Row from "./Row";
import TableFilterDialog from "./TableFilterDialog";
import { filterData } from "./searchFilterFunction";

export default function Table({ data }) {
  const onSiteChange = (event, newValue) => {
    setSiteFilter(newValue || { owner: { name: "" } });
  };

  const onAfterDateChange = (event) => {
    setAfterDate(event);
  };

  const onBeforeDateChange = (event) => {
    setBeforeDate(event);
  };

  const onProjectChange = (event, newValue) => {
    setProjectFilter(newValue || []);
  };

  const onTemplateChange = (event, newValue) => {
    setTemplateFilter(newValue || []);
  };

  const onStatusChange = (event, newValue) => {
    setStatusFilter(newValue || "");
  };

  const onIncidentChange = (event, newValue) => {
    setIncidentFilter(newValue || undefined);
  };

  const clearFilters = () => {
    setSiteFilter({ owner: { name: "" } });
    setAfterDate(null);
    setBeforeDate(null);
    setProjectFilter([]);
    setTemplateFilter([]);
    setStatusFilter("");
    setIncidentFilter("");
  };

  const [searchTerm, setSearchTerm] = React.useState("");
  const [siteFilter, setSiteFilter] = React.useState({ owner: { name: "" } });
  const [afterDate, setAfterDate] = React.useState(null);
  const [beforeDate, setBeforeDate] = React.useState(null);

  const [projectFilter, setProjectFilter] = React.useState([]);
  const [templateFilter, setTemplateFilter] = React.useState([]);
  const [status, setStatusFilter] = React.useState("");
  const [incident, setIncidentFilter] = React.useState("");

  const [filteredData, setFilteredData] = React.useState(data);

  // useEffect for filter update
  React.useEffect(() => {
    setFilteredData(
      filterData(
        data,
        searchTerm,
        siteFilter,
        afterDate,
        beforeDate,
        projectFilter,
        templateFilter,
        status,
        incident
      )
    );
  }, [
    searchTerm,
    siteFilter,
    afterDate,
    beforeDate,
    projectFilter,
    templateFilter,
    status,
    incident,
    data,
  ]);

  const headCells = [
    {
      uid: "name",
      numeric: false,
      disablePadding: false,
      label: "HA Name",
      sort: true,
    },
    {
      uid: "date",
      numeric: true,
      disablePadding: false,
      label: "Date",
      sort: true,
    },
    {
      uid: "Submitter Username",
      numeric: true,
      disablePadding: false,
      label: "Submitter Username",
      sort: true,
    },
    {
      uid: "company",
      numeric: true,
      disablePadding: false,
      label: "Company",
      sort: true,
    },
    {
      uid: "Location",
      numeric: true,
      disablePadding: false,
      label: "Location",
      sort: false,
    },
    {
      uid: "projects",
      numeric: true,
      disablePadding: false,
      label: "Project(s)",
      sort: false,
    },
    {
      uid: "templates",
      numeric: true,
      disablePadding: false,
      label: "Template(s)",
      sort: false,
    },
    {
      uid: "status",
      numeric: true,
      disablePadding: false,
      label: "HA Status",
      sort: true,
    },
    {
      uid: "revisionNumber",
      numeric: true,
      disablePadding: false,
      label: "Revisions",
      sort: true,
    },
    {
      uid: "incident",
      numeric: true,
      disablePadding: false,
      label: "With or Without Incident",
      sort: true,
    },
    {
      uid: "crewmembers",
      numeric: true,
      disablePadding: false,
      label: "# of Crew Members",
      sort: true,
    },
    {
      uid: "raScore",
      numeric: true,
      disablePadding: false,
      label: "Risk Score",
      sort: true,
    },
  ];

  return (
    <TableTemplate
      hasPagination={true}
      data={filteredData}
      title="Hazard Analyses"
      headCells={headCells}
      initialOrderBy="date"
      initialSort="desc"
      getItemsFromOrderBy={(a, b, orderBy) => {
        let aItem, bItem;

        switch (orderBy) {
          case "Submitter Username":
            aItem = a.submitter.username;
            bItem = b.submitter.username;
            break;
          case "company":
            aItem = a.submitter.company?.name;
            bItem = b.submitter.company?.name;
            break;
          case "status":
            aItem = a.isCompleted ? 1 : 0;
            bItem = b.isCompleted ? 1 : 0;
            break;
          case "incident":
            aItem = a.hasInjury ? "With" : "Without";
            bItem = b.hasInjury ? "With" : "Without";
            break;
          case "linked observations":
            aItem = a.observation.length;
            bItem = b.observation.length;
            break;
          case "crewmembers":
            aItem = a.haCrewMembers.length;
            bItem = b.haCrewMembers.length;
            break;
          default:
            aItem = a[orderBy];
            bItem = b[orderBy];
        }

        return { aItem, bItem };
      }}
      renderFilterDialog={(open, setOpen) => (
        <TableFilterDialog
          open={open}
          setOpen={setOpen}
          site={siteFilter}
          onSiteChange={onSiteChange}
          onAfterDateChange={onAfterDateChange}
          afterDate={afterDate}
          onBeforeDateChange={onBeforeDateChange}
          beforeDate={beforeDate}
          projectFilter={projectFilter}
          onProjectChange={onProjectChange}
          templateFilter={templateFilter}
          onTemplateChange={onTemplateChange}
          status={status}
          onStatusChange={onStatusChange}
          incident={incident}
          onIncidentChange={onIncidentChange}
          clearFilters={clearFilters}
        />
      )}
      searchTerm={searchTerm}
      updateSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
      getBadgeCount={() => {}}
      renderRow={(row) => <Row key={row.id} row={row} />}
    />
  );
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
};
