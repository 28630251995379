import * as React from "react";

import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  Autocomplete,
  TextField,
  Checkbox,
  Typography,
  FormControlLabel,
} from "@mui/material";

import { useQuery, useReactiveVar } from "@apollo/client";
import PropTypes from "prop-types";

import { onlineVar } from "../../../../../../../../graphql/localVariables/user";
import { GET_PERMISSION_GROUPS } from "../../../../../../../../graphql/queries/permissions";
import { CustomSwitch } from "../../../../../../../CustomComponents/Switch";

export default function TableFilterDialog({
  open,
  setOpen,
  clearFilters,
  inactive,
  onInactiveChange,
  groupsFilter,
  onGroupChange,
}) {
  const online = useReactiveVar(onlineVar);
  const { data: groups } = useQuery(GET_PERMISSION_GROUPS, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogContent style={{ minHeight: "100px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography>Show Inactive?</Typography>
            <Grid container alignItems="center">
              <FormControlLabel
                style={{ color: "black" }}
                control={
                  <CustomSwitch
                    checked={inactive}
                    onChange={onInactiveChange}
                  />
                }
                label={inactive ? "YES" : "NO"}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ padding: "5px" }}>
            <Autocomplete
              id="select-groups"
              options={groups ? groups.PermissionGroups : []}
              getOptionLabel={(option) => {
                return option.name;
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              value={groupsFilter}
              onChange={onGroupChange}
              multiple
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Permission Groups:"
                  value={params}
                  id={params.id}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          variant="contained"
          color="secondary"
        >
          FILTER
        </Button>
        <Button onClick={clearFilters} variant="contained" color="primary">
          CLEAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

TableFilterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
