import * as React from "react";
import { useNavigate } from "react-router";

import { Button } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { useMutation, useQuery } from "@apollo/client";
import PropTypes from "prop-types";

import { TOGGLE_COMPANY_STATUS } from "../../../../../graphql/mutations/admin/company";
import {
  GET_SITES,
  GET_OSPs,
  GET_USERS,
  COMPANY_DASHBOARD,
} from "../../../../../graphql/queries/admin/company";
import { CustomSwitch } from "../../../../CustomComponents/Switch";

export default function Row({ row }) {
  const navigate = useNavigate();

  const [editCompanyStatus] = useMutation(TOGGLE_COMPANY_STATUS, {
    refetchQueries: [{ query: COMPANY_DASHBOARD }],
  });

  // create dict mapping site to company
  const { data: sites } = useQuery(GET_SITES);
  const ownerSites = {};
  if (sites !== undefined) {
    sites.sites.map((site) => (ownerSites[site.id] = site.ownerId));
  }

  // create dict mapping project to company
  const { data: projects } = useQuery(GET_OSPs);
  const ownerProjects = {};
  if (projects !== undefined) {
    projects.ownerSiteProjects.map((osp) => {
      if (osp.project !== null) {
        ownerProjects[osp.project.id] = osp.owner.id;
      }
    });
  }

  // create dict mapping user to company
  const { data: users } = useQuery(GET_USERS);
  const ownerUsers = {};
  if (users !== undefined) {
    users.users.map((user) => {
      if (user.company !== null) {
        ownerUsers[user.id] = user.company.id;
      }
    });
  }

  const handleIsActiveChange = (event, id) => {
    const active = [];
    const inactive = [];
    if (event.target.checked) {
      active.push(Number(id));
    } else {
      inactive.push(Number(id));
    }
    editCompanyStatus({
      variables: { inactiveCompanies: inactive, activeCompanies: active },
    });
  };
  return (
    <TableRow hover key={row.id} style={{ cursor: "pointer" }}>
      <TableCell component="th" scope="row">
        {row.name}
      </TableCell>
      <TableCell>{row.isContractor ? "Yes" : "No"}</TableCell>
      <TableCell>
        {Object.values(ownerSites).reduce((acc, value) => {
          return value === row.id ? acc + 1 : acc;
        }, 0)}
      </TableCell>
      <TableCell>
        {Object.values(ownerProjects).reduce((acc, value) => {
          return value === row.id ? acc + 1 : acc;
        }, 0)}
      </TableCell>
      <TableCell>
        {Object.values(ownerUsers).reduce((acc, value) => {
          return value === row.id ? acc + 1 : acc;
        }, 0)}
      </TableCell>
      <TableCell>{"Yes"}</TableCell>
      <TableCell>
        <CustomSwitch
          checked={row.isActive}
          name={row.id}
          onChange={(event) => handleIsActiveChange(event, row.id)}
        />
      </TableCell>
      <TableCell>
        <Button
          variant="contained"
          color="yellow0"
          onClick={() => navigate(`/admin/company/${row.id}`)}
        >
          VIEW
        </Button>
      </TableCell>
    </TableRow>
  );
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
};
