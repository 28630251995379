import * as React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Autocomplete,
  TextField,
  Grid,
  Button,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import { useQuery, useReactiveVar } from "@apollo/client";
import PropTypes from "prop-types";

import { onlineVar } from "../../../../../../../graphql/localVariables/user";
import {
  GET_OUTCOMES,
  GET_SITES,
} from "../../../../../../../graphql/queries/index";
import { GET_CONTRACTORS_AT_OWNER_SITE_PROJECT } from "../../../../../../../graphql/queries/offlineMode";

export default function TableFilterDialog({
  open,
  setOpen,
  onSiteChange,
  site,
  contractor,
  onContractorChange,
  onAfterDateChange,
  afterDate,
  onBeforeDateChange,
  beforeDate,
  onOutcomeChange,
  outcomes,
  onStatusChange,
  status,
  clearFilters,
}) {
  const online = useReactiveVar(onlineVar);
  const { data: sites } = useQuery(GET_SITES, {
    fetchPolicy: online ? "cache-first" : "cache-only",
  });
  const { data: contractors } = useQuery(
    GET_CONTRACTORS_AT_OWNER_SITE_PROJECT,
    { fetchPolicy: online ? "network-only" : "cache-only" }
  );
  const { data: getOutcomes } = useQuery(GET_OUTCOMES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  const removeDuplicateContractors = (arr) => {
    const uniqueIds = [];
    const filtered = arr.filter((c) => {
      const isDuplicate = uniqueIds.includes(c.contractor.id);
      if (!isDuplicate) {
        uniqueIds.push(c.contractor.id);
        return true;
      }
      return false;
    });
    return filtered;
  };

  const getContractors = () => {
    if (site.owner.name !== "") {
      return contractors?.ownerSiteProjectContractors
        ? contractors.ownerSiteProjectContractors.filter(
            (c) =>
              c.ownerSiteProject.owner?.id === site.owner.id &&
              c.ownerSiteProject.site?.id === site.site.id
          )
        : [];
    }
    return contractors?.ownerSiteProjectContractors
      ? removeDuplicateContractors(contractors.ownerSiteProjectContractors)
      : [];
  };

  const filter = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={4} style={{ padding: "5px" }}>
            <Autocomplete
              id="filter-site"
              options={
                sites?.ownerSiteProjects
                  ? [...sites.ownerSiteProjects].sort((a, b) =>
                      a.site.name > b.site.name ? 1 : -1
                    )
                  : []
              }
              getOptionLabel={(option) =>
                option.site
                  ? `${option.site?.name} [${option.owner?.name}]`
                  : option.owner?.name
              }
              value={site}
              onChange={onSiteChange}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.site
                      ? `${option.site?.name} [${option.owner?.name}]`
                      : option.owner?.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Site [Site Owner]"
                  value={params}
                  id={params.id}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "5px" }}>
            <Autocomplete
              id="filter-contractor"
              options={getContractors().sort((a, b) =>
                a.contractor.name > b.contractor.name ? 1 : -1
              )}
              getOptionLabel={(option) => option.contractor?.name}
              value={contractor}
              onChange={onContractorChange}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.contractor.id}>
                    {option.contractor?.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Contractor"
                  value={params}
                  id={params.id}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "5px" }}>
            <Autocomplete
              id="filter-outcomes"
              options={
                getOutcomes?.outcomes
                  ? [...getOutcomes.outcomes].sort((a, b) =>
                      a.name > b.name ? 1 : -1
                    )
                  : []
              }
              getOptionLabel={(option) => {
                return option.name;
              }}
              value={outcomes}
              onChange={onOutcomeChange}
              multiple
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Outcome"
                  value={params}
                  id={params.id}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "5px" }}>
            <Autocomplete
              id="filter-planned"
              options={["Planned", "Unplanned"]}
              getOptionLabel={(option) => {
                return option;
              }}
              value={status}
              onChange={onStatusChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Planned Status"
                  value={params}
                  id={params.id}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} style={{ padding: "5px" }} container>
            <DesktopDatePicker
              label="Filter After This Date"
              inputFormat="MMM DD, yyyy"
              value={afterDate}
              onChange={onAfterDateChange}
              renderInput={(params) => (
                <TextField
                  sx={{ width: "45%", marginRight: "20px" }}
                  variant="standard"
                  {...params}
                />
              )}
              disableMaskedInput
            />
            <DesktopDatePicker
              label="Filter Before This Date"
              inputFormat="MMM DD, yyyy"
              value={beforeDate}
              onChange={onBeforeDateChange}
              renderInput={(params) => (
                <TextField
                  sx={{ width: "45%" }}
                  variant="standard"
                  {...params}
                />
              )}
              disableMaskedInput
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={filter} variant="contained" color="secondary">
          FILTER
        </Button>
        <Button onClick={clearFilters} variant="contained" color="primary">
          CLEAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

TableFilterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSiteChange: PropTypes.func.isRequired,
  site: PropTypes.object.isRequired,
  onAfterDateChange: PropTypes.func.isRequired,
  afterDate: PropTypes.instanceOf(Date).isRequired,
  onBeforeDateChange: PropTypes.func.isRequired,
  beforeDate: PropTypes.instanceOf(Date).isRequired,
  projectFilter: PropTypes.array.isRequired,
  onProjectChange: PropTypes.func.isRequired,
  contractor: PropTypes.array.isRequired,
  onContractorChange: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  outcomes: PropTypes.array.isRequired,
  onOutcomeChange: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
};
