import * as React from "react";

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from "@mui/material";

export default function TableFilterDialog({
  open,
  setOpen,
  contractor,
  onContractorChange,
  nixnuser,
  onNixnUserChange,
  active,
  onActiveChange,
  clearFilters,
}) {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={4} style={{ padding: "5px" }}>
            <Autocomplete
              id="filter-contractor"
              options={["Yes", "No"]}
              value={contractor}
              onChange={onContractorChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Contractor"
                  value={params}
                  id={params.id}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "5px" }}>
            <Autocomplete
              id="filter-nixn-user"
              options={["Yes", "No"]}
              value={nixnuser}
              onChange={onNixnUserChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by NIXN User"
                  value={params}
                  id={params.id}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "5px" }}>
            <Autocomplete
              id="filter-active"
              options={["Active", "Inactive"]}
              value={active}
              onChange={onActiveChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Active"
                  value={params}
                  id={params.id}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          variant="contained"
          color="secondary"
        >
          FILTER
        </Button>
        <Button onClick={clearFilters} variant="contained" color="primary">
          CLEAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}
