import React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";

import Check from "@mui/icons-material/Check";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import { useLazyQuery, useMutation } from "@apollo/client";

import { MIT_MERGE } from "../../../graphql/mutations/admin/arm";
import { GET_ACTIVE_ARMS_ADMIN } from "../../../graphql/queries/admin/arm";
import removeDuplicates from "../../../utils/removeDuplicates";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import SearchField from "../../CustomComponents/SearchField";
import { CustomTextField } from "../../CustomStyles/LightTextField";
import { CustomDialog } from "../../CustomStyles/dialog";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";

export default function Merge({ open, handleClose }) {
  const [merge, setMerge] = React.useState({ m: { name: "" } });
  const [mitigators, setMitigators] = React.useState([]);
  const [search, setSearch] = React.useState("");

  const [getMitigators, { data: activeMit, loading }] = useLazyQuery(
    GET_ACTIVE_ARMS_ADMIN,
    {
      fetchPolicy: "network-only",
    }
  );
  const [mitMerge] = useMutation(MIT_MERGE, {
    onCompleted() {
      onCompletedFunc("Mitigator has been merged");
      setMerge({ m: { name: "" } });
      setMitigators([]);
      setSearch("");
      handleClose();
    },
    onError(error) {
      onErrorFunc(error);
    },
  });

  React.useEffect(() => {
    if (open) {
      getMitigators();
    }
  }, [open]);

  const add = (m) => {
    setMitigators((prev) => [...prev, m]);
  };

  const remove = (m) => {
    setMitigators((prev) => prev.filter((p) => p.id !== m.id));
  };

  const onSave = () => {
    if (merge.m.id) {
      mitigators.forEach((m) =>
        mitMerge({
          variables: { idKeep: Number(merge.m.id), idRemove: Number(m) },
        })
      );
    } else {
      onErrorFunc("Select the mitigator to keep");
    }
  };

  const onCancel = () => {
    handleClose();
    setMerge({ m: { name: "" } });
    setMitigators([]);
  };

  const mit = activeMit
    ? removeDuplicates(activeMit.actionRiskMitigators, "m", "id")
    : [];

  const filteredMit =
    activeMit && merge.m.id
      ? removeDuplicates(activeMit.actionRiskMitigators, "m", "id")
          .filter(
            (m) =>
              m.m.id !== merge.m.id &&
              m.m.name.toLowerCase().includes(search.toLowerCase())
          )
          .sort((a, b) => (a.m.name > b.m.name ? 1 : -1))
      : [];

  const Row = ({ index, style }) => {
    const m = filteredMit[index];

    return (
      <Grid
        key={m.m.id}
        item
        xs={12}
        container
        alignItems="center"
        style={{
          ...style,
          borderBottom: "1px solid grey",
        }}
      >
        <Grid item xs={1.5}>
          {mitigators.findIndex((mit) => mit === m.m.id) >= 0 ? (
            <IconButton
              style={{ marginRight: "10px" }}
              onClick={() => remove(m.m.id)}
            >
              <Check style={{ color: "white" }} />
            </IconButton>
          ) : (
            <IconButton
              style={{ marginRight: "10px" }}
              onClick={() => add(m.m.id)}
            >
              <CheckBoxOutlineBlank style={{ color: "white" }} />
            </IconButton>
          )}
        </Grid>
        <Grid item xs={10.5}>
          <Typography>{m.m.name}</Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <CustomDialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle style={{ ...pageTitleStyles }}>ARM MERGE.</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={6}>
            <Typography>Select mitigator to keep.</Typography>
            {loading && !activeMit ? (
              <Grid container justifyContent="center" alignItems="center">
                <CircularProgress color="secondary" />
              </Grid>
            ) : (
              <Autocomplete
                id="select-merge-mitigator"
                options={mit}
                value={merge}
                onChange={(event, value) =>
                  setMerge(value || { m: { name: "" } })
                }
                getOptionLabel={(option) => option.m.name}
                isOptionEqualToValue={(option, value) =>
                  option.m.id === value.m.id
                }
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    variant="standard"
                    label={"Mitigator:"}
                  />
                )}
                style={{ width: "80%", marginTop: "15px" }}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography>
              Select mitigator(s) to merge with the one on the left.
            </Typography>
            <SearchField value={search} setValue={setSearch} />
            <Grid container style={{ maxHeight: "300px", overflowY: "auto" }}>
              {filteredMit.length > 0 && merge.m.id && (
                <div
                  style={{
                    minHeight: "300px",
                    width: "95%",
                  }}
                >
                  <AutoSizer>
                    {({ height, width }) => (
                      <List
                        className="List"
                        height={height}
                        itemCount={filteredMit.length}
                        itemSize={100}
                        width={width}
                      >
                        {Row}
                      </List>
                    )}
                  </AutoSizer>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="yellow0"
          sx={{ mr: 1 }}
          onClick={onSave}
        >
          SAVE
        </Button>
        <Button
          variant="contained"
          style={{ marginRight: "10px" }}
          onClick={onCancel}
        >
          CANCEL
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}
