import React from "react";

import { Grid, Typography } from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import {
  createObservationVar,
  editObservationVar,
} from "../../../graphql/localVariables/observation";
import { observationMutations } from "../../../graphql/mutations";
import SelectedUnselected from "../../CustomComponents/SelectedUnselected";
import CreateARM from "./CreateARM";

export default function MitigatorsForm({
  type,
  onEditSection,
  mitigators: data,
  loading,
  permissions,
}) {
  // Global Variables
  const editObs = useReactiveVar(editObservationVar);
  const createObs = useReactiveVar(createObservationVar);
  const form = type === "EDIT" ? editObs : createObs;

  // Mutations
  const { updateKeyEditObservation, updateKeyCreateObservation } =
    observationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditObservation : updateKeyCreateObservation;

  // Local Variables
  const [open, setOpen] = React.useState(false);
  const [selectedMitigators, setSelectedMitigators] = React.useState(
    form?.mitigators || []
  );
  const [unselectedMitigators, setUnselectedMitigators] = React.useState([]);
  const [focusList, setFocusList] = React.useState([]);

  const updateState = (newSelected, newUnselected) => {
    onEditSection(4);
    const formatNewSelected = formatMitigators(newSelected, true);
    const formatUnselected = formatMitigators(newUnselected, false);
    updateObsKeyFunction("mitigators", formatNewSelected);
    updateObsKeyFunction("unselectedMitigators", formatUnselected);

    const currentMitIds = newSelected.map((s) => s.m.id);

    // Remove images of potentially removed action
    const removedUploadedFiles = form.files.filter((f) => {
      if (f.associatedItem.m && !f.associatedItem.isCorrection) {
        return (
          !currentMitIds.includes(f.associatedItem.m.id) && f.originalFile.id
        );
      } else if (f.associatedItem.m && f.associatedItem.isCorrection) {
        return (
          currentMitIds.includes(f.associatedItem.m.id) && f.originalFile.id
        );
      }
      return false;
    });

    if (form.removeMediaFiles) {
      updateObsKeyFunction("removeMediaFiles", [
        ...form.removeMediaFiles,
        ...removedUploadedFiles.map((f) => f.originalFile.id),
      ]);
    } else {
      updateObsKeyFunction("removeMediaFiles", [
        ...removedUploadedFiles.map((f) => f.originalFile.id),
      ]);
    }

    updateObsKeyFunction(
      "files",
      form.files.filter((f) => {
        if (f.associatedItem?.m?.id && !f.associatedItem.isCorrection) {
          return currentMitIds.includes(f.associatedItem.m.id);
        } else if (f.associatedItem?.m?.id && f.associatedItem.isCorrection) {
          return !currentMitIds.includes(f.associatedItem.m.id);
        }
        return true;
      })
    );
  };

  const formatMitigators = (array, isActive) => {
    return array.map((c) => {
      return {
        ...c,
        isActive: isActive,
      };
    });
  };

  React.useEffect(() => {
    if (data && form.risks) {
      // Remove selected mitigators that do not fit the match selected risks
      const filteredSelectedMitigators = selectedMitigators.filter(
        (mitigatorArm) =>
          form.risks.findIndex(
            (riskArm) =>
              Number(riskArm.a.id) === Number(mitigatorArm.a.id) &&
              Number(riskArm.r.id) === Number(mitigatorArm.r.id)
          ) >= 0
      );

      // Format so isActive = true
      const formattedSelected = formatMitigators(
        filteredSelectedMitigators,
        true
      );

      // Set local and global state
      setSelectedMitigators(formattedSelected);
      updateObsKeyFunction("mitigators", formattedSelected);

      // Filter unselected mitigators and remove already selected ones
      const filteredUnselectedMitigators = data.companyArms
        .map((carm) => carm.arm)
        .filter(
          (mitigatorArm) =>
            form.risks.findIndex(
              (riskArm) =>
                Number(riskArm.a.id) === Number(mitigatorArm.a.id) &&
                Number(riskArm.r.id) === Number(mitigatorArm.r.id)
            ) >= 0 &&
            filteredSelectedMitigators.findIndex(
              (riskArm) =>
                Number(riskArm.a.id) === Number(mitigatorArm.a.id) &&
                Number(riskArm.r.id) === Number(mitigatorArm.r.id) &&
                Number(riskArm.m.id) === Number(mitigatorArm.m.id)
            ) < 0
        );

      const unselectedOldState = [...form.unselectedMitigators];

      // Keep the old state of isRootCause and isCorrection
      // If new mitigator takes those data points from backend data
      const merge = filteredUnselectedMitigators.map((prev) => {
        const index = unselectedOldState.findIndex(
          (mitArm) => Number(mitArm.id) === Number(prev.id)
        );
        if (index < 0) {
          return prev;
        } else {
          return unselectedOldState[index];
        }
      });

      // Format to make sure isActive = false
      const formatUnselected =
        unselectedOldState.length === 0
          ? formatMitigators(filteredUnselectedMitigators, false)
          : formatMitigators(merge, false);

      // Update global and local state
      updateObsKeyFunction("unselectedMitigators", formatUnselected);
      setUnselectedMitigators(formatUnselected);
    }
  }, [data, form.risks]);

  React.useEffect(() => {
    if (form.mitigators) {
      setSelectedMitigators(form.mitigators);
    }
  }, [form.mitigators]);

  React.useEffect(() => {
    if (form.ha?.haArms) {
      setFocusList(form.ha.haArms.map((arm) => Number(arm.arm.id)));
    }

    if (form.ha?.name === "") {
      setFocusList([]);
    }
  }, [form.ha]);

  return (
    <Grid testid="Mitigators">
      <SelectedUnselected
        selected={selectedMitigators}
        unselected={unselectedMitigators}
        setSelected={setSelectedMitigators}
        setUnselected={setUnselectedMitigators}
        updateState={updateState}
        idKey="m"
        itemType="mitigator"
        focusList={focusList || []}
        label="Mitigators"
        loadingUnselected={loading}
        hideMobileSearch
      />
      {permissions.includes("ARM") ? (
        <>
          <Typography
            onClick={() => setOpen(true)}
            style={{ marginTop: "20px", cursor: "pointer" }}
          >
            If you don&apos;t see the mitigator you are looking for,{" "}
            <span
              style={{
                background: "#FFB700",
              }}
            >
              click here
            </span>{" "}
            to add it.
          </Typography>
          <CreateARM
            open={open}
            handleClose={() => setOpen(false)}
            tab="mitigator"
            type={type}
          />
        </>
      ) : null}
    </Grid>
  );
}
