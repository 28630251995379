import * as React from "react";

import { CircularProgress, Grid } from "@mui/material";

import { useQuery, useReactiveVar } from "@apollo/client";

import { onlineVar } from "../../../../../../../../graphql/localVariables/user";
import { GET_COMPANY_USERS } from "../../../../../../../../graphql/queries/admin/company";
import TableTemplate from "../../../../../../../CustomComponents/TableTemplate/TableTemplate";
import Row from "./Row";
import TableFilterDialog from "./TableFilterDialog";
import { filterData } from "./searchFilterFunction";

export default function Table({ company }) {
  const online = useReactiveVar(onlineVar);
  const { data, loading } = useQuery(GET_COMPANY_USERS, {
    variables: { companyId: Number(company.id) },
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  const onGroupChange = (event, newValue) => {
    setGroupsFilter(newValue || []);
  };

  const onInactiveChange = (event) => {
    setInActive(event.target.checked);
  };

  const clearFilters = () => {
    setGroupsFilter([]);
    setInActive(false);
  };

  const [searchTerm, setSearchTerm] = React.useState("");
  const [inactive, setInActive] = React.useState(false);
  const [groupsFilter, setGroupsFilter] = React.useState([]);

  const [filteredData, setFilteredData] = React.useState([]);

  // useEffect for filter update
  React.useEffect(() => {
    if (data) {
      setFilteredData(
        filterData(data.users, searchTerm, groupsFilter, inactive)
      );
    }
  }, [data, searchTerm, groupsFilter]);

  return (
    <>
      {loading ? (
        <Grid container justifyContent="center">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <TableTemplate
          hasPagination={true}
          data={filteredData}
          title="Users"
          headCells={[
            {
              uid: "name",
              numeric: false,
              disablePadding: false,
              label: "Name",
              sort: true,
            },
            {
              uid: "username",
              numeric: true,
              disablePadding: false,
              label: "Username",
              sort: true,
            },
            {
              uid: "groups",
              numeric: true,
              disablePadding: false,
              label: "Permission Groups",
            },
            {
              uid: "ha",
              numeric: true,
              disablePadding: false,
              label: "# of HAs",
            },
            {
              uid: "obs",
              numeric: true,
              disablePadding: false,
              label: "# of Observations",
            },
            {
              uid: "audit",
              numeric: true,
              disablePadding: false,
              label: "# of Audits",
            },
          ]}
          initialOrderBy="username"
          getItemsFromOrderBy={(a, b, orderBy) => {
            let aItem, bItem;

            if (orderBy === "name") {
              aItem = `${a.firstName} ${a.lastName}`;
              bItem = `${b.firstName} ${b.lastName}`;
            } else {
              aItem = a[orderBy];
              bItem = b[orderBy];
            }

            return { aItem, bItem };
          }}
          renderFilterDialog={(open, setOpen) => (
            <TableFilterDialog
              open={open}
              setOpen={setOpen}
              clearFilters={clearFilters}
              inactive={inactive}
              onInactiveChange={onInactiveChange}
              groupsFilter={groupsFilter}
              onGroupChange={onGroupChange}
            />
          )}
          searchTerm={searchTerm}
          updateSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
          getBadgeCount={() => {}}
          renderRow={(row) => <Row key={row.id} row={row} />}
        />
      )}
    </>
  );
}
