import React from "react";
import { useNavigate } from "react-router-dom";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  List,
  ListItemText,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemButton,
} from "@mui/material";
import { useTheme } from "@mui/styles";

export default function ReportingItems({
  iconSelectedColor,
  textSelectedColor,
  endBarSelectedColor,
  classes,
  permissions,
  keyHook,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const expandIconColor = open ? endBarSelectedColor : theme.palette.grey1.main;

  let customReportItem;
  if (permissions.includes("CUSTOM_REPORTING")) {
    customReportItem = (
      <ListItemButton onClick={() => navigate("/reporting/custom")}>
        <ListItemText primary="Custom Reporting" className={classes.listText} />
      </ListItemButton>
    );
  }
  return (
    <Accordion disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: expandIconColor }} />}
        aria-controls="reporting-content"
        id="reporting-header"
        style={{
          backgroundColor: theme.palette.black0.main,
          color: theme.palette.grey1.main,
        }}
        onClick={handleClick}
      >
        <SearchOutlinedIcon
          className={classes.listIcons}
          style={{
            color:
              open || keyHook === "reporting" ? iconSelectedColor : undefined,
          }}
        />
        <Typography
          className={classes.listText}
          style={{
            color:
              open || keyHook === "reporting" ? textSelectedColor : undefined,
          }}
        >
          Reporting
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          backgroundColor: theme.palette.black0.main,
          paddingBottom: "0px",
        }}
      >
        <List>{customReportItem}</List>
      </AccordionDetails>
    </Accordion>
  );
}
