import React from "react";

import { Button } from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import { permissionsVar } from "../../../../graphql/localVariables/user";
import CreateCompanyDialog from "./CreateCompanyDialog";

export default function CreateCompany() {
  const permissions = useReactiveVar(permissionsVar);
  const [open, setOpen] = React.useState(false);

  return (
    <>
      {permissions.includes("CREATE_COMPANY") && (
        <Button
          variant="contained"
          color="yellow0"
          onClick={() => setOpen(true)}
        >
          ADD NEW COMPANY
        </Button>
      )}
      <CreateCompanyDialog open={open} setOpen={setOpen} />
    </>
  );
}
