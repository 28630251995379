import React from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  Tooltip,
  Autocomplete,
  Checkbox,
  Grid,
  Typography,
} from "@mui/material";

import { useReactiveVar, useQuery, useLazyQuery } from "@apollo/client";

import {
  createObservationVar,
  editObservationVar,
} from "../../../graphql/localVariables/observation";
import { onlineVar } from "../../../graphql/localVariables/user";
import { observationMutations } from "../../../graphql/mutations";
import { GET_CREWMEMBERS, GET_OWNERSITE } from "../../../graphql/queries";
import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import {
  GET_OBS_CORRECTION_USERS,
  GET_OWNER_OSP,
  GET_SITE_OSP,
} from "../../../graphql/queries/observations";
import removeDuplicates from "../../../utils/removeDuplicates";
import { headerStyles } from "../../CustomStyles/pageTitle";

export default function AssignedCorrections({ type, selected }) {
  // Apollo queries, variables, mutations
  const editObservation = useReactiveVar(editObservationVar);
  const createObservation = useReactiveVar(createObservationVar);
  const updateObservation =
    type === "EDIT" ? editObservation : createObservation;
  const online = useReactiveVar(onlineVar);
  const { updateKeyCreateObservation, updateKeyEditObservation } =
    observationMutations;
  const updateObservationFunction =
    type === "EDIT" ? updateKeyEditObservation : updateKeyCreateObservation;

  // Query for users, currentUser, and crewmembers
  const [getCorrectionUsers, { data: users }] = useLazyQuery(
    GET_OBS_CORRECTION_USERS,
    {
      fetchPolicy: online ? "network-only" : "cache-only",
    }
  );

  const { data: userData } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  const { data: ownerOSP } = useQuery(GET_OWNER_OSP, {
    variables: { ownerId: Number(updateObservation.owner.id) },
  });
  const { data: contractorOSP } = useQuery(GET_OWNER_OSP, {
    variables: { ownerId: Number(updateObservation.contractor.id) },
  });
  const { data: siteOSP } = useQuery(GET_SITE_OSP, {
    variables: {
      ownerId: Number(updateObservation.owner.id),
      siteId: Number(updateObservation.site.id),
    },
  });
  const [getOwnerSite, { data: os }] = useLazyQuery(GET_OWNERSITE, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const [getCrewMembers, { data: crewMembers }] = useLazyQuery(
    GET_CREWMEMBERS,
    { fetchPolicy: online ? "network-only" : "cache-only" }
  );

  const [allUsers, setAllUsers] = React.useState([]);

  // Modal state
  const [open, setOpen] = React.useState(false);

  // correctionComments state
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    if (open && updateObservation.owner && updateObservation.site) {
      getOwnerSite({
        variables: {
          ownerId: Number(updateObservation.owner.id),
          siteId: Number(updateObservation.site.id),
        },
      });
    }
  }, [open, updateObservation.owner, updateObservation.site]);

  React.useEffect(() => {
    if (ownerOSP && siteOSP && contractorOSP) {
      const list = [Number(updateObservation.ospId)];
      if (ownerOSP.ownerSiteProjects.length > 0) {
        list.push(Number(ownerOSP.ownerSiteProjects[0].id));
      }
      if (siteOSP.ownerSiteProjects.length > 0) {
        list.push(Number(siteOSP.ownerSiteProjects[0].id));
      }
      if (contractorOSP.ownerSiteProjects.length > 0) {
        list.push(Number(contractorOSP.ownerSiteProjects[0].id));
      }
      getCorrectionUsers({
        variables: {
          ospIds: list,
        },
      });
    }
  }, [ownerOSP, siteOSP, contractorOSP]);

  React.useEffect(() => {
    if (users) {
      setAllUsers(
        removeDuplicates([...users.users, ...users.supervisors], "id")
      );
    }
  }, [users]);

  // When ospId updates, fetch crewmembers
  React.useEffect(() => {
    if (os) {
      getCrewMembers({
        variables: {
          ospId: Number(os.ownerSiteProjects[0].id),
        },
      });
    }
  }, [os]);

  // update local comments state when global is updated
  React.useEffect(() => {
    if (updateObservation.correctionComments) {
      setValue(updateObservation.correctionComments);
    }
  }, [updateObservation.correctionComments]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
    updateObservationFunction("correctionComments", value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  // When the users to notify updates
  const handleChange = (event, newValue) => {
    updateObservationFunction("foreman", newValue);
  };

  // when crewmembers observed updates
  const handleCrewMemberChange = (event, newValue) => {
    updateObservationFunction("crewMembers", newValue);
  };

  // filter current user out of usernames
  const filterData = () => {
    if (userData?.currentUser) {
      return allUsers.filter((u) => {
        return u.id !== userData.currentUser.id;
      });
    }
    return [];
  };

  // icons for drop down selects
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <div style={{ display: "contents" }}>
      <Grid item xs={12} align="right">
        <Tooltip title="Send notification that corrections need to be made.">
          <Button
            variant="contained"
            color="yellow0"
            sx={{ mr: 1 }}
            onClick={handleOpen}
          >
            SEND NOTIFICATION
          </Button>
        </Tooltip>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent sx={{ minWidth: "300px", minHeight: "350px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography sx={headerStyles}>
                SEND CORRECTIONS NOTIFICATION.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                id="select-users"
                options={filterData()}
                value={updateObservation.foreman || []}
                multiple
                onChange={handleChange}
                getOptionLabel={(option) =>
                  option.firstName !== ""
                    ? `${option.firstName} ${option.lastName} (${option.username}) [${option.company?.name}]`
                    : `${option.username} [${option.company?.name}]`
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.id}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.firstName !== ""
                      ? `${option.firstName} ${option.lastName} (${option.username}) [${option.company?.name}]`
                      : `${option.username} [${option.company?.name}]`}
                  </li>
                )}
                disableClearable
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={"Users to be notified:"}
                  />
                )}
                sx={{ padding: "10px, 0px" }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={selected.map((c) => c.m.name).join(", ")}
                multiline
                variant="standard"
                label={"Corrections Present:"}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="notify-select-crew"
                options={crewMembers?.ownerSiteProjectCrewMembers || []}
                value={updateObservation.crewMembers || []}
                multiple
                onChange={handleCrewMemberChange}
                getOptionLabel={(option) =>
                  `${option.crewMember.firstName} ${option.crewMember.lastName} [${option.crewMember.company?.name}]`
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={`${option.crewMember.id}`}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.crewMember.firstName} {option.crewMember.lastName} [
                    {option.crewMember.company?.name}]
                  </li>
                )}
                disableClearable
                isOptionEqualToValue={(option, value) =>
                  (option.crewMember.firstName === value.crewMember.firstName &&
                    option.crewMember.lastName === value.crewMember.lastName) ||
                  value.name === ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={"Crew Members Observed:"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={value}
                onChange={(event) => setValue(event.target.value)}
                multiline
                rows={8}
                inputProps={{ maxLength: 300 }}
                variant="outlined"
                label={"Comments:"}
                sx={{ width: "100%" }}
              />
              <Typography>
                {value.length} / {300}
              </Typography>
              <InfoOutlinedIcon
                color="primary"
                sx={{
                  fontSize: "small",
                  alignSelf: "center",
                  marginRight: "3px",
                  marginTop: "10px",
                }}
              />
              <Typography
                sx={{
                  fontSize: ".875rem",
                  display: "inline",
                  marginTop: "10px",
                }}
              >
                This notification will be sent after the observation is
                submitted.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>SEND</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
