import React from "react";

import { Grid } from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import {
  createObservationVar,
  editObservationVar,
} from "../../../graphql/localVariables/observation";
import { observationMutations } from "../../../graphql/mutations";
import SelectedUnselected from "../../CustomComponents/SelectedUnselected";
import AssignCorrections from "./AssignCorrectionsModal";

export default function CorrectionsForm({ type, onEditSection }) {
  // Global Variables
  const editObs = useReactiveVar(editObservationVar);
  const createObs = useReactiveVar(createObservationVar);
  const form = type === "EDIT" ? editObs : createObs;

  // Mutations
  const { updateKeyEditObservation, updateKeyCreateObservation } =
    observationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditObservation : updateKeyCreateObservation;

  const [selectedCorrections, setSelectedCorrections] = React.useState([]);
  const [unselectedCorrections, setUnselectedCorrections] = React.useState([]);

  const updateState = (newCorrections) => {
    onEditSection(5);
    const unselectedMitCorrections = newCorrections.map((c) => ({
      ...c,
      isCorrection: true,
      isActive: false,
    }));
    const otherMit = form.unselectedMitigators.filter(
      (m) =>
        unselectedMitCorrections.findIndex(
          (c) => Number(c.id) === Number(m.id)
        ) < 0
    );
    const formatOtherMit = otherMit.map((c) => ({
      ...c,
      isCorrection: false,
      isActive: false,
    }));

    updateObsKeyFunction("unselectedMitigators", [
      ...unselectedMitCorrections,
      ...formatOtherMit,
    ]);

    const currentCorrIds = newCorrections.map((s) => s.m.id);

    // Remove images of potentially removed correction
    const removedUploadedFiles = form.files.filter((f) =>
      f.associatedItem?.m?.id && f.associatedItem.isCorrection
        ? currentCorrIds.includes(f.associatedItem.m.id) && f.originalFile.id
        : false
    );

    if (form.removeMediaFiles) {
      updateObsKeyFunction("removeMediaFiles", [
        ...form.removeMediaFiles,
        ...removedUploadedFiles.map((f) => f.originalFile.id),
      ]);
    } else {
      updateObsKeyFunction("removeMediaFiles", [
        ...removedUploadedFiles.map((f) => f.originalFile.id),
      ]);
    }
    updateObsKeyFunction(
      "files",
      form.files.filter((f) =>
        f.associatedItem?.m?.id && f.associatedItem.isCorrection
          ? currentCorrIds.includes(f.associatedItem.m.id)
          : true
      )
    );
  };
  React.useEffect(() => {
    if (form?.unselectedMitigators) {
      let selected = [];
      let unselected = [];
      form.unselectedMitigators.forEach((m) => {
        if (!m.isActive) {
          if (m.isCorrection) {
            selected.push(m);
          } else {
            unselected.push(m);
          }
        }
      });
      setSelectedCorrections([...selected]);
      setUnselectedCorrections([...unselected]);
    }
  }, [form.unselectedMitigators]);

  return (
    <Grid testid="Corrections">
      {selectedCorrections.length !== 0 ? (
        <AssignCorrections selected={selectedCorrections} type={type} />
      ) : null}
      <SelectedUnselected
        selected={selectedCorrections}
        unselected={unselectedCorrections}
        setSelected={setSelectedCorrections}
        setUnselected={setUnselectedCorrections}
        updateState={updateState}
        idKey="m"
        itemType="correction"
        focusList={[]}
        label="Corrections"
        hideMobileSearch
      />
    </Grid>
  );
}
