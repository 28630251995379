import { makeVar } from "@apollo/client";

import makeVarPersisted from "../makeVarPersisted";

// CREATE Audit //
export const initialState = {
  ospId: null,
  location: "",
  audit: { name: "", id: null },
  auditTopics: [],
  auditResponses: [],
  removeImages: [],
  date: new Date(),
  time: new Date(),
  contributors: [],
  raScore: 0,
};
export const createAuditVar = makeVarPersisted(initialState, "createAuditVar");
export const createAuditImagesVar = makeVar([]);

// EDIT Audit //
const initialEditState = {
  id: null,
  audit: { name: "", id: null },
  auditTopics: [],
  ospId: null,
  location: "",
  auditResponses: [],
  removeImages: [],
  date: new Date(),
  time: new Date(),
  contributors: [],
  creator: "",
  raScore: 0,
};
export const editAuditVar = makeVarPersisted(initialEditState, "editAuditVar");
export const editAuditImagesVar = makeVar([]);

export const viewAuditFiltersVar = makeVarPersisted(
  {
    searchterm: "",
    site: { owner: { name: "" } },
    afterDate: null,
    beforeDate: null,
    username: { username: "" },
  },
  "viewAuditFilters"
);
