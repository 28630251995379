import * as React from "react";

import { useQuery } from "@apollo/client";

import { GET_AUDITS } from "../../../../graphql/queries/admin/audits";
import TableTemplate from "../../../CustomComponents/TableTemplate/TableTemplate";
import Row from "./Row";
import { filterData } from "./searchFilterFunction";

export default function Table() {
  const { data, loading } = useQuery(GET_AUDITS, {
    fetchPolicy: "network-only",
  });

  const [searchTerm, setSearchTerm] = React.useState("");

  const [filteredData, setFilteredData] = React.useState([]);

  // useEffect for filter update
  React.useEffect(() => {
    setFilteredData(filterData(data ? data.audits : [], searchTerm));
  }, [data, searchTerm]);

  return (
    <TableTemplate
      hasPagination={true}
      data={filteredData}
      title="Audit Types"
      headCells={[
        {
          uid: "name",
          numeric: false,
          disablePadding: false,
          label: "Name",
          sort: true,
        },
        {
          uid: "dateCreated",
          numeric: true,
          disablePadding: false,
          label: "Date Created",
        },
        {
          uid: "Active",
          numeric: true,
          disablePadding: false,
          label: "Active",
        },
        {
          uid: "View",
          numeric: true,
          disablePadding: false,
          label: "",
        },
        {
          uid: "Download CSV",
          numeric: true,
          disablePadding: false,
          label: "",
        },
        {
          uid: "Edit",
          numeric: true,
          disablePadding: false,
          label: "",
        },
      ]}
      initialOrderBy="name"
      getItemsFromOrderBy={(a, b, orderBy) => {
        let aItem, bItem;

        aItem = a[orderBy];
        bItem = b[orderBy];

        return { aItem, bItem };
      }}
      renderFilterDialog={null}
      searchTerm={searchTerm}
      updateSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
      getBadgeCount={() => {}}
      renderRow={(row) => <Row key={row.id} row={row} />}
      loading={loading}
    />
  );
}
