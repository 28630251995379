import React from "react";
import { useNavigate } from "react-router-dom";

// import AccountTreeIcon from "@mui/icons-material/AccountTree";
import HomeIcon from "@mui/icons-material/Home";
import {
  Grid,
  List,
  ListItemText,
  Divider,
  ListItemButton,
} from "@mui/material";
import { useTheme } from "@mui/styles";

import nixnLogo from "../../images/NIXN_Logo.svg";
import AccountItems from "./AccountItems";
import AdminItems from "./AdminItem";
import CreateItems from "./CreateItems";
import ReleaseNotesDialog from "./ReleaseNotesDialog";
import ReportingItems from "./ReportingItems";
import ViewItems from "./ViewItems";
import useStyles from "./styles";

export default function Drawer({
  drawerWidth,
  permissions,
  isSuperuser,
  isStaff,
  user,
}) {
  const classes = useStyles(drawerWidth)();
  const navigate = useNavigate();
  const theme = useTheme();

  const iconSelectedColor = theme.palette.yellow0.main;
  const textSelectedColor = theme.palette.white.main;
  const endBarSelectedColor = theme.palette.yellow0.main;

  const [keyHook, setKey] = React.useState("Home");

  // SET KEY BASED ON URL
  React.useEffect(() => {
    const path = window.location.pathname;
    if (path.includes("create")) {
      setKey("create");
    } else if (
      (path.includes("view") || path.includes("edit")) &&
      !path.includes("admin")
    ) {
      setKey("view");
    } else if (path.includes("user-settings")) {
      setKey("account");
    } else if (path.includes("reporting")) {
      setKey("reporting");
    } else if (path.includes("admin")) {
      setKey("admin");
    }
  }, []);

  // let riskLabItem;
  // if (permissions.includes("VIEW_RISK_LAB")) {
  //   riskLabItem = (
  //     <ListItemButton
  //       key="Risk Lab"
  //       onClick={() => {
  //         setKey("RiskLab");
  //         navigate("/risk-lab");
  //       }}
  //     >
  //       <AccountTreeIcon
  //         className={classes.listIcons}
  //         style={{
  //           color: keyHook === "RiskLab" ? iconSelectedColor : undefined,
  //         }}
  //       />
  //       <ListItemText
  //         primary={"Risk Lab"}
  //         className={classes.listText}
  //         style={{
  //           color: keyHook === "RiskLab" ? textSelectedColor : undefined,
  //         }}
  //       />
  //       <div
  //         className={classes.listEndBar}
  //         style={{
  //           backgroundColor:
  //             keyHook === "RiskLab" ? endBarSelectedColor : undefined,
  //         }}
  //       ></div>
  //     </ListItemButton>
  //   );
  // }

  let reportingItem;
  if (permissions.includes("CUSTOM_REPORTING")) {
    reportingItem = (
      <ReportingItems
        iconSelectedColor={iconSelectedColor}
        textSelectedColor={textSelectedColor}
        endBarSelectedColor={endBarSelectedColor}
        classes={classes}
        permissions={permissions}
        isStaff={isStaff}
        keyHook={keyHook}
      />
    );
  }

  let adminItems;
  if (
    permissions.includes("VIEW_CARM_ADMINISTRATION") ||
    permissions.includes("VIEW_PLANNING_ADMINISTRATION") ||
    permissions.includes("VIEW_PERMISSION_ADMINISTRATION") ||
    permissions.includes("VIEW_COMPANY_ADMINISTRATION") ||
    permissions.includes("SUPERVISION") ||
    permissions.includes("AUDIT_MANAGE") ||
    permissions.includes("CREATE_USER") ||
    permissions.includes("EDIT_USER")
  ) {
    adminItems = (
      <AdminItems
        iconSelectedColor={iconSelectedColor}
        textSelectedColor={textSelectedColor}
        endBarSelectedColor={endBarSelectedColor}
        classes={classes}
        permissions={permissions}
        keyHook={keyHook}
      />
    );
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      style={{ minHeight: "100%", flexWrap: "nowrap" }}
    >
      <Grid item>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-end"
          className={classes.toolbar}
        >
          <Grid
            item
            xs={12}
            style={{
              textAlign: "center",
            }}
          >
            <img
              src={nixnLogo}
              width="150px"
              height="100%"
              style={{
                verticalAlign: "middle",
                cursor: "pointer",
              }}
              onClick={() => {
                setKey("Home");
                navigate("/");
              }}
              alt="NIXN logo"
            />
          </Grid>
        </Grid>

        <Divider />
        <List>
          <ListItemButton
            key={"Home"}
            onClick={() => {
              setKey("Home");
              navigate("/");
            }}
          >
            <HomeIcon
              className={classes.listIcons}
              style={{
                color: keyHook === "Home" ? iconSelectedColor : undefined,
              }}
            />
            <ListItemText
              primary={"Home"}
              className={classes.listText}
              style={{
                color: keyHook === "Home" ? textSelectedColor : undefined,
              }}
            />
            <div
              className={classes.listEndBar}
              style={{
                backgroundColor:
                  keyHook === "Home" ? endBarSelectedColor : undefined,
              }}
            ></div>
          </ListItemButton>
          <CreateItems
            iconSelectedColor={iconSelectedColor}
            textSelectedColor={textSelectedColor}
            endBarSelectedColor={endBarSelectedColor}
            permissions={permissions}
            classes={classes}
            keyHook={keyHook}
          />
          <ViewItems
            iconSelectedColor={iconSelectedColor}
            textSelectedColor={textSelectedColor}
            endBarSelectedColor={endBarSelectedColor}
            permissions={permissions}
            classes={classes}
            keyHook={keyHook}
          />
          {/* {riskLabItem}*/}
          {reportingItem}
          {adminItems}
        </List>

        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.toolbar}
        ></Grid>
      </Grid>
      <Grid item>
        <AccountItems
          iconSelectedColor={iconSelectedColor}
          textSelectedColor={textSelectedColor}
          endBarSelectedColor={endBarSelectedColor}
          isSuperuser={isSuperuser}
          classes={classes}
          keyHook={keyHook}
          user={user}
        />
        <ReleaseNotesDialog />
      </Grid>
    </Grid>
  );
}
