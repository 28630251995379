import * as React from "react";

import TableTemplate from "../../../../../../../CustomComponents/TableTemplate/TableTemplate";
import Row from "./Row";
import { filterData } from "./searchFilterFunction";

export default function Table({ data, company, extraData }) {
  const [searchTerm, setSearchTerm] = React.useState("");

  const [filteredData, setFilteredData] = React.useState([]);

  React.useEffect(() => {
    setFilteredData(filterData(data, searchTerm));
  }, [searchTerm]);

  return (
    <TableTemplate
      hasPagination={true}
      data={filteredData}
      title="Contractors"
      headCells={[
        {
          uid: "name",
          numeric: false,
          disablePadding: false,
          label: "Contractor Name",
          sort: true,
        },
        {
          uid: "contractedSites",
          numeric: false,
          disablePadding: false,
          label: "# of Contracted Sites",
        },
        {
          uid: "contractedProjects",
          numeric: false,
          disablePadding: false,
          label: "# of Contracted Projects",
        },
      ]}
      initialOrderBy="name"
      getItemsFromOrderBy={(a, b, orderBy) => {
        let aItem, bItem;

        if (orderBy === "name") {
          aItem = `${a.contractor.firstName} ${a.contractor.lastName}`;
          bItem = `${b.contractor.firstName} ${b.contractor.lastName}`;
        }

        return { aItem, bItem };
      }}
      hasFilters={false}
      renderFilterDialog={null}
      searchTerm={searchTerm}
      updateSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
      getBadgeCount={() => {}}
      renderRow={(row) => (
        <Row key={row.id} row={row} company={company} extraData={extraData} />
      )}
    />
  );
}
