import React from "react";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
  Grid,
} from "@mui/material";

import { useMutation } from "@apollo/client";

import { MUTATE_INDUSTRY } from "../../../graphql/mutations/admin/industry";
import {
  GET_ACTIVE_INDUSTRIES,
  GET_ALL_INDUSTRIES,
} from "../../../graphql/queries/admin/company";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import { CustomTextField } from "../../CustomStyles/LightTextField";
import { CustomDialog } from "../../CustomStyles/dialog";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";

export default function CreateIndustry({ data }) {
  const [createIndustry] = useMutation(MUTATE_INDUSTRY, {
    onCompleted() {
      onClear();
      setOpen(false);
      onCompletedFunc("Industry has been created");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [
      { query: GET_ACTIVE_INDUSTRIES },
      { query: GET_ALL_INDUSTRIES },
    ],
  });
  const [open, setOpen] = React.useState(false);

  const [name, setName] = React.useState("");
  const [code, setCode] = React.useState("");

  const [selectedClassification, setSelectedClassification] = React.useState({
    name: "",
    id: null,
  });

  const onSave = () => {
    if (name !== "" && selectedClassification.id !== null) {
      createIndustry({
        variables: {
          name,
          classifications: [Number(selectedClassification.id)],
          code,
        },
      });
    }
  };

  const onCancel = () => {
    setOpen(false);
    onClear();
  };

  const onClear = () => {
    setName("");
  };

  return (
    <>
      <Button variant="contained" color="yellow0" onClick={() => setOpen(true)}>
        ADD NEW INDUSTRY
      </Button>

      <CustomDialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={() => setOpen(false)}
      >
        <DialogTitle style={{ ...pageTitleStyles }}>
          ADD NEW INDUSTRY.
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column">
            <Autocomplete
              id="select-merge-mitigator"
              options={data && data.classifications ? data.classifications : []}
              value={selectedClassification}
              onChange={(event, value) => setSelectedClassification(value)}
              getOptionLabel={(option) => option.name}
              disableClearable
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  variant="standard"
                  label={"Classification:"}
                  style={{ margin: "10px 0px", width: "75%" }}
                />
              )}
            />

            <CustomTextField
              label="Industry Name:"
              variant="standard"
              style={{ margin: "10px 0px", width: "75%" }}
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
            <CustomTextField
              label="Industry Code:"
              variant="standard"
              style={{ margin: "10px 0px", width: "75%" }}
              value={code}
              onChange={(event) => setCode(event.target.value)}
              inputProps={{ maxLength: 6 }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="yellow0"
            sx={{ mr: 1 }}
            onClick={onSave}
          >
            SAVE
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={onCancel}
          >
            CANCEL
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
}
