import React from "react";

import { Image, Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";

import { styles } from "./styles";

export default function ReviewPage({ ha }) {
  return (
    <Page size="A4" orientation="landscape">
      <View
        style={{
          borderTop: "3px solid black",
          margin: "30px 20px 20px 20px",
        }}
      >
        <Text style={{ marginTop: "20px" }}>
          Hazard Analysis Approval/Review
        </Text>
        <View
          style={{
            marginTop: "30px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View style={{ width: "50%" }}>
            <Text style={styles.labelText}>Supervisor:</Text>
            <Text style={styles.mainText}>
              {ha.submitter.company?.name ?? " "}
            </Text>
          </View>
          <View style={{ width: "50%" }}>
            <Text style={styles.labelText}>Who Completed HA:</Text>
            <Text style={styles.mainText}>
              {ha.submitter.firstName !== "" && ha.submitter.lastName !== ""
                ? `${ha.submitter.firstName} ${ha.submitter.lastName} (${ha.submitter.username})`
                : ha.submitter.username}
            </Text>
          </View>
          <View style={{ width: "50%" }}>
            <Text style={styles.labelText}>Date:</Text>
            <Text style={styles.mainText}>
              {moment(new Date(ha.timeCreated)).format("MM/DD/YYYY")}
            </Text>
          </View>
          <View style={{ width: "50%" }}>
            <Text style={styles.labelText}>Time:</Text>
            <Text style={styles.mainText}>
              {moment(new Date(ha.timeCreated)).format("hh:mm A")}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          width: "100%",
          padding: "20px",
          borderRight: "1px solid black",
        }}
      >
        <Text style={styles.labelText}>Crewmembers:</Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {ha.haCrewMembers.length > 0 ? (
            ha.haCrewMembers.map((cm) => {
              return (
                <View
                  wrap={false}
                  key={cm.crewMember.id}
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  <Text style={{ fontSize: "12px", marginRight: "20px" }}>
                    {cm.crewMember.firstName} {cm.crewMember.lastName}
                  </Text>
                  <Text style={{ fontSize: "12px", marginRight: "20px" }}>
                    {moment(cm.crewMember.lastUpdated).format("MM/DD/YYYY")}
                  </Text>
                  {cm.crewMember.signatureBase64 !== "" && (
                    <Image
                      src={cm.crewMember.signatureBase64}
                      style={{ width: "200px" }}
                    />
                  )}
                </View>
              );
            })
          ) : (
            <Text style={{ fontSize: "12px", paddingLeft: "20px" }}>
              No crewmembers added.
            </Text>
          )}
        </View>
      </View>
    </Page>
  );
}
