import React from "react";
import { useNavigate } from "react-router-dom";

import { AspectRatio } from "@mui/icons-material";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import { CustomDialog } from "../../../../CustomStyles/dialog";
import CreateCompany from "./CreateCompany";
import OrgChart from "./OrgChart";
import useStyles from "./styles";

export default function OrgChartRoot({ company }) {
  const classes = useStyles()();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      className={classes.root}
      style={{ height: "100%", paddingBottom: "40px" }}
    >
      <CreateCompany />

      <Grid item xs={12} style={{ width: "100%" }}>
        <Typography
          className={classes.cardTitle}
          sx={{
            fontFamily: "barlowextrabold",
          }}
        >
          Associated Companies
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ overflow: "auto", height: "80%" }}>
        <OrgChart company={company} />
      </Grid>
      <Grid item xs={12}>
        <IconButton
          onClick={() => setOpen(true)}
          style={{ backgroundColor: "lightgrey" }}
        >
          <AspectRatio />
        </IconButton>
      </Grid>
      <CustomDialog fullScreen open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          {open && <OrgChart company={company} viewAll />}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            color="secondary"
          >
            CLOSE
          </Button>
        </DialogActions>
      </CustomDialog>
      <div className={classes.overlay}>
        <Typography
          className={classes.text}
          onClick={() => navigate("/admin/company/view")}
        >
          View All
        </Typography>
      </div>
    </Grid>
  );
}
