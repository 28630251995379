import React from "react";

import {
  Typography,
  FormControl,
  Button,
  Grid,
  Avatar,
  Autocomplete,
} from "@mui/material";

import { useMutation, useQuery } from "@apollo/client";
import { DropzoneDialog } from "mui-file-dropzone";

import { authMutations } from "../../../../../../graphql/mutations";
import {
  CHANGE_USER_COMPANY,
  CREATE_NEW_USER,
} from "../../../../../../graphql/mutations/userSettings";
import { GET_USERS_ADMIN } from "../../../../../../graphql/queries/admin/user";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../../../CustomComponents/OnErrorFunction";
import PasswordField from "../../../../../CustomComponents/PasswordField";
import TabPanel from "../../../../../CustomComponents/TabPanels";
import {
  StyledTabWhite,
  StyledTabs,
} from "../../../../../CustomComponents/Tabs";
import { CustomTextField } from "../../../../../CustomStyles/LightTextField";
import { defaultAvatar } from "../../../../../CustomStyles/avatar";

export default function CreateNewUser({ companyId }) {
  const { updateSnackbar } = authMutations;
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] = React.useState("");
  const [photo, setPhoto] = React.useState("");
  const [openUpload, setOpenUpload] = React.useState(false);
  const [choosenUser, setChoosenUser] = React.useState({
    id: null,
    firstName: "",
    lastName: "",
    username: "",
    company: { id: null, name: null },
  });

  const { data: users } = useQuery(GET_USERS_ADMIN);

  const [createNewUser] = useMutation(CREATE_NEW_USER, {
    onCompleted() {
      setUsername("");
      setEmail("");
      setPassword("");
      setPasswordConfirm("");
      onCompletedFunc("User has been created");
    },
    onError(error) {
      onErrorFunc(error);
    },
  });

  const [changeUserCompanyMutation] = useMutation(CHANGE_USER_COMPANY, {
    onCompleted() {
      onCompletedFunc("User has been added to company");
    },
    onError(error) {
      onErrorFunc(error);
    },
  });

  const changeUserCompany = () => {
    if (choosenUser.id && companyId) {
      changeUserCompanyMutation({
        variables: { userId: choosenUser.id, company: companyId },
      });
    }
  };

  const createUser = () => {
    // TODO: make sure password is good format
    if (username === "") {
      updateSnackbar({
        open: true,
        message: "Please enter a username.",
        severity: "error",
      });
    } else if (email === "") {
      updateSnackbar({
        open: true,
        message: "Please enter an email.",
        severity: "error",
      });
    } else if (password === "") {
      updateSnackbar({
        open: true,
        message: "Please enter a password.",
        severity: "error",
      });
    } else if (passwordConfirm === "") {
      updateSnackbar({
        open: true,
        message: "Please confirm the new password.",
        severity: "error",
      });
    } else if (password !== passwordConfirm) {
      updateSnackbar({
        open: true,
        message: "Please make sure to enter the password correctly both times.",
        severity: "error",
      });
    } else {
      createNewUser({
        variables: {
          username: username.toLowerCase(),
          email,
          password,
          companyId: Number(companyId),
          image: photo,
        },
      });
    }
  };

  const handleSave = (photo) => {
    setOpenUpload(false);
    setPhoto(photo[0]);
  };

  const isImage = (file) => {
    if (file.type && file.type.split("/")[0] === "image") {
      return true;
    }
  };

  // Tabs Function: sets index
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [value, setValue] = React.useState(0);

  return (
    <Grid>
      <StyledTabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        style={{ color: "white", paddingBottom: "20px" }}
      >
        <StyledTabWhite label="NEW ACCOUNT" />
        <StyledTabWhite label="EXISTING ACCOUNT" />
      </StyledTabs>

      {/* Individual Create */}

      <TabPanel value={value} index={0}>
        <FormControl fullWidth>
          <Grid
            container
            direction="column"
            alignItems="center"
            style={{ margin: "20px 0px" }}
          >
            <Avatar
              alt="New Photo"
              src={
                isImage(photo) && photo.type ? URL.createObjectURL(photo) : ""
              }
              sx={{
                ...defaultAvatar,
                width: "75px",
                margin: "auto",
                height: "75px",
                fontSize: "2.5rem",
                marginBottom: "20px",
              }}
            />
            <Button
              variant="contained"
              color="yellow0"
              onClick={() => setOpenUpload(true)}
            >
              UPLOAD PHOTO
            </Button>
            <DropzoneDialog
              open={openUpload}
              onSave={handleSave}
              acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
              showPreviews={true}
              filesLimit={1}
              onClose={() => setOpenUpload(false)}
              maxFileSize={10000000} // 10MB
            />
          </Grid>
          <CustomTextField
            variant="standard"
            label="Username:"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
          <Typography>
            Required. 150 characters or fewer. Letters, digits and @/./+/-/_
            only.
          </Typography>
          <CustomTextField
            variant="standard"
            label="Email Address:"
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <PasswordField
            label="Password:"
            password={password}
            setPassword={setPassword}
            white
          />
          <Typography>
            Password can&apos;t be too similar to other personal information.
          </Typography>
          <Typography>Password must contain at least 8 characters.</Typography>
          <Typography>
            Password can&apos;t be a commonly used password.
          </Typography>
          <Typography>Password can&apos;t be entirely numeric.</Typography>
          <PasswordField
            label="Password Confirmation:"
            password={passwordConfirm}
            setPassword={setPasswordConfirm}
            white
          />
          <Typography>
            Enter the same password as before, for verification.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            sx={{ my: 1 }}
            onClick={createUser}
          >
            CREATE
          </Button>
        </FormControl>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Autocomplete
          id="select-user"
          options={
            users
              ? [...users.users].sort((a, b) =>
                  a.username.toLowerCase() > b.username.toLowerCase() ? 1 : -1
                )
              : []
          }
          value={choosenUser}
          getOptionLabel={(option) =>
            `${option.username} ${
              option.firstName !== ""
                ? `[${option.firstName} ${option.lastName}]`
                : ""
            }`
          }
          onChange={(event, newVal) => setChoosenUser(newVal)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disableClearable
          renderInput={(params) => (
            <CustomTextField
              {...params}
              variant="standard"
              label="Select User:"
              value={params}
              id={params.id}
            />
          )}
        />
        {choosenUser.id !== null ? (
          <div>
            <Typography sx={{ paddingTop: "10px" }}>
              Warning! {choosenUser.username} will be removed from{" "}
              {choosenUser.company.name} and add to this company. Press save to
              continue.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              sx={{ my: 1 }}
              onClick={changeUserCompany}
            >
              SAVE
            </Button>
          </div>
        ) : (
          <div style={{ textAlign: "end" }}>
            <Typography sx={{ paddingTop: "10px" }}>
              Please be patient as users load. No user selected.
            </Typography>
          </div>
        )}
      </TabPanel>
    </Grid>
  );
}
