import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    width: "95%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    border: "3px solid black",
    justifyContent: "center",
    margin: "0px auto",
  },
  formSection: { width: "25%", border: "1px solid black", padding: "10px" },
  armSectionHeader: { width: "33.3%", borderBottom: "1px solid black" },
  armSection: {
    width: "100%",
    borderBottom: "1px solid black",
    borderLeft: "1px solid black",
  },
  column: { display: "flex", flexDirection: "column" },
  row: { width: "100%", display: "flex", flexDirection: "row" },
  text: { padding: "20px", fontSize: "10px" },
  sectionLabel: { paddingBottom: "10px", fontSize: "12px" },
  labelText: { padding: "10px", fontSize: "12px", color: "grey" },
  mainText: {
    padding: "10px",
    fontSize: "14px",
    width: "90%",
    borderBottom: "1px solid grey",
  },
});
