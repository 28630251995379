import React from "react";
import { useNavigate } from "react-router-dom";

import { InfoOutlined } from "@mui/icons-material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import moment from "moment";

import { permissionsVar } from "../../../graphql/localVariables/user";
import { CUSTOM_REPORT } from "../../../graphql/mutations/reporting";
import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import {
  onCompletedFunc,
  onErrorFunc,
  showErrorMessage,
} from "../../CustomComponents/OnErrorFunction";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Layout from "../../Layout";
import Company from "./Filters/Company";
import Contractors from "./Filters/Contractors";
import Observer from "./Filters/Observer";
import Owner from "./Filters/Owner";
import Projects from "./Filters/Projects";
import Site from "./Filters/Site";

export default function CustomReporting() {
  const permissions = useReactiveVar(permissionsVar);
  const navigate = useNavigate();

  const { data: currentUser } = useQuery(GET_CURRENT_USER);

  const [submitCustomReport] = useMutation(CUSTOM_REPORT, {
    onCompleted() {
      onCompletedFunc(
        "Your report has been submitted successfully. You will receive it in your email within 24 hours."
      );
      clear();
    },
    onError(error) {
      onErrorFunc(error);
    },
  });

  React.useEffect(() => {
    if (
      permissions.length === 0 ||
      (permissions.length > 0 && !permissions.includes("CUSTOM_REPORTING"))
    ) {
      navigate("/");
    }
  }, []);

  const [fullDatabaseReport, setFullDatabaseReport] = React.useState(false);
  const [weeklyReport, setWeeklyReport] = React.useState(false);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [company, setCompany] = React.useState(null);
  const [user, setUser] = React.useState([]);
  const [owner, setOwner] = React.useState([]);
  const [allSites, setAllSites] = React.useState(false);
  const [site, setSite] = React.useState([]);
  const [project, setProject] = React.useState([]);
  const [contractors, setContractors] = React.useState([]);
  const [allContractors, setAllContractors] = React.useState(false);
  const [allProjects, setAllProjects] = React.useState(false);
  const [summary, setSummary] = React.useState(false);
  const [summaryTypes, setSummaryTypes] = React.useState([]);
  const [siteUserReports, setSiteUserReports] = React.useState(false);
  const [companyUserReports, setCompanyUserReports] = React.useState(false);
  const [customReports, setCustomReports] = React.useState(false);
  const [crewMemberReport, setCrewMemberReport] = React.useState(false);

  const [disableSummary, setDisableSummary] = React.useState(false);

  React.useEffect(() => {
    if (companyUserReports || siteUserReports || user.length > 0) {
      setCustomReports(true);
      setSummary(false);
      setDisableSummary(true);
    } else {
      setDisableSummary(false);
    }
  }, [companyUserReports, siteUserReports, user]);

  const submit = () => {
    // Error checking
    const noDate = !weeklyReport && (!startDate || !endDate);
    const noSummaryTypes = summary && summaryTypes.length === 0;
    // only need summary or customReports chosen if NOT a crewmember report
    const noTypes = !summary && !customReports && !crewMemberReport;
    const noOwner = companyUserReports && owner.length === 0;
    const noSite = siteUserReports && site.length === 0 && !allSites;
    const noSiteOwners = (site.length > 0 || allSites) && owner.length === 0;
    const noProjectSites =
      (project.length > 0 || allProjects) && site.length === 0 && !allSites;
    // need at least owner or contractor if not Full Database Report or User Report
    const noOSP =
      !fullDatabaseReport &&
      owner.length === 0 &&
      contractors.length === 0 &&
      !allContractors &&
      user.length === 0;

    if (
      noDate ||
      noSite ||
      noOwner ||
      noSiteOwners ||
      noProjectSites ||
      noSummaryTypes ||
      noTypes ||
      noOSP
    ) {
      let message = "";
      if (noDate) message = message + " Select a date range for your report.";
      if (noSite) message = message + " Select which site(s).";
      if (noOwner) message = message + " Select which owner(s).";
      if (noSiteOwners) message = message + " Select the owners for the sites.";
      if (noProjectSites)
        message = message + " Select the sites for the projects.";
      if (noOSP) message = message + " Select owner(s) or contractor(s).";
      if (noSummaryTypes)
        message =
          message + " Select which summary report types should be included.";
      if (noTypes)
        message =
          message + " Select which types of reports should be generated.";
      showErrorMessage(message);
    } else {
      const input = {
        fullDatabaseReport,
        weeklyReport,
        startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
        endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
      };

      if (contractors.length > 0 || allContractors) {
        input.contractor = allContractors
          ? [0]
          : contractors.map((c) => Number(c.id));
      }

      if (crewMemberReport) {
        input.crewMemberReport = crewMemberReport;
      }

      if (!fullDatabaseReport) {
        input.owner = owner.map((o) => Number(o.owner.id));
        input.site = allSites ? [0] : site.map((s) => Number(s.site.id));
        input.project = allProjects
          ? [0]
          : project.map((p) => Number(p.project.id));

        input.observerId = user.map((u) => Number(u.id));
        input.userReport =
          user.length > 0 || siteUserReports || companyUserReports;
        input.siteUserReport = siteUserReports;
        input.companyUserReport = companyUserReports;
      }

      if (summary) {
        input.summaryReports = true;
        input.summaryTypes = summaryTypes.map((t) =>
          t.toLowerCase().replace(" ", "_")
        );
      } else input.summaryReports = false;

      input.customReports = customReports;

      submitCustomReport({
        variables: {
          reportType: input,
          emailTo: currentUser?.currentUser.email,
        },
      });
    }
  };

  const clear = () => {
    setFullDatabaseReport(false);
    setWeeklyReport(false);
    setStartDate(null);
    setEndDate(null);
    setCompany(null);
    setUser([]);
    setOwner([]);
    setSite([]);
    setAllSites(false);
    setProject([]);
    setAllProjects(false);
    setSummary(false);
    setSummaryTypes([]);
    setCompanyUserReports(false);
    setSiteUserReports(false);
    setCustomReports(false);
    setContractors([]);
    setAllContractors(false);
    setCrewMemberReport(false);
    setDisableSummary(false);
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const clearOSP = () => {
    setOwner([]);
    setSite([]);
    setAllSites(false);
    setProject([]);
    setAllProjects(false);
    setContractors([]);
    setAllContractors(false);
  };

  return (
    <Layout>
      <Grid
        style={{
          height: "100%",
        }}
      >
        <Typography sx={pageTitleStyles}>CUSTOM REPORTING.</Typography>
        <Paper style={{ padding: "20px" }}>
          {fullDatabaseReport && (
            <Alert severity="warning" sx={{ width: "100%" }}>
              Full Database Reports are time and resource intensive. Results may
              take 5-12 hours to arrive. Only run in extreme and necessary
              situations.
            </Alert>
          )}
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              container
              alignItems="center"
              justifyContent="center"
            >
              <Checkbox
                checked={fullDatabaseReport}
                onChange={(e) => {
                  setFullDatabaseReport(e.target.checked);
                  setUser([]);
                  setCompanyUserReports(false);
                  setSiteUserReports(false);
                  clearOSP();
                }}
                disabled={crewMemberReport}
              />
              <Typography>Full Database Report</Typography>
            </Grid>
            <Grid
              item
              xs={6}
              container
              alignItems="center"
              justifyContent="center"
            >
              <Checkbox
                checked={crewMemberReport}
                onChange={(e) => {
                  setCrewMemberReport(e.target.checked);
                  setUser([]);
                  setSiteUserReports(false);
                  setCompanyUserReports(false);
                }}
                disabled={fullDatabaseReport}
              />
              <Typography>CrewMember Report</Typography>
              <Tooltip title="Pull reports for all crewmembers that are on the passed site.">
                <InfoOutlined sx={{ ml: 1 }} />
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={2} container alignItems="center">
              <Checkbox
                checked={weeklyReport}
                onChange={(e) => {
                  setWeeklyReport(e.target.checked);
                  setStartDate(null);
                  setEndDate(null);
                }}
              />
              <Typography>Weekly Report</Typography>
              <Tooltip title="Pull reports for the past 7 days.">
                <InfoOutlined sx={{ ml: 1 }} />
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={5}>
              <MobileDatePicker
                label="Start Date:"
                inputFormat="MM/DD/YYYY"
                value={startDate}
                onChange={(date) => setStartDate(date)}
                disabled={weeklyReport}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{
                      width: "100%",
                    }}
                    variant="standard"
                  />
                )}
                maxDate={endDate}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <MobileDatePicker
                label="End Date:"
                inputFormat="MM/DD/YYYY"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                disabled={weeklyReport}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{
                      width: "100%",
                    }}
                    variant="standard"
                  />
                )}
                minDate={startDate}
              />
            </Grid>
            {!fullDatabaseReport && (
              <>
                {!crewMemberReport && (
                  <>
                    <Grid item xs={12} md={6}>
                      <Company company={company} setCompany={setCompany} />
                    </Grid>
                    <Grid item xs={12} md={6} container>
                      <Grid item xs={12}>
                        <Observer
                          user={user}
                          setUser={setUser}
                          company={company}
                          disabled={siteUserReports || companyUserReports}
                          clearOSP={clearOSP}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} container alignItems="center">
                        <Checkbox
                          checked={siteUserReports}
                          onChange={(e) => {
                            setSiteUserReports(e.target.checked);
                            setUser([]);
                            setProject([]);
                            setAllProjects(false);
                            setContractors([]);
                            setAllContractors(false);
                          }}
                          disabled={companyUserReports}
                        />
                        <Typography>Site User Reports</Typography>
                        <Tooltip title="Pull reports for all users that are associated to the passed site.">
                          <InfoOutlined sx={{ ml: 1 }} />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} md={6} container alignItems="center">
                        <Checkbox
                          checked={companyUserReports}
                          onChange={(e) => {
                            setCompanyUserReports(e.target.checked);
                            setUser([]);
                            clearOSP();
                          }}
                          disabled={siteUserReports}
                        />
                        <Typography>Company User Reports</Typography>
                        <Tooltip title="Pull reports for all users that are associated to the passed owner.">
                          <InfoOutlined sx={{ ml: 1 }} />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </>
                )}
                {user.length === 0 && (
                  <>
                    <Grid item xs={12}>
                      <Owner
                        owner={owner}
                        setOwner={setOwner}
                        setSite={setSite}
                      />
                    </Grid>
                    {!companyUserReports && (
                      <Grid item xs={12} md={siteUserReports ? 12 : 6}>
                        <Site
                          owner={owner}
                          site={site}
                          setSite={setSite}
                          setProject={setProject}
                          allSites={allSites}
                          setAllSites={setAllSites}
                        />
                      </Grid>
                    )}
                    {!companyUserReports && !siteUserReports && (
                      <Grid item xs={12} md={6}>
                        <Projects
                          site={site}
                          project={project}
                          setProject={setProject}
                          owner={owner}
                          allProjects={allProjects}
                          setAllProjects={setAllProjects}
                          allSites={allSites}
                        />
                      </Grid>
                    )}
                    {!companyUserReports && !siteUserReports && (
                      <Grid item xs={12}>
                        <Contractors
                          contractors={contractors}
                          setContractors={setContractors}
                          allContractors={allContractors}
                          setAllContractors={setAllContractors}
                          owner={owner}
                          site={site}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}

            <Grid item xs={12} md={4} container alignItems="center">
              <Checkbox
                checked={summary}
                onChange={(e) => setSummary(e.target.checked)}
                disabled={disableSummary}
              />
              <Typography>Generate Summary Reports</Typography>
              <Grid item xs={12}>
                {summary && (
                  <Autocomplete
                    id="select-summary-types"
                    options={[
                      "Obs Corrections",
                      "Obs Summary",
                      "HA Summary",
                      "Unplanned Actions",
                    ]}
                    value={summaryTypes}
                    multiple
                    getOptionLabel={(option) => option}
                    onChange={(e, newValue) => setSummaryTypes(newValue)}
                    disableCloseOnSelect
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={`${option}`}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Summary Types:"
                        value={params}
                        id={params.id}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} container alignItems="center">
              <Checkbox
                checked={customReports}
                onChange={(e) => setCustomReports(e.target.checked)}
              />
              <Typography>Generate Custom Reports</Typography>
            </Grid>
          </Grid>
        </Paper>
        <Grid
          container
          justifyContent="space-between"
          alignContent="center"
          style={{
            position: "fixed",
            width: "100%",
            padding: "0px 50px",
          }}
          sx={{ bottom: { xs: 75, sm: 20, md: 20 }, left: 0 }}
        >
          <Grid
            item
            xs={12}
            container
            alignContent="center"
            justifyContent="space-between"
          >
            <Button variant="contained" color="primary" onClick={clear}>
              CLEAR
            </Button>
            <Button variant="contained" color="yellow0" onClick={submit}>
              SUBMIT
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}
