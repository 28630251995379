import makeVarPersisted from "../makeVarPersisted";

export const initialState = {
  owner: null,
  site: null,
  project: null,
  ospId: null,
  contractor: null,
  date: new Date(),
  time: new Date(),
  ha: null,
  actions: [],
  location: null,
  risks: [],
  mitigators: [],
  unselectedMitigators: [],
  outcomeUnplanned: false,
  outcomeExpected: true,
  outcomeInjury: false,
  outcomes: [],
  dollarAmount: null,
  outcomeNearMiss: false,
  files: [],
  foreman: [],
  crewMembers: [],
  correctionComments: "",
  disabled: false,
};

export const createObservationVar = makeVarPersisted(initialState, "createObs");
export const editObservationVar = makeVarPersisted(initialState, "editObs");

export const viewObservationFiltersVar = makeVarPersisted(
  {
    searchterm: "",
    site: { owner: { name: "" } },
    contractor: { contractor: { name: "" } },
    afterDate: null,
    beforeDate: null,
    outcomes: [],
    status: "",
    usernames: [],
  },
  "viewObsFilters"
);
