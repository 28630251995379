import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Grid, Typography, Autocomplete } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";

import axios from "axios";
import moment from "moment";

import { authMutations } from "../../graphql/mutations";
import logo from "../../images/nixn-logo-square.png";
import {
  CustomTextField,
  CustomDateField,
} from "../CustomStyles/LightTextField";
import { phonePageTitleStyles } from "../CustomStyles/pageTitle";

export default function CrewMemberSignUp() {
  const navigate = useNavigate();
  const [company, setCompany] = React.useState({ name: "", id: null });
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [hireDate, setHireDate] = React.useState(null);
  const [expirationDate, setExpirationDate] = React.useState(null);
  const [contactName, setContactName] = React.useState("");
  const [contactPhone, setContactPhone] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState({ name: "" });
  const [craft, setCraft] = React.useState({ name: "" });
  const [years, setYears] = React.useState({ name: "" });
  const [other, setOther] = React.useState("");

  const [complete, setComplete] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [info, setInfo] = React.useState({
    jobTitles: [],
    craftTypes: [],
    yearsOfExps: [],
    companies: [],
  });

  const { ospId, site, owner, siteId } = useParams();

  // Mutations
  const { updateSnackbar } = authMutations;

  const query = `
  mutation CREATE(
    $phoneNumber: String!
    $contactNumber: String
    $firstName: String
    $lastName: String
    $company: ID!
    $OSP: ID!
    $email: String
    $jobTitle: ID
    $craft: ID
    $years: ID
    $signature: String
    $hireDate: Date
    $expirationDate: Date
    $contactName: String
    $other: String
  ) {
    crewMemberSingleMutation(
      input: {
        phoneNumber: $phoneNumber
        contactNumber: $contactNumber
        firstName: $firstName
        company: $company
        ownerSiteProject: $OSP
        lastName: $lastName
        email: $email
        jobTitle: $jobTitle
        craftType: $craft
        yrsOfExp: $years
        signatureBase64: $signature
        hireDate: $hireDate
        drugTestExpiry: $expirationDate
        contactName: $contactName
        other: $other
      }
    ) {
      crewMember {
        phoneNumber
      }
      message {
        success
        errors
        info
      }
    }
  }
`;

  const submitSignup = () => {
    if (
      phone !== "" &&
      company.id !== null &&
      firstName !== "" &&
      lastName !== "" &&
      jobTitle.name !== "" &&
      craft.name !== "" &&
      years.name !== ""
    ) {
      setLoading(true);
      const input = {
        isActive: true,
        company: Number(company.id),
        phoneNumber: phone,
        OSP: Number(ospId),
        firstName,
        lastName,
        jobTitle: Number(jobTitle.id),
        craft: Number(craft.id),
        years: Number(years.id),
      };
      if (email !== "") {
        input.email = email;
      }
      if (hireDate) {
        input.hireDate = moment(hireDate).format("YYYY-MM-DD");
      }
      if (expirationDate) {
        input.expirationDate = moment(expirationDate).format("YYYY-MM-DD");
      }
      if (other !== "") {
        input.other = other;
      }
      if (contactName !== "") {
        input.contactName = contactName;
      }
      if (contactPhone !== "") {
        input.contactNumber = contactPhone;
      }
      axios
        .post(
          "/gql/",
          {
            query: query,
            variables: input,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.data.crewMemberSingleMutation) {
            setComplete(true);
          } else if (res.data.errors) {
            const errors = res.data.errors.map((e) => e.message).join(", ");
            updateSnackbar({
              open: true,
              message: errors,
              severity: "error",
            });
          }
          setLoading(false);
        })
        .catch(() => {
          updateSnackbar({
            open: true,
            message: "Something went wrong. Please reload the page.",
            severity: "error",
          });
          setLoading(false);
        });
    } else {
      updateSnackbar({
        open: true,
        message:
          "Must enter phone number, company, first name, last name, craft type, job title, and years of experience.",
        severity: "error",
      });
    }
  };

  const signupInfo = () => {
    const query = {
      query: `query {yearsOfExps {id, name} craftTypes {id, name} companies(crewmemberSignupSiteId: ${siteId}) {id, name} jobTitles {id, name}}`,
    };
    axios
      .post("/gql/", query)
      .then((response) => {
        setInfo(response.data.data);
      })
      .catch(() => {
        updateSnackbar({
          open: true,
          message: "Something went wrong. Please reload the page",
          severity: "error",
        });
      });
  };

  React.useEffect(() => {
    signupInfo();
  }, []);

  return (
    <>
      {complete ? (
        <Grid style={{ padding: 20 }}>
          <Typography style={{ color: "white" }}>Thank you!</Typography>
          <Typography style={{ color: "white" }}>
            Your submission has been recieved. You can now close the page.
          </Typography>
        </Grid>
      ) : (
        <Grid container justifyContent="center">
          <Grid
            item
            xs={12}
            container
            style={{ backgroundColor: "#151711", height: 60, width: "100%" }}
            justifyContent="center"
          >
            <img src={logo} alt="NIXN logo" style={{ height: "100%" }} />
          </Grid>
          <Grid item style={{ marginTop: "30px" }} xs={10} md={6}>
            <Typography style={phonePageTitleStyles}>
              SITE SIGN UP:{" "}
              <span style={{ color: "rgb(255, 183, 0)" }}>
                {site} [{owner}]
              </span>
            </Typography>

            <Grid container direction="column" alignItems="center">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    id="select-company"
                    options={info.companies}
                    value={company}
                    getOptionLabel={(option) => {
                      return option.name;
                    }}
                    onChange={(event, newVal) => setCompany(newVal)}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    disableClearable
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        variant="standard"
                        label="Company:"
                        value={params}
                        id={params.id}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    variant="standard"
                    label="First Name:"
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    variant="standard"
                    label="Last Name:"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomDateField
                    defaultCountry={"us"}
                    value={phone}
                    onChange={(val) => setPhone(val)}
                    label="Phone Number:"
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    variant="standard"
                    label="Email:"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    type="email"
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    id="select-craft-type"
                    options={info ? info.craftTypes : []}
                    value={craft}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newVal) => setCraft(newVal)}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    disableClearable
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        variant="standard"
                        label="Craft Type:"
                        value={params}
                        id={params.id}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    id="select-job-title"
                    options={info ? info.jobTitles : []}
                    value={jobTitle}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newVal) => setJobTitle(newVal)}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    disableClearable
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        variant="standard"
                        label="Job Title:"
                        value={params}
                        id={params.id}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    id="select-years"
                    options={info ? info.yearsOfExps : []}
                    value={years}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newVal) => setYears(newVal)}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    disableClearable
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        variant="standard"
                        label="Years of Exp:"
                        value={params}
                        id={params.id}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MobileDatePicker
                    label="Hire Date:"
                    inputFormat="MM/DD/YYYY"
                    value={hireDate}
                    onChange={(date) => setHireDate(date)}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        style={{
                          width: "100%",
                        }}
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MobileDatePicker
                    label="Expiration Date:"
                    inputFormat="MM/DD/YYYY"
                    value={expirationDate}
                    onChange={(date) => setExpirationDate(date)}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        style={{
                          width: "100%",
                        }}
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <CustomTextField
                      variant="standard"
                      label="Emergency Contact Name:"
                      value={contactName}
                      onChange={(event) => setContactName(event.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomDateField
                      defaultCountry={"us"}
                      value={contactPhone}
                      onChange={(val) => setContactPhone(val)}
                      label="Emergency Contact Phone Number:"
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    variant="standard"
                    label="Other:"
                    value={other}
                    onChange={(event) => setOther(event.target.value)}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ margin: "20px" }}>
                <LoadingButton
                  variant="contained"
                  color="yellow0"
                  sx={{ mr: 1 }}
                  onClick={submitSignup}
                  loading={loading}
                >
                  SUBMIT
                </LoadingButton>
                <Button
                  onClick={() => navigate("/login")}
                  variant="contained"
                  color="primary"
                >
                  CANCEL
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
