import * as React from "react";

import PropTypes from "prop-types";

import TableTemplate from "../../../../../../CustomComponents/TableTemplate/TableTemplate";
import Row from "./Row";
import TableFilterDialog from "./TableFilterDialog";
import { filterData } from "./searchFilterFunction";

export default function Table({ data }) {
  const onSiteChange = (event, newValue) => {
    setSiteFilter(newValue || { owner: { name: "" } });
  };

  const onContractorChange = (event, newValue) => {
    setContractorFilter(newValue || { contractor: { name: "" } });
  };

  const onAfterDateChange = (event) => {
    setAfterDate(event);
  };

  const onBeforeDateChange = (event) => {
    setBeforeDate(event);
  };

  const onOutcomeChange = (event, newValue) => {
    setOutcomeFilter(newValue || []);
  };

  const onStatusChange = (event, newValue) => {
    setPlannedStatusFilter(newValue || "");
  };

  const clearFilters = () => {
    setSiteFilter({ owner: { name: "" } });
    setContractorFilter({ contractor: { name: "" } });
    setAfterDate(null);
    setBeforeDate(null);
    setOutcomeFilter([]);
    setPlannedStatusFilter("");
  };

  const [searchTerm, setSearchTerm] = React.useState("");
  const [siteFilter, setSiteFilter] = React.useState({ owner: { name: "" } });
  const [contractorFilter, setContractorFilter] = React.useState({
    contractor: { name: "" },
  });
  const [afterDate, setAfterDate] = React.useState(null);
  const [beforeDate, setBeforeDate] = React.useState(null);
  const [outcomeFilter, setOutcomeFilter] = React.useState([]);
  const [plannedStatusFilter, setPlannedStatusFilter] = React.useState("");

  const [filteredData, setFilteredData] = React.useState(data);

  // useEffect for filter update
  React.useEffect(() => {
    setFilteredData(
      filterData(
        data,
        searchTerm,
        siteFilter,
        afterDate,
        beforeDate,
        outcomeFilter,
        plannedStatusFilter,
        contractorFilter
      )
    );
  }, [
    searchTerm,
    siteFilter,
    afterDate,
    beforeDate,
    outcomeFilter,
    plannedStatusFilter,
    contractorFilter,
  ]);

  const headCells = [
    {
      uid: "name",
      numeric: false,
      disablePadding: false,
      label: "OBS ID",
      sort: true,
    },
    {
      uid: "date",
      numeric: true,
      disablePadding: false,
      label: "Date",
      sort: true,
    },
    {
      uid: "outcome",
      numeric: true,
      disablePadding: false,
      label: "Outcome",
      sort: false,
    },
    {
      uid: "Submitter Username",
      numeric: true,
      disablePadding: false,
      label: "Submitter Username",
      sort: true,
    },
    {
      uid: "Contractor Observed",
      numeric: true,
      disablePadding: false,
      label: "Contractor Observed",
      sort: true,
    },
    {
      uid: "Planned Status",
      numeric: true,
      disablePadding: false,
      label: "Planned Status",
      sort: false,
    },
    {
      uid: "Location",
      numeric: true,
      disablePadding: false,
      label: "Location",
      sort: false,
    },
    {
      uid: "HA Name",
      numeric: true,
      disablePadding: false,
      label: "HA Name",
      sort: true,
    },
  ];

  return (
    <TableTemplate
      hasPagination={true}
      data={filteredData}
      title="Observations"
      headCells={headCells}
      initialOrderBy="name"
      initialSort="desc"
      getItemsFromOrderBy={(a, b, orderBy) => {
        let aItem, bItem;

        switch (orderBy) {
          case "Submitter Username":
            aItem = a.submitter.username;
            bItem = b.submitter.username;
            break;
          case "HA Name":
            aItem = a.ha ? a.ha.name : "";
            bItem = b.ha ? b.ha.name : "";
            break;
          case "Contractor Observed":
            aItem = a.contractor ? a.contractor.name : "";
            bItem = b.contractor ? b.contractor.name : "";
            break;
          default:
            aItem = a[orderBy];
            bItem = b[orderBy];
        }

        return { aItem, bItem };
      }}
      renderFilterDialog={(open, setOpen) => (
        <TableFilterDialog
          open={open}
          setOpen={setOpen}
          site={siteFilter}
          onSiteChange={onSiteChange}
          contractor={contractorFilter}
          onContractorChange={onContractorChange}
          onAfterDateChange={onAfterDateChange}
          afterDate={afterDate}
          onBeforeDateChange={onBeforeDateChange}
          beforeDate={beforeDate}
          onOutcomeChange={onOutcomeChange}
          outcomes={outcomeFilter}
          onStatusChange={onStatusChange}
          status={plannedStatusFilter}
          clearFilters={clearFilters}
        />
      )}
      searchTerm={searchTerm}
      updateSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
      getBadgeCount={() => {}}
      renderRow={(row) => <Row key={row.id} row={row} />}
    />
  );
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
};
