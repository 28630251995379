import React from "react";

import { Grid, Typography } from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import { onlineVar } from "../../graphql/localVariables/user";

export default function OfflineBanner() {
  const online = useReactiveVar(onlineVar);

  if (!online)
    return (
      <Grid sx={{ backgroundColor: "rgb(255, 183, 0)", height: 25 }}>
        <Typography align="center">OFFLINE MODE</Typography>
      </Grid>
    );

  return null;
}
