import React from "react";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Grid, Button, IconButton, useTheme, Typography } from "@mui/material";

import { useQuery, useReactiveVar } from "@apollo/client";

import { permissionsVar } from "../../../graphql/localVariables/user";
import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import SideNav from "../../SideNav";
import RecentHA from "../RecentHA";
import RecentObs from "../RecentObs";
import { checkInstall } from "../checkInstallPWA";
import { PWADialog } from "../pwa";
import useStyles from "./styles";

export default function MobileDashboard() {
  const classes = useStyles()();
  const theme = useTheme();
  const permissions = useReactiveVar(permissionsVar);
  const { data } = useQuery(GET_CURRENT_USER);
  const isSuperuser = data?.currentUser?.isSuperuser ?? false;
  const isStaff = data?.currentUser?.isStaff ?? false;

  const [activeIndex, changeActive] = React.useState(0);
  const cardArray = [RecentHA, RecentObs];

  const handleRightClick = () => {
    if (activeIndex + 1 < cardArray.length) {
      changeActive(() => activeIndex + 1);
    } else {
      changeActive(0);
    }
  };

  const handleLeftClick = () => {
    if (activeIndex - 1 >= 0) {
      changeActive(() => activeIndex - 1);
    } else {
      let remainder = cardArray.length % 1;
      if (remainder === 0) {
        remainder = 1;
      }
      changeActive(cardArray.length - remainder);
    }
  };

  const renderDots = () => {
    let numDots = cardArray.length;
    if (cardArray.length === 0) {
      numDots = 0;
    } else if (numDots < 1) {
      numDots = 1;
    } else {
      numDots = Math.ceil(numDots);
    }
    const arr = new Array(numDots).fill("");
    const currentPage = Math.floor(activeIndex);
    return arr.map((dot, index) => {
      if (index === currentPage) {
        return (
          <div
            key="current"
            style={{
              backgroundColor: "#F3CA3E",
              borderRadius: 10,
              width: "10px",
              height: "10px",
              margin: "10px 5px",
            }}
          />
        );
      } else {
        return (
          <div
            key="other"
            style={{
              backgroundColor: "#CDCDCD",
              borderRadius: 10,
              width: "10px",
              height: "10px",
              margin: "10px 5px",
            }}
          />
        );
      }
    });
  };

  const [prompt, setPrompt] = React.useState(null);
  const [appInstalled, setAppInstalled] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");

  const getGreetingText = () => {
    const hour = new Date().getHours();
    if (hour < 12) {
      return "MORNING";
    } else if (hour < 18) {
      return "AFTERNOON";
    }
    return "EVENING";
  };

  const greetingStr = "GOOD ".concat(getGreetingText(), ",");

  React.useEffect(() => {
    if (
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone
    ) {
      setAppInstalled(true);
    }

    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setPrompt(e);
    });

    window.addEventListener("appinstalled", () => {
      setAppInstalled(true);
    });
  }, []);

  React.useEffect(() => {
    if (data && data.currentUser) {
      setName(
        data.currentUser.firstName !== ""
          ? `${data.currentUser.firstName} ${data.currentUser.lastName}`
          : data.currentUser.username
      );
    }
  }, [data]);

  return (
    <div
      style={{
        backgroundColor: "#333",
        height: "calc(100vh - 60px)",
      }}
    >
      <SideNav
        permissions={permissions}
        isSuperuser={isSuperuser}
        isStaff={isStaff}
      />

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        style={{ marginTop: 60, marginBottom: 65 }}
      >
        <Grid item xs={12}>
          <Typography className={classes.greetingText}>
            {greetingStr}
          </Typography>
          <Typography className={classes.name}>{name.toUpperCase()}</Typography>
        </Grid>
        {/* <Grid item xs={12}>
          <RiskMitigation
            riskMitigationScore={riskMitigationScore}
            lastUpdate={lastUpdate}
          />
        </Grid> */}
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={1}>
            <IconButton onClick={handleLeftClick}>
              <ArrowBackIosNewIcon
                style={{ color: theme.palette.grey2.main }}
              />
            </IconButton>
          </Grid>
          <Grid item xs={10}>
            {activeIndex === 0 ? <RecentHA /> : <RecentObs />}
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={handleRightClick}>
              <ArrowForwardIosIcon
                style={{ color: theme.palette.grey2.main }}
              />
            </IconButton>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "0px" }}>
            <Grid container justifyContent="center">
              {renderDots()}
            </Grid>
          </Grid>
        </Grid>
        {!appInstalled && (
          <Button
            onClick={() => checkInstall(prompt, setOpen)}
            variant="contained"
            color="yellow0"
            sx={{ my: 2 }}
          >
            INSTALL APP
          </Button>
        )}
      </Grid>
      <PWADialog open={open} setOpen={setOpen} />
    </div>
  );
}
