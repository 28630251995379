import * as React from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { useQuery } from "@apollo/client";
import moment from "moment";
import PropTypes from "prop-types";

import { GET_PROJECT_DATA_COMPANY } from "../../../../../../../../graphql/queries/admin/company";
import EditProjectDialog from "../../../Edit/EditProjectDialog";

export default function Row({ row, company }) {
  const { data } = useQuery(GET_PROJECT_DATA_COMPANY, {
    variables: { projectId: Number(row.project.id) },
  });
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow
        hover
        onClick={() => setOpen(true)}
        key={row.id}
        style={{ cursor: "pointer" }}
      >
        <TableCell component="th" scope="row">
          {row.project.name}
        </TableCell>
        <TableCell>
          {data &&
            moment(new Date(data.project.timeCreated)).format(
              "MM/DD/YYYY HH:mm"
            )}
        </TableCell>
        <TableCell>{data?.project.ownerSiteProjects.length}</TableCell>
        <TableCell>${data?.project.dollarAmount}</TableCell>
      </TableRow>
      <EditProjectDialog
        open={open}
        setOpen={setOpen}
        data={row}
        company={company}
      />
    </>
  );
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
  company: PropTypes.object,
};
