import * as React from "react";

import { CircularProgress, Grid } from "@mui/material";

import { useQuery, useReactiveVar } from "@apollo/client";

import { onlineVar } from "../../../../../../../../graphql/localVariables/user";
import { GET_COMPANY_USERS } from "../../../../../../../../graphql/queries/admin/company";
import TableTemplate from "../../../../../../../CustomComponents/TableTemplate/TableTemplate";
import Row from "./Row";
import { filterData } from "./searchFilterFunction";

export default function Table({ company }) {
  const online = useReactiveVar(onlineVar);
  const { data, loading } = useQuery(GET_COMPANY_USERS, {
    variables: { companyId: Number(company.id) },
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  const [searchTerm, setSearchTerm] = React.useState("");

  const [filteredData, setFilteredData] = React.useState([]);

  // useEffect for filter update
  React.useEffect(() => {
    if (data) {
      setFilteredData(
        filterData(
          data.users.filter((u) => u.supervise.length > 0),
          searchTerm
        )
      );
    }
  }, [data, searchTerm]);

  return (
    <>
      {loading ? (
        <Grid container justifyContent="center">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <TableTemplate
          hasPagination={true}
          data={filteredData}
          title="Supervisors"
          headCells={[
            {
              uid: "name",
              numeric: false,
              disablePadding: false,
              label: "Name",
              sort: true,
            },
            {
              uid: "username",
              numeric: true,
              disablePadding: false,
              label: "Username",
              sort: true,
            },
            {
              uid: "supervisees",
              numeric: true,
              disablePadding: false,
              label: "# of Supervisees",
            },
          ]}
          initialOrderBy="name"
          getItemsFromOrderBy={(a, b, orderBy) => {
            let aItem, bItem;
            if (orderBy === "name") {
              aItem = `${a.firstName} ${a.lastName}`;
              bItem = `${b.firstName} ${b.lastName}`;
            } else {
              aItem = a[orderBy];
              bItem = b[orderBy];
            }

            return { aItem, bItem };
          }}
          renderFilterDialog={null}
          searchTerm={searchTerm}
          updateSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
          getBadgeCount={() => {}}
          renderRow={(row) => <Row key={row.id} row={row} />}
        />
      )}
    </>
  );
}
