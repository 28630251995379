import * as React from "react";

import { TableRow } from "@mui/material";
import TableCell from "@mui/material/TableCell";

import PropTypes from "prop-types";

export default function TableBodyTemplate({
  renderRow,
  order,
  orderBy,
  page,
  rowsPerPage,
  getComparator,
  stableSort,
  data,
  numColumns,
}) {
  if (
    stableSort(data, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    ).length === 0
  ) {
    return (
      <TableRow>
        <TableCell style={{ borderBottom: "transparent" }} colSpan={numColumns}>
          No Results Found
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {stableSort(data, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => renderRow(row))}
    </>
  );
}

TableBodyTemplate.propTypes = {
  renderRow: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  getComparator: PropTypes.func.isRequired,
  stableSort: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  numColumns: PropTypes.number.isRequired,
};
