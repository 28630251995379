import * as React from "react";

import TableTemplate from "../../../../../../../CustomComponents/TableTemplate/TableTemplate";
import Row from "./Row";
import { filterData } from "./searchFilterFunction";

export default function Table({ data }) {
  const [searchTerm, setSearchTerm] = React.useState("");

  const [filteredData, setFilteredData] = React.useState([]);

  React.useEffect(() => {
    setFilteredData(filterData(data, searchTerm));
  }, [data, searchTerm]);

  return (
    <TableTemplate
      hasPagination={true}
      data={filteredData}
      title="Observed Sites"
      headCells={[
        {
          uid: "name",
          numeric: false,
          disablePadding: false,
          label: "Site Name",
          sort: true,
        },
      ]}
      initialOrderBy="name"
      getItemsFromOrderBy={(a, b, orderBy) => {
        let aItem, bItem;

        if (orderBy === "name") {
          aItem = a.ownerSiteProject.site.name;
          bItem = b.ownerSiteProject.site.name;
        }

        return { aItem, bItem };
      }}
      renderFilterDialog={null}
      searchTerm={searchTerm}
      updateSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
      getBadgeCount={() => {}}
      renderRow={(row) => <Row key={row.id} row={row} />}
    />
  );
}
