import * as React from "react";

import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import PropTypes from "prop-types";

function TableHeadCell({ order, orderBy, headCell, createSortHandler }) {
  return (
    <TableCell
      key={headCell.uid}
      padding={headCell.disablePadding ? "none" : "normal"}
      sortDirection={orderBy === headCell.uid ? order : false}
    >
      {headCell.sort ? (
        <TableSortLabel
          active={orderBy === headCell.uid}
          direction={orderBy === headCell.uid ? order : "asc"}
          onClick={createSortHandler(headCell.uid)}
        >
          {headCell.label}
          {orderBy === headCell.uid ? (
            <Box component="span" sx={visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </Box>
          ) : null}
        </TableSortLabel>
      ) : (
        headCell.label
      )}
    </TableCell>
  );
}

TableHeadCell.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCell: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    disablePadding: PropTypes.bool,
    sort: PropTypes.bool,
  }).isRequired,
  createSortHandler: PropTypes.func.isRequired,
};

export default function TableHeader({
  headCells,
  order,
  orderBy,
  onRequestSort,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableHeadCell
              order={order}
              orderBy={orderBy}
              headCell={headCell}
              createSortHandler={createSortHandler}
              key={headCell.uid}
            />
          );
        })}
      </TableRow>
    </TableHead>
  );
}

TableHeader.propTypes = {
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disablePadding: PropTypes.bool,
      sort: PropTypes.bool,
    })
  ).isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};
