import React from "react";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import PropTypes from "prop-types";

import { CustomDialog } from "../../CustomStyles/dialog";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import PreviewImages from "./PreviewImages";

export default function ViewImages({ open, handleClose, images }) {
  const onCancel = () => {
    handleClose();
  };

  return (
    <CustomDialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle
        style={{
          ...pageTitleStyles,
        }}
      >
        VIEW AUDIT IMAGES.
      </DialogTitle>
      <DialogContent>
        <PreviewImages fileObjects={images} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onCancel}>
          DONE
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}

ViewImages.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
};
