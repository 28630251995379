import React from "react";

import { Info } from "@mui/icons-material";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

import { CustomDialog } from "../CustomStyles/dialog";
import { versionNum, releaseNotes } from "./releaseNotes";

export default function ReleaseNotesDialog() {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Grid container alignItems="center" justifyContent="center">
        <Typography
          style={{ color: "white", fontSize: "12px", fontWeight: "bold" }}
        >
          VERSION {versionNum}
        </Typography>
        <IconButton onClick={() => setOpen(true)}>
          <Info
            style={{ color: "white", fontSize: "18px", marginLeft: "20px" }}
          />
        </IconButton>
      </Grid>

      <CustomDialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>RELEASE NOTES</DialogTitle>
        <DialogContent style={{ height: "90vh" }}>
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
            {releaseNotes[0].versionNum} ({releaseNotes[0].date})
          </Typography>
          <List sx={{ listStyleType: "disc", pl: 4 }}>
            {releaseNotes[0].notes.map((n) => (
              <ListItem key={n} sx={{ display: "list-item" }}>
                <ListItemText>{n}</ListItemText>
              </ListItem>
            ))}
          </List>

          {releaseNotes.map((note, index) => (
            <div key={note.versionNum}>
              {index !== 0 && (
                <Grid>
                  <Typography style={{ marginTop: "20px" }}>
                    {note.versionNum} ({note.date})
                  </Typography>
                  <List sx={{ listStyleType: "disc", pl: 4 }}>
                    {note.notes.map((n) => (
                      <ListItem key={n} sx={{ display: "list-item" }}>
                        <ListItemText>{n}</ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              )}
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="yellow0"
            onClick={() => setOpen(false)}
          >
            CLOSE
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
}
