import * as React from "react";

import { useLazyQuery, useReactiveVar } from "@apollo/client";
import moment from "moment";

import { viewHAFiltersVar } from "../../../../graphql/localVariables/ha";
import { onlineVar } from "../../../../graphql/localVariables/user";
import { haMutations } from "../../../../graphql/mutations";
import { GET_HAS } from "../../../../graphql/queries/ha";
import TableTemplate from "../../../CustomComponents/TableTemplate/TableTemplate";
import Row from "./Row";
import TableFilterDialog from "./TableFilterDialog";

export default function Table() {
  const online = useReactiveVar(onlineVar);
  const [getHAs, { data, loading, refetch, fetchMore }] = useLazyQuery(
    GET_HAS,
    {
      variables: {
        first: 50,
        name: "",
        owner: null,
        site: null,
        afterDate: null,
        beforeDate: null,
        submitterCompany: null,
        user: null,
        projects: null,
        templates: null,
        status: null,
        incident: null,
      },
      fetchPolicy: online ? "network-only" : "cache-only",
      notifyOnNetworkStatusChange: true,
    }
  );
  const { updateAllViewHAFilters, updateKeyViewHAFilters } = haMutations;
  const filters = useReactiveVar(viewHAFiltersVar);
  const [initialLoad, setInitialLoad] = React.useState(false);

  const clearFilters = () => {
    updateAllViewHAFilters({
      site: { owner: { name: "" } },
      projects: [],
      templates: [],
      afterDate: null,
      beforeDate: null,
      status: "",
      incident: "",
      usernames: [],
      company: { name: "" },
      searchterm: "",
    });
  };

  const getInput = () => {
    return {
      name: filters.searchterm ? filters.searchterm : "",
      owner: filters.site.owner?.id ? Number(filters.site.owner.id) : null,
      site: filters.site.site ? Number(filters.site.site.id) : null,
      afterDate: filters.afterDate
        ? moment(filters.afterDate).format("YYYY-MM-DD")
        : null,
      beforeDate: filters.beforeDate
        ? moment(filters.beforeDate).format("YYYY-MM-DD")
        : null,
      submitterCompany:
        filters.company && filters.company.name !== ""
          ? filters.company.id
          : null,
      user:
        filters.usernames && filters.usernames.length > 0
          ? filters.usernames.map((u) => Number(u.id))
          : null,
      projects:
        filters.projects && filters.projects.length > 0
          ? filters.projects.map((p) => Number(p.id))
          : null,
      templates:
        filters.templates && filters.templates.length > 0
          ? filters.templates.map((t) => Number(t.id))
          : null,
      status:
        !filters.status || filters.status === ""
          ? null
          : filters.status === "Closed",
      incident:
        !filters.incident || filters.incident === ""
          ? null
          : filters.incident === "With",
    };
  };

  const onFilter = () => {
    const input = {
      first: 50,
      ...getInput(),
    };

    refetch(input);
  };

  React.useEffect(() => {
    getHAs({
      variables: { first: 50, ...getInput() },
      onCompleted() {
        setInitialLoad(true);
      },
    });
  }, []);

  React.useEffect(() => {
    if (filters.searchterm && initialLoad) {
      onFilter();
    }
  }, [filters.searchterm]);

  const headCells = [
    {
      uid: "id",
      numeric: false,
      disablePadding: false,
      label: "HA ID",
      sort: true,
    },
    {
      uid: "name",
      numeric: false,
      disablePadding: false,
      label: "HA Name",
      sort: true,
    },
    {
      uid: "date",
      numeric: true,
      disablePadding: false,
      label: "Date",
      sort: true,
    },
    {
      uid: "submitter",
      numeric: true,
      disablePadding: false,
      label: "Submitter Username",
      sort: true,
    },
    {
      uid: "company",
      numeric: true,
      disablePadding: false,
      label: "Company",
      sort: true,
    },
    {
      uid: "locations",
      numeric: true,
      disablePadding: false,
      label: "Location",
    },
    {
      uid: "projects",
      numeric: true,
      disablePadding: false,
      label: "Project(s)",
    },
    {
      uid: "templates",
      numeric: true,
      disablePadding: false,
      label: "Template(s)",
    },
    {
      uid: "isCompleted",
      numeric: true,
      disablePadding: false,
      label: "HA Status",
      sort: true,
    },
    {
      uid: "revisionNumber",
      numeric: true,
      disablePadding: false,
      label: "Revisions",
      sort: true,
    },
    {
      uid: "hasInjury",
      numeric: true,
      disablePadding: false,
      label: "With or Without Incident",
      sort: true,
    },
    {
      uid: "observations",
      numeric: true,
      disablePadding: false,
      label: "# of Linked Observations",
      sort: true,
    },
    {
      uid: "crewmembers",
      numeric: true,
      disablePadding: false,
      label: "# of Crew Members",
      sort: true,
    },
    {
      uid: "raScore",
      numeric: true,
      disablePadding: false,
      label: "Risk Score",
      sort: true,
    },
  ];

  return (
    <TableTemplate
      hasPagination={true}
      data={
        data?.hazardAnalyses?.edges
          ? data.hazardAnalyses.edges.map((edge) => ({
              ...edge.node,
              haOwnerSiteProject: edge.node.haOwnerSiteProject.filter(
                (haOSP) => haOSP.isActive
              ),
            }))
          : []
      }
      handleFetchMore={(page, rowsPerPage) => {
        if (
          data?.hazardAnalyses?.edges &&
          (page + 1) * rowsPerPage + 1 > data.hazardAnalyses.edges.length &&
          data.hazardAnalyses?.pageInfo?.hasNextPage
        ) {
          fetchMore({
            variables: {
              after: data.hazardAnalyses.pageInfo.endCursor,
              first: 50, // Fetch the next 50 items
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return previousResult;
              return {
                hazardAnalyses: {
                  ...fetchMoreResult.hazardAnalyses,
                  edges: [
                    ...previousResult.hazardAnalyses.edges,
                    ...fetchMoreResult.hazardAnalyses.edges,
                  ],
                },
              };
            },
          });
        }
      }}
      paginationCount={
        data?.hazardAnalyses?.totalCount ? data.hazardAnalyses.totalCount : 0
      }
      title="Hazard Analyses"
      headCells={headCells}
      initialOrderBy="date"
      initialSort="desc"
      getItemsFromOrderBy={(a, b, orderBy) => {
        let aItem, bItem;

        switch (orderBy) {
          case "submitter":
            aItem = a.submitter.username;
            bItem = b.submitter.username;
            break;
          case "company":
            aItem = a.submitter.company?.name;
            bItem = b.submitter.company?.name;
            break;
          case "isCompleted":
            aItem = a.isCompleted ? 1 : 0;
            bItem = b.isCompleted ? 1 : 0;
            break;
          case "hasInjury":
            aItem = a.hasInjury ? "With" : "Without";
            bItem = b.hasInjury ? "With" : "Without";
            break;
          case "observations":
            aItem = a.observation.length;
            bItem = b.observation.length;
            break;
          case "crewmembers":
            aItem = a.haCrewMembers.length;
            bItem = b.haCrewMembers.length;
            break;
          default:
            aItem = a[orderBy];
            bItem = b[orderBy];
        }

        return { aItem, bItem };
      }}
      renderFilterDialog={(open, setOpen, setPage) => (
        <TableFilterDialog
          open={open}
          setOpen={setOpen}
          clearFilters={clearFilters}
          onFilter={() => {
            const input = {
              first: 50,
              ...getInput(),
            };

            refetch(input);
            setPage(0);
          }}
        />
      )}
      searchTerm={filters.searchterm}
      updateSearchTerm={(searchTerm) =>
        updateKeyViewHAFilters("searchterm", searchTerm)
      }
      getBadgeCount={() => {
        let count = 0;
        if (filters.site && filters.site.owner.name !== "") count++;
        if (filters.projects && filters.projects.length > 0) count++;
        if (filters.templates && filters.templates.length > 0) count++;
        if (filters.company && filters.company.name !== "") count++;
        if (filters.status && filters.status !== "") count++;
        if (filters.incident && filters.incident !== "") count++;
        if (filters.usernames && filters.usernames.length > 0) count++;
        if (filters.afterDate && filters.afterDate !== null) count++;
        if (filters.beforeDate && filters.beforeDate !== null) count++;
        return count;
      }}
      renderRow={(row) => <Row key={row.id} row={row} />}
      loading={loading}
    />
  );
}
