import React from "react";

import { Grid, Box } from "@mui/material";

import { useQuery, useReactiveVar } from "@apollo/client";

import { permissionsVar } from "../../graphql/localVariables/user";
import { GET_CURRENT_USER } from "../../graphql/queries/auth";
import OfflineBanner from "../CustomComponents/OfflineBanner";
import SideNav from "../SideNav";

export default function Layout({ children }) {
  const permissions = useReactiveVar(permissionsVar);
  const { data } = useQuery(GET_CURRENT_USER);

  return (
    <Grid>
      <SideNav
        dismissable
        permissions={permissions}
        isSuperuser={data?.currentUser?.isSuperuser ?? false}
        isStaff={data?.currentUser?.isStaff ?? false}
      />
      <Box>
        <Grid
          sx={{
            marginTop: { xs: "64px" },
          }}
        >
          <OfflineBanner />
          <Grid
            sx={{
              padding: { xs: "20px", md: "35px" },
              marginBottom: { xs: "50px", md: "0px" },
            }}
          >
            {children}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
