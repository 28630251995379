import * as React from "react";

import { useLazyQuery, useReactiveVar } from "@apollo/client";
import moment from "moment";

import { viewObservationFiltersVar } from "../../../../graphql/localVariables/observation";
import { onlineVar } from "../../../../graphql/localVariables/user";
import { observationMutations } from "../../../../graphql/mutations";
import { GET_OBSERVATIONS } from "../../../../graphql/queries/observations";
import TableTemplate from "../../../CustomComponents/TableTemplate/TableTemplate";
import Row from "./Row";
import TableFilterDialog from "./TableFilterDialog";

export default function Table() {
  const online = useReactiveVar(onlineVar);
  const [getObs, { data, loading, refetch, fetchMore }] = useLazyQuery(
    GET_OBSERVATIONS,
    {
      fetchPolicy: online ? "network-only" : "cache-only",
      notifyOnNetworkStatusChange: true,
    }
  );
  const { updateAllViewObservationFilter, updateKeyViewObservationFilter } =
    observationMutations;
  const filters = useReactiveVar(viewObservationFiltersVar);
  const [initialLoad, setInitialLoad] = React.useState(false);

  const clearFilters = () => {
    updateAllViewObservationFilter({
      site: { owner: { name: "" } },
      contractor: { contractor: { name: "" } },
      afterDate: null,
      beforeDate: null,
      outcomes: [],
      status: "",
      usernames: [],
      searchterm: "",
    });
  };

  const getInput = () => {
    return {
      name: filters.searchterm ?? "",
      owner: filters.site?.owner?.id ? Number(filters.site.owner.id) : null,
      site: filters.site?.site ? Number(filters.site.site.id) : null,
      afterDate: filters.afterDate
        ? moment(filters.afterDate).format("YYYY-MM-DD")
        : null,
      beforeDate: filters.beforeDate
        ? moment(filters.beforeDate).format("YYYY-MM-DD")
        : null,
      contractor: filters.contractor?.contractor?.id
        ? Number(filters.contractor.contractor.id)
        : null,
      submitter:
        filters.usernames && filters.usernames.length > 0
          ? filters.usernames.map((u) => Number(u.id))
          : null,
      outcome:
        filters.outcomes && filters.outcomes.length > 0
          ? filters.outcomes.map((o) => Number(o.id))
          : null,
      planned:
        !filters.status || filters.status === ""
          ? null
          : filters.status === "Planned",
    };
  };

  const onFilter = () => {
    const input = {
      first: 50,
      ...getInput(),
    };

    refetch(input);
  };

  React.useEffect(() => {
    getObs({
      variables: { first: 50, ...getInput() },
      onCompleted() {
        setInitialLoad(true);
      },
    });
  }, []);

  React.useEffect(() => {
    if (filters.searchterm !== "" && initialLoad) {
      onFilter();
    }
  }, [filters.searchterm]);

  const headCells = [
    {
      uid: "name",
      numeric: false,
      disablePadding: false,
      label: "OBS ID",
      sort: true,
    },
    {
      uid: "date",
      numeric: true,
      disablePadding: false,
      label: "Date",
      sort: true,
    },
    {
      uid: "outcome",
      numeric: true,
      disablePadding: false,
      label: "Outcome",
      sort: false,
    },
    {
      uid: "Submitter Username",
      numeric: true,
      disablePadding: false,
      label: "Submitter Username",
      sort: true,
    },
    {
      uid: "Contractor Observed",
      numeric: true,
      disablePadding: false,
      label: "Contractor Observed",
      sort: true,
    },
    {
      uid: "Planned Status",
      numeric: true,
      disablePadding: false,
      label: "Planned Status",
      sort: false,
    },
    {
      uid: "Location",
      numeric: true,
      disablePadding: false,
      label: "Location",
      sort: false,
    },
    {
      uid: "HA Name",
      numeric: true,
      disablePadding: false,
      label: "HA Name",
      sort: true,
    },
    {
      uid: "Notifications Sent",
      numeric: true,
      disablePadding: false,
      label: "Notifications Sent",
    },
    {
      uid: "Notifications Seen",
      numeric: true,
      disablePadding: false,
      label: "Notifications Seen",
    },
  ];

  return (
    <TableTemplate
      hasPagination={true}
      data={
        data?.observations?.edges
          ? data.observations.edges.map((e) => e.node)
          : []
      }
      handleFetchMore={(page, rowsPerPage) => {
        if (
          data?.observations?.edges &&
          (page + 1) * rowsPerPage + 1 > data.observations.edges.length &&
          data.observations?.pageInfo?.hasNextPage
        ) {
          fetchMore({
            variables: {
              after: data.observations.pageInfo.endCursor,
              first: 50, // Fetch the next 50 items
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return previousResult;
              return {
                observations: {
                  ...fetchMoreResult.observations,
                  edges: [
                    ...previousResult.observations.edges,
                    ...fetchMoreResult.observations.edges,
                  ],
                },
              };
            },
          });
        }
      }}
      paginationCount={
        data?.observations?.totalCount ? data.observations.totalCount : 0
      }
      title="Observations"
      headCells={headCells}
      initialOrderBy="date"
      initialSort="desc"
      getItemsFromOrderBy={(a, b, orderBy) => {
        let aItem, bItem;

        switch (orderBy) {
          case "Submitter Username":
            aItem = a.submitter.username;
            bItem = b.submitter.username;
            break;
          case "HA Name":
            aItem = a.ha ? a.ha.name : "";
            bItem = b.ha ? b.ha.name : "";
            break;
          case "Contractor Observed":
            aItem = a.contractor ? a.contractor.name : "";
            bItem = b.contractor ? b.contractor.name : "";
            break;
          default:
            aItem = a[orderBy];
            bItem = b[orderBy];
        }

        return { aItem, bItem };
      }}
      renderFilterDialog={(open, setOpen, setPage) => (
        <TableFilterDialog
          open={open}
          setOpen={setOpen}
          clearFilters={clearFilters}
          onFilter={() => {
            const input = {
              first: 50,
              ...getInput(),
            };
            refetch(input);
            setPage(0);
          }}
        />
      )}
      searchTerm={filters.searchterm}
      updateSearchTerm={(searchTerm) =>
        updateKeyViewObservationFilter("searchterm", searchTerm)
      }
      getBadgeCount={() => {
        let count = 0;
        if (filters.site && filters.site.owner.name !== "") count++;
        if (filters.contractor && filters.contractor.contractor.name !== "")
          count++;
        if (filters.outcomes && filters.outcomes.length > 0) count++;
        if (filters.status && filters.status !== "") count++;
        if (filters.usernames && filters.usernames.length > 0) count++;
        if (filters.afterDate && filters.afterDate !== null) count++;
        if (filters.beforeDate && filters.beforeDate !== null) count++;
        return count;
      }}
      renderRow={(row) => <Row key={row.id} row={row} />}
      loading={loading}
    />
  );
}
