import React from "react";

import {
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";

import {
  onlineVar,
  permissionsVar,
} from "../../../../../../graphql/localVariables/user";
import { CHANGE_USER_PROFILE } from "../../../../../../graphql/mutations/userSettings";
import { GET_COMPANY_USER_DATA } from "../../../../../../graphql/queries/admin/company";
import { GET_CURRENT_USER } from "../../../../../../graphql/queries/auth";
import AuditTable from "../../../../../Audit/View/Table";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../../../CustomComponents/OnErrorFunction";
import TabPanel from "../../../../../CustomComponents/TabPanels";
import {
  StyledTabs,
  StyledTabWhite,
} from "../../../../../CustomComponents/Tabs";
import { CustomTextField } from "../../../../../CustomStyles/LightTextField";
import { defaultAvatar } from "../../../../../CustomStyles/avatar";
import { CustomDialog } from "../../../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../../../CustomStyles/pageTitle";
import EditUserPermissions from "./EditUserPermissions";
import EditUserSites from "./EditUserSites";
import SupervisionTab from "./EditUserSupervision";
import HATable from "./HATable";
import ObsTable from "./ObsTable";
import UploadProfilePic from "./UploadProfilePic";
import useStyles from "./styles";

export default function EditUserDialog({ open, setOpen, d, tabIndex = 0 }) {
  const theme = useTheme();
  const classes = useStyles();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const online = useReactiveVar(onlineVar);
  const permissions = useReactiveVar(permissionsVar);

  // Queries
  const [getCurrentUser, { data: currentUser }] =
    useLazyQuery(GET_CURRENT_USER);
  const [getUserData, { data }] = useLazyQuery(GET_COMPANY_USER_DATA, {
    variables: { id: Number(d.id) },
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  const [editDisabled, setEditDisabled] = React.useState(false);

  React.useEffect(() => {
    if (!permissions.includes("EDIT_USER")) {
      setEditDisabled(true);
    }
  }, []);

  React.useEffect(() => {
    if (open) {
      getUserData({
        variables: { id: Number(d.id) },
      });
      getCurrentUser();
    }
  }, [open]);

  // General Info Mutation
  const [mutateUserProfile] = useMutation(CHANGE_USER_PROFILE, {
    onCompleted() {
      onCompletedFunc("Profile has been updated");
      reset();
    },
    onError(error) {
      onErrorFunc(error);
      reset();
    },
  });

  // Local State
  const [openObs, setOpenObs] = React.useState(false);
  const [openHA, setOpenHA] = React.useState(false);
  const [openAudit, setOpenAudit] = React.useState(false);

  // Tab State
  const [value, setValue] = React.useState(tabIndex);

  // General Info State
  const [firstName, setFirstName] = React.useState(d.firstName);
  const [lastName, setLastName] = React.useState(d.lastName);
  const [username, setUsername] = React.useState(d.username);
  const [email, setEmail] = React.useState(d.email);

  // On Done: closes modal and resets
  const onDone = () => {
    setOpen(false);
    reset();
  };

  // On Save: chooses mutation based on tab index
  const onSave = () => {
    switch (value) {
      case 0:
        const input = {
          userId: d.id,
          firstName,
          lastName,
          username: username.toLowerCase(),
        };
        if (!editDisabled && currentUser.currentUser.isStaff) {
          input.email = email;
        }
        return mutateUserProfile({
          variables: input,
        });
      default:
        return null;
    }
  };

  // Resets General Info
  const reset = () => {
    setFirstName(d.firstName);
    setLastName(d.lastName);
    setUsername(d.username);
    setEmail(d.email);
  };

  // Tabs Function: sets index
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderButtons = () => {
    return (
      <Grid sx={{ textAlignLast: "end", paddingTop: "50px" }}>
        <Button
          variant="contained"
          color="yellow0"
          sx={{ mr: 1 }}
          onClick={onSave}
          disabled={editDisabled}
        >
          SAVE
        </Button>
        <Button
          variant="contained"
          style={{ marginRight: "10px" }}
          onClick={onDone}
        >
          DONE
        </Button>
      </Grid>
    );
  };

  return (
    <CustomDialog
      open={open}
      fullWidth
      maxWidth="lg"
      fullScreen={mobile}
      sx={{ color: "#333" }}
      onClose={() => setOpen(false)}
    >
      <DialogTitle style={{ ...pageTitleStyles }}>
        USER:
        <span style={{ color: "#FFB700" }}>
          {" "}
          {d.firstName !== "" && d.lastName !== ""
            ? `${d.firstName} ${d.lastName}`
            : d.username}
        </span>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          {/* Profile Pic  */}

          <Grid item xs={3} alignItems="center" justifyContent="center">
            <Avatar
              alt={`${d.firstName} ${d.lastName}`}
              src={d.image}
              sx={{
                ...defaultAvatar,
                width: "150px",
                margin: "auto",
                height: "150px",
                fontSize: "2.5rem",
              }}
            />
            <UploadProfilePic userId={d.id} disabled={editDisabled} />
          </Grid>

          {/* Tab Panel */}

          <Grid item xs={9}>
            <StyledTabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              style={{ color: "white", paddingBottom: "20px" }}
            >
              <StyledTabWhite label="GENERAL INFO" />
              <StyledTabWhite label="PERMISSIONS" />
              <StyledTabWhite label="SITES" />
              <StyledTabWhite label="SUPERVISION" />
              <StyledTabWhite label="REPORTS" />
            </StyledTabs>

            {/* General Info Tab */}

            <TabPanel value={value} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <CustomTextField
                    variant="standard"
                    label="First Name:"
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                    style={{ width: "100%" }}
                    disabled={editDisabled}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextField
                    variant="standard"
                    label="Last Name:"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                    style={{ width: "100%" }}
                    disabled={editDisabled}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextField
                    variant="standard"
                    label="Username:"
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                    type="username"
                    style={{ width: "100%" }}
                    disabled={editDisabled}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextField
                    variant="standard"
                    label="Email:"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    type="email"
                    style={{ width: "100%" }}
                    disabled={
                      currentUser
                        ? editDisabled || !currentUser.currentUser.isStaff
                        : true
                    }
                  />
                </Grid>
              </Grid>
              {renderButtons()}
            </TabPanel>

            {/* Permissions Tab */}

            <TabPanel value={value} index={1}>
              {data && (
                <EditUserPermissions
                  d={data}
                  setOpen={setOpen}
                  disabled={editDisabled}
                />
              )}
            </TabPanel>

            {/* Sites Tab */}

            <TabPanel value={value} index={2}>
              {data && (
                <EditUserSites
                  data={data}
                  setOpen={setOpen}
                  disabled={editDisabled}
                />
              )}
            </TabPanel>

            {/* Supervisor To Tab */}

            <TabPanel value={value} index={3}>
              {data && (
                <SupervisionTab
                  d={data}
                  open={open}
                  setOpen={setOpen}
                  disabled={
                    editDisabled || !permissions.includes("SUPERVISION")
                  }
                />
              )}
            </TabPanel>

            {/* Reports Tab */}

            <TabPanel value={value} index={4}>
              {data && (
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Paper
                      elevation={5}
                      style={{
                        width: "100%",
                        height: "80px",
                        backgroundColor: "inherit",
                        borderRadius: "10px",
                        boxShadow: `10px -10px 30px ${theme.palette.grey3.main}`,
                        color: "white",
                      }}
                    >
                      <Grid
                        container
                        style={{ position: "relative", height: "100%" }}
                      >
                        <Typography
                          style={{ padding: "10px", fontWeight: "bold" }}
                        >
                          OBSERVATIONS ({data.user.observation.edges.length})
                        </Typography>
                        <div
                          className={classes.overlay}
                          onClick={() => setOpenObs(true)}
                        >
                          <Typography className={classes.text}>
                            View All
                          </Typography>
                        </div>
                        <CustomDialog
                          fullWidth
                          maxWidth="lg"
                          open={openObs}
                          onClose={() => setOpenObs(false)}
                        >
                          <ObsTable
                            data={data.user.observation.edges.map(
                              (e) => e.node
                            )}
                          />
                          <DialogActions>
                            <Button
                              variant="contained"
                              color="yellow0"
                              onClick={() => setOpenObs(false)}
                            >
                              DONE
                            </Button>
                          </DialogActions>
                        </CustomDialog>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper
                      elevation={5}
                      style={{
                        width: "100%",
                        height: "80px",
                        backgroundColor: "inherit",
                        borderRadius: "10px",
                        boxShadow: `10px -10px 30px ${theme.palette.grey3.main}`,
                        color: "white",
                      }}
                    >
                      <Grid
                        container
                        style={{ position: "relative", height: "100%" }}
                      >
                        <Typography
                          style={{ padding: "10px", fontWeight: "bold" }}
                        >
                          HAs ({data.user.ha.edges.length})
                        </Typography>
                        <div
                          className={classes.overlay}
                          onClick={() => setOpenHA(true)}
                        >
                          <Typography className={classes.text}>
                            View All
                          </Typography>
                        </div>
                        <CustomDialog
                          fullWidth
                          maxWidth="lg"
                          open={openHA}
                          onClose={() => setOpenHA(false)}
                        >
                          <HATable
                            data={data.user.ha.edges.map((e) => e.node)}
                          />
                          <DialogActions>
                            <Button
                              variant="contained"
                              color="yellow0"
                              onClick={() => setOpenHA(false)}
                            >
                              DONE
                            </Button>
                          </DialogActions>
                        </CustomDialog>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper
                      elevation={5}
                      style={{
                        width: "100%",
                        height: "80px",
                        backgroundColor: "inherit",
                        borderRadius: "10px",
                        boxShadow: `10px -10px 30px ${theme.palette.grey3.main}`,
                        color: "white",
                      }}
                    >
                      <Grid
                        container
                        style={{ position: "relative", height: "100%" }}
                      >
                        <Typography
                          style={{ padding: "10px", fontWeight: "bold" }}
                        >
                          AUDITS ({data.user.auditSubmission.length})
                        </Typography>
                        <div
                          className={classes.overlay}
                          onClick={() => setOpenAudit(true)}
                        >
                          <Typography className={classes.text}>
                            View All
                          </Typography>
                        </div>
                        <CustomDialog
                          fullWidth
                          maxWidth="lg"
                          open={openAudit}
                          onClose={() => setOpenAudit(false)}
                        >
                          <AuditTable data={data.user.auditSubmission} />
                          <DialogActions>
                            <Button
                              variant="contained"
                              color="yellow0"
                              onClick={() => setOpenAudit(false)}
                            >
                              DONE
                            </Button>
                          </DialogActions>
                        </CustomDialog>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              )}
              {renderButtons()}
            </TabPanel>
          </Grid>
        </Grid>
      </DialogContent>
    </CustomDialog>
  );
}
