import React from "react";

import { Document } from "@react-pdf/renderer";

import ARMSection from "./ARMSection";
import MissedMitigatorsPage from "./MissedMitigatorsPage";
import ReviewPage from "./ReviewPage";
import SummaryPage from "./SummaryPage";

export const PDFDocument = (ha, arms, showScores) => {
  return (
    <Document
      pageMode="fullScreen"
      title="Hazard Analysis Report"
      author="NIXN"
    >
      <SummaryPage ha={ha} showScores={showScores} />
      <ARMSection ha={ha} />
      <MissedMitigatorsPage ha={ha} arms={arms} />
      <ReviewPage ha={ha} />
    </Document>
  );
};
