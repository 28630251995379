import React from "react";

import { Grid, Typography } from "@mui/material";

import { useReactiveVar, useQuery } from "@apollo/client";

import { createAuditVar } from "../../../graphql/localVariables/audit";
import { auditMutations } from "../../../graphql/mutations";
import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Layout from "../../Layout";
import AuditForm from "../Form";
import AuditTypeInput from "./AuditType";
import DatePicker from "./DatePicker";
import LocationInput from "./LocationInput";
import TimePicker from "./TimePicker";

export default function CreateAudit() {
  const createAudit = useReactiveVar(createAuditVar);
  const { updateCreateAudit } = auditMutations;
  const [startAudit, setStartAudit] = React.useState(false);
  // Query for user data
  const { data: userData } = useQuery(GET_CURRENT_USER);

  React.useEffect(() => {
    if (createAudit.audit?.name && createAudit.location) {
      setStartAudit(true);
    } else {
      updateCreateAudit("time", new Date());
    }
  }, []);

  React.useEffect(() => {
    if (userData?.currentUser) {
      updateCreateAudit("creator", userData.currentUser);
    }
  }, [userData, createAudit.creator]);

  return (
    <Layout>
      <Grid>
        <Typography sx={pageTitleStyles}>CREATE AUDIT.</Typography>
        {startAudit ? (
          <AuditForm
            type={"CREATE"}
            setStartAudit={setStartAudit}
            audit={createAudit.audit}
          />
        ) : (
          <div
            style={{
              borderRadius: "5px",
              backgroundColor: "#fff",
              padding: "1em 1em 2em 1em",
              minWidth: "200px",
              height: "100%",
            }}
          >
            <Grid item xs={12} sx={{ padding: ".25em" }}>
              <LocationInput setStartAudit={setStartAudit} />
            </Grid>
            <Grid item xs={12} sx={{ padding: ".25em" }}>
              <DatePicker />
            </Grid>
            <Grid item xs={12} sx={{ padding: ".25em" }}>
              <TimePicker />
            </Grid>
            <Grid item xs={12} sx={{ padding: ".25em" }}>
              <AuditTypeInput setStartAudit={setStartAudit} />
            </Grid>
          </div>
        )}
      </Grid>
    </Layout>
  );
}
