/* eslint-disable react/display-name */
import * as React from "react";
import { TableVirtuoso } from "react-virtuoso";

import SearchIcon from "@mui/icons-material/Search";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../../../../../CustomStyles/searchBar";
import EditCrewDialog from "../../Edit/EditCrewDialog";

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

function Row({ row, company }) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableCell component="th" scope="row">
        {`${row.firstName} ${row.lastName}`}
      </TableCell>
      <TableCell>{row.phoneNumber}</TableCell>
      <TableCell>{row.jobTitle?.name}</TableCell>
      <TableCell>{row.craftType?.name}</TableCell>
      <TableCell>
        <Button variant="contained" onClick={() => setOpen(true)}>
          VIEW
        </Button>
        <EditCrewDialog
          open={open}
          setOpen={setOpen}
          company={company}
          d={row}
        />
      </TableCell>
    </React.Fragment>
  );
}

function rowContent(_index, row, company) {
  return <Row index={_index} row={row} company={company} key={row.id} />;
}

export default function CrewMemberVirtualizedTable({ data, columns, company }) {
  const [search, setSearch] = React.useState("");

  function fixedHeaderContent(columns) {
    return (
      <>
        <TableRow>
          <TableCell colSpan={5}>
            <Grid container justifyContent="space-bewteen">
              <Typography variant="h6" id="tableTitle" component="div">
                Crewmembers
              </Typography>
              <Tooltip title="Search">
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{
                      "aria-label": "search",
                    }}
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                  />
                </Search>
              </Tooltip>
            </Grid>
          </TableCell>
        </TableRow>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.dataKey}
              variant="head"
              align={"left"}
              style={{ width: column.width }}
              sx={{
                backgroundColor: "background.paper",
              }}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </>
    );
  }

  return (
    <Paper style={{ height: 400, width: "100%" }}>
      <TableVirtuoso
        data={data.filter((row) =>
          `${row.firstName} ${row.lastName}`
            .toLowerCase()
            .includes(search.toLowerCase())
        )}
        components={VirtuosoTableComponents}
        fixedHeaderContent={() => fixedHeaderContent(columns)}
        itemContent={(index, row) => rowContent(index, row, company)}
      />
    </Paper>
  );
}
