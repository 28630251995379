import React from "react";
import { useNavigate } from "react-router-dom";

import { Grid, Typography, Button } from "@mui/material";

import { useMutation, useReactiveVar } from "@apollo/client";
import { DropzoneDialog } from "mui-file-dropzone";

import { permissionsVar } from "../../../graphql/localVariables/user";
import { UPLOAD_AUDIT_CSV } from "../../../graphql/mutations/audits";
import { GET_AUDITS } from "../../../graphql/queries/audits";
import {
  onErrorFunc,
  onCompletedFunc,
} from "../../CustomComponents/OnErrorFunction";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Layout from "../../Layout";
import Table from "./Table";
import CSV from "./audit_template.csv";

export default function AuditManagement() {
  const navigate = useNavigate();
  const permissions = useReactiveVar(permissionsVar);

  const [uploadAuditCSV] = useMutation(UPLOAD_AUDIT_CSV, {
    onCompleted() {
      onCompletedFunc("Audit has been created");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [
      {
        query: GET_AUDITS,
      },
    ],
  });

  const [openUpload, setOpenUpload] = React.useState(false);

  const handleSave = (file) => {
    uploadAuditCSV({ variables: { file: file[0] } });
    setOpenUpload(false);
  };

  React.useEffect(() => {
    if (
      permissions.length === 0 ||
      (permissions.length > 0 && !permissions.includes("AUDIT_MANAGE"))
    ) {
      navigate("/");
    }
  }, []);

  return (
    <Layout>
      <Grid
        style={{
          height: "100%",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: "15px" }}
        >
          <Grid item>
            <Typography sx={pageTitleStyles}>AUDIT MANAGEMENT.</Typography>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              sx={{ mr: 1 }}
              onClick={() => {
                fetch(CSV)
                  .then((response) => response.blob())
                  .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = "audit_template.csv";
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                  })
                  .catch(() => alert("Failed to download file."));
              }}
            >
              DOWNLOAD TEMPLATE
            </Button>
            <Button
              color="yellow0"
              variant="contained"
              onClick={() => setOpenUpload(true)}
            >
              UPLOAD NEW CSV
            </Button>
            <DropzoneDialog
              open={openUpload}
              onSave={handleSave}
              acceptedFiles={[
                ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
              ]}
              showPreviews={true}
              filesLimit={1}
              onClose={() => setOpenUpload(false)}
              maxFileSize={10000000} // 10MB
            />
          </Grid>
        </Grid>
        <Table />
      </Grid>
    </Layout>
  );
}
