import * as React from "react";

import TablePagination from "@mui/material/TablePagination";

import PropTypes from "prop-types";

export default function Pagination({
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  dataLength,
  handleFetchMore,
  paginationCount,
}) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    if (handleFetchMore) {
      handleFetchMore(page, rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={paginationCount ?? dataLength}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      showFirstButton
    />
  );
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  dataLength: PropTypes.number.isRequired,
  handleFetchMore: PropTypes.func,
  paginationCount: PropTypes.number,
};
