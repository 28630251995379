import { makeVar } from "@apollo/client";

import makeVarPersisted from "../makeVarPersisted";

export const initialState = {
  owner: null,
  site: null,
  project: null,
  ospId: null,
  date: new Date(),
  time: new Date(),
  ha: { name: "", date: "" },
  action: null,
  location: null,
  crewMembers: [],
  employeeName: "",
  employeeJobTitle: "",
  employeePhoneNumber: "",
  employeeDOB: null,
  type: "",
  injuryDate: new Date(),
  injuryTime: new Date(),
  haARM: [],
  incidentReportArms: [],
  incidentTopicResponses: [],
  incidentQuestionResponses: [],
  isCompleted: false,
  weather: "",
  removeFiles: [],
};
export const createIncidentVar = makeVarPersisted(initialState, "createInc");
export const editIncidentVar = makeVarPersisted(initialState, "editInc");
export const editIncidentImagesVar = makeVar([]);
export const createIncidentImagesVar = makeVar([]);

export const viewIncidentFiltersVar = makeVarPersisted(
  {
    searchterm: "",
    site: { owner: { name: "" } },
    afterDate: null,
    beforeDate: null,
    type: "",
    username: { username: "" },
  },
  "viewIncidentFilters"
);
