import { useEffect, useState } from "react";

import { ApolloClient, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import * as Sentry from "@sentry/react";
import { LocalForageWrapper, persistCache } from "apollo3-cache-persist";
import loggerLink from "apollo-link-logger";
import QueueLink from "apollo-link-queue";
import SerializingLink from "apollo-link-serialize";
import { createUploadLink } from "apollo-upload-client";
import localforage from "localforage";

import { fourFunc } from "../components/CustomComponents/401Call";
import { apiUrl } from "../config";
import { cache } from "./cache";

persistCache({
  cache,
  storage: new LocalForageWrapper(localforage),
  debug: true,
  maxSize: false,
});

const authLink = setContext((_, { headers }) => {
  return headers;
});
const httpLink = createUploadLink({
  uri: `${apiUrl}graphql/`,
  credentials: "include",
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, code }) => {
      // eslint-disable-next-line no-console
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      if (code === "unauthorized") {
        fourFunc();
      }
    });

  if (networkError) {
    if (`${networkError}`.includes("504")) {
      Sentry.captureMessage(`${operation.operationName} - ${networkError}`);
    }
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
  }
});

export const queueLink = new QueueLink();
const retryLink = new RetryLink({
  delay: {
    initial: 60000,
    max: 120000,
  },
  attempts: {
    max: Infinity,
    retryIf: (error, _operation) => {
      return (
        [
          "haCreate",
          "haEdit",
          "observationCreate",
          "observationEdit",
          "auditCreate",
          "auditEdit",
          "incidentCreate",
          "incidentEdit",
          "createAction",
          "createRisk",
          "createMitigator",
        ].includes(_operation.operationName) &&
        error.message === "Failed to fetch"
      );
    },
  },
});
const serializingLink = new SerializingLink();

export const useInitializeClient = () => {
  const [client, setClient] = useState();

  useEffect(() => {
    async function initializeCache() {
      const client = new ApolloClient({
        link: from([
          authLink,
          loggerLink,
          errorLink,
          queueLink,
          serializingLink,
          retryLink,
          httpLink,
          // trackerLink, // not currently working
        ]),
        cache,
        connectToDevTools: true,
        credentials: "include",
        defaultOptions: {
          query: {
            errorPolicy: "all",
          },
        },
      });

      // eslint-disable-next-line no-console
      client.onResetStore(() => console.log("Cache has been reset."));
      // eslint-disable-next-line no-console
      client.onClearStore(() => console.log("Cache has been cleared."));
      setClient(client);
    }
    initializeCache();
  }, []);

  return client;
};
