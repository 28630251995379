import React from "react";

import {
  Autocomplete,
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

import { useMutation, useLazyQuery } from "@apollo/client";
import { DropzoneDialog } from "mui-file-dropzone";

import { UPDATE_COMPANY } from "../../../graphql/mutations/admin/company";
import {
  GET_OWNER_SITES,
  GET_COMPANIES,
  GET_COMPANY,
  GET_ACTIVE_INDUSTRIES,
  GET_USERS,
} from "../../../graphql/queries/admin/company";
import { GET_SEARCH_TEMPLATES } from "../../../graphql/queries/ha";
import {
  onErrorFunc,
  onCompletedFunc,
} from "../../CustomComponents/OnErrorFunction";
import { CustomSwitch } from "../../CustomComponents/Switch";
import { StyledTab, StyledTabs } from "../../CustomComponents/Tabs";
import { CustomTextField } from "../../CustomStyles/LightTextField";
import { defaultAvatar } from "../../CustomStyles/avatar";
import { CustomDialog } from "../../CustomStyles/dialog";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import { TemplateAccordion, UserAccordion } from "./CompanyAccordions";
import ObservableListboxComponent from "./ObservableVirtualizedListBox";
import ListboxComponent from "./VirtualizedListBox";
import {
  COMPANY_ADMIN_CONTRACTED_SITES,
  COMPANY_ADMIN_TEMPLATES,
  COMPANY_ADMIN_USERS,
} from "./companyAdminQueries";

export default function EditCompany({ company }) {
  const [open, setOpen] = React.useState(false);

  // COMPANY DATA
  const [
    getCompanyUsers,
    { data: companyAdminUsers, loading: loadingCompanyAdminUsers },
  ] = useLazyQuery(COMPANY_ADMIN_USERS, {
    fetchPolicy: "network-only",
  });
  const [getCompanyContractors, { data: companyContractors }] = useLazyQuery(
    COMPANY_ADMIN_CONTRACTED_SITES,
    {
      fetchPolicy: "network-only",
    }
  );
  const [
    getCompanyTemplates,
    { data: companyAdminTemplates, loading: loadingCompanyAdminTemplates },
  ] = useLazyQuery(COMPANY_ADMIN_TEMPLATES, {
    fetchPolicy: "network-only",
  });

  // ALL DATA
  const [getIndustries, { data: industries }] = useLazyQuery(
    GET_ACTIVE_INDUSTRIES,
    {
      fetchPolicy: "network-only",
    }
  );
  const [getCompanies, { data: companies, loading: loadingCompanies }] =
    useLazyQuery(GET_COMPANIES, {
      fetchPolicy: "network-only",
    });
  const [getOwnerSites, { data: sites }] = useLazyQuery(GET_OWNER_SITES, {
    fetchPolicy: "network-only",
  });
  const [getUsers, { data: users, loading: loadingUsers }] = useLazyQuery(
    GET_USERS,
    {
      fetchPolicy: "network-only",
    }
  );
  const [getTemplates, { data: templates, loading: loadingTemplates }] =
    useLazyQuery(GET_SEARCH_TEMPLATES, {
      fetchPolicy: "network-only",
    });

  React.useEffect(() => {
    if (open) {
      // COMPANY DATA
      getCompanyUsers({ variables: { id: Number(company.id) } });
      getCompanyContractors({ variables: { id: Number(company.id) } });
      getCompanyTemplates({ variables: { id: Number(company.id) } });

      // ALL DATA
      getIndustries();
      getCompanies();
      getOwnerSites();
      getUsers();
      getTemplates();
    }
  }, [open]);

  const [editCompany] = useMutation(UPDATE_COMPANY, {
    onCompleted() {
      setOpen(false);
      onCompletedFunc("Company has been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [
      {
        query: GET_COMPANY,
        variables: {
          id: Number(company.id),
        },
        fetchPolicy: "network-only",
      },
      {
        query: COMPANY_ADMIN_USERS,
        variables: { id: Number(company.id) },
        fetchPolicy: "network-only",
      },
      {
        query: COMPANY_ADMIN_CONTRACTED_SITES,
        variables: { id: Number(company.id) },
        fetchPolicy: "network-only",
      },
      {
        query: COMPANY_ADMIN_TEMPLATES,
        variables: { id: Number(company.id) },
        fetchPolicy: "network-only",
      },
    ],
  });

  const [openUpload, setOpenUpload] = React.useState(false);

  const [logo, setLogo] = React.useState(company.logoBase64);
  const [isActive, setIsActive] = React.useState(company.isActive);
  const [name, setName] = React.useState(company.name);
  const [classification, setClassification] = React.useState(
    company.industry && company.industry.industrialClassifications.length > 0
      ? company.industry.industrialClassifications[0].classification
      : { name: "" }
  );
  const [industry, setIndustry] = React.useState({
    industry: company.industry,
  });
  const [riskThreshold, setRiskThreshold] = React.useState(
    company.riskThreshold
  );

  const [observable, setObservable] = React.useState(false);
  const [observableSites, setObservableSites] = React.useState([]);
  const [ownedParent, setOwnedParent] = React.useState(company.parent !== null);
  const [parent, setParent] = React.useState(company.parent);
  const [hasChild, setHasChild] = React.useState(company.child.length > 0);
  const [children, setChildren] = React.useState(company.child);

  const [companyUsers, setCompanyUsers] = React.useState([]);
  const [addedUsers, setAddedUsers] = React.useState([]);
  const [removedUsers, setRemovedUsers] = React.useState([]);
  const [supervisors, setSupervisors] = React.useState([]);

  const [companyTemplates, setCompanyTemplates] = React.useState([]);
  const [addedTemplates, setAddedTemplates] = React.useState([]);
  const [removedTemplates, setRemovedTemplates] = React.useState([]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (companyAdminUsers) {
      setCompanyUsers(companyAdminUsers.users);
      setSupervisors(
        companyAdminUsers.users
          .filter(
            (u) =>
              u.supervise.findIndex(
                (s) =>
                  s.ownerSiteProject &&
                  s.ownerSiteProject.owner &&
                  s.ownerSiteProject.owner.id === company.id &&
                  !s.ownerSiteProject.site
              ) >= 0
          )
          .map((u) => Number(u.id))
      );
    }
  }, [companyAdminUsers]);

  React.useEffect(() => {
    if (companyContractors) {
      setObservable(
        companyContractors.ownerSiteProjectContractors.filter(
          (ospc) => ospc.ownerSiteProject?.site
        ).length > 0
      );
      setObservableSites(
        companyContractors.ownerSiteProjectContractors
          .filter(
            (ospc) =>
              ospc.ownerSiteProject.site && !ospc.ownerSiteProject.project
          )
          .map((ospc) => ({
            ...ospc.ownerSiteProject,
          }))
      );
    }
  }, [companyContractors]);

  React.useEffect(() => {
    if (companyAdminTemplates) {
      setCompanyTemplates(
        companyAdminTemplates.ownerSiteProjectTemplates.filter(
          (t) => !t.ownerSiteProject.site
        )
      );
    }
  }, [companyAdminTemplates]);

  React.useEffect(() => {
    if (company) {
      setIsActive(company.isActive);
    }
  }, [company]);

  const getBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
  };

  const onSave = () => {
    if (logo.type) {
      getBase64(logo, function (base64Data) {
        editCompany({
          variables: {
            isActive,
            id: Number(company.id),
            parent: parent ? Number(parent.id) : null,
            industry:
              industry && industry.industry
                ? Number(industry.industry.id)
                : null,
            name,
            rt: Number(riskThreshold),
            logo: base64Data.split(",")[1],
            isContractor: observable,
            childCompanies: children.map((c) => Number(c.id)),
            ownerSiteProject: observable
              ? observableSites.map((c) => Number(c.id))
              : [],
            supervisors,
            associatedUsers: [...companyUsers, ...addedUsers].map((u) =>
              Number(u.id)
            ),
            unassociatedUsers: removedUsers.map((u) => Number(u.id)),
            associatedTemplate: [
              ...companyTemplates.map((t) => t.template),
              ...addedTemplates,
            ].map((t) => Number(t.id)),
            unassociatedTemplate: removedTemplates.map((t) => Number(t.id)),
          },
        });
      });
    } else {
      editCompany({
        variables: {
          isActive,
          id: Number(company.id),
          parent: parent ? Number(parent.id) : null,
          industry:
            industry && industry.industry ? Number(industry.industry.id) : null,
          name,
          rt: Number(riskThreshold),
          logo,
          isContractor: observable,
          childCompanies: children.map((c) => Number(c.id)),
          ownerSiteProject: observable
            ? observableSites.map((c) => Number(c.id))
            : [],
          supervisors,
          associatedUsers: [...companyUsers, ...addedUsers].map((u) =>
            Number(u.id)
          ),
          unassociatedUsers: removedUsers.map((u) => Number(u.id)),
          associatedTemplate: [
            ...companyTemplates.map((t) => t.template),
            ...addedTemplates,
          ].map((t) => Number(t.id)),
          unassociatedTemplate: removedTemplates.map((t) => Number(t.id)),
        },
      });
    }
  };

  const onCancel = () => {
    setOpen(false);
    setLogo(company.logoBase64);
    setName(company.name);
    setClassification(
      company.industry &&
        company.industry.industrialClassifications &&
        company.industry.industrialClassifications.length > 0
        ? company.industry.industrialClassifications[0].classification
        : { name: "" }
    );
    setIndustry({ industry: company.industry });
    setOwnedParent(company.parent !== null);
    setParent(company.parent);
    setHasChild(company.child.length > 0);
    setChildren(company.child);

    setAddedUsers([]);
    setRemovedUsers([]);

    setAddedTemplates([]);
    setRemovedTemplates([]);
  };

  const handleSave = (photo) => {
    setOpenUpload(false);
    setLogo(photo[0]);
  };

  const isImage = (file) => {
    if (file.type && file.type.split("/")[0] === "image") {
      return true;
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="yellow0"
        sx={{ ml: 3 }}
        onClick={() => setOpen(true)}
      >
        EDIT COMPANY
      </Button>
      <CustomDialog
        open={open}
        fullWidth
        maxWidth="md"
        sx={{ color: "#333" }}
        onClose={() => setOpen(false)}
      >
        <DialogTitle style={{ ...pageTitleStyles }}>EDIT COMPANY.</DialogTitle>
        <DialogContent>
          <FormControlLabel
            style={{ color: "white" }}
            control={
              <CustomSwitch
                checked={isActive}
                onChange={(event) => setIsActive(event.target.checked)}
                light
              />
            }
            label={isActive ? "ACTIVE" : "INACTIVE"}
          />
          <Grid container>
            <Grid
              item
              xs={3}
              container
              direction="column"
              alignItems="center"
              style={{ margin: "20px 0px" }}
            >
              <Avatar
                alt={`${name}`}
                src={
                  isImage(logo) && logo.type
                    ? URL.createObjectURL(logo)
                    : `data:image/jpg;base64,${logo}`
                }
                sx={{
                  ...defaultAvatar,
                  width: "75px",
                  margin: "auto",
                  height: "75px",
                  fontSize: "2.5rem",
                  marginBottom: "20px",
                }}
              />
              <Button
                variant="contained"
                color="yellow0"
                onClick={() => setOpenUpload(true)}
              >
                CHANGE LOGO
              </Button>
              <DropzoneDialog
                open={openUpload}
                onSave={handleSave}
                acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                showPreviews={true}
                filesLimit={1}
                onClose={() => setOpenUpload(false)}
                maxFileSize={10000000} // 10MB
              />
            </Grid>
            <Grid item xs={9} container alignItems="center">
              <CustomTextField
                label="Company Name:"
                variant="standard"
                style={{ margin: "10px 0px", width: "75%", color: "#fff" }}
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </Grid>
            <Autocomplete
              disablePortal
              id="select-classification"
              options={industries ? industries.classifications : []}
              value={classification}
              onChange={(event, value) => {
                setClassification(value);
                setIndustry({ industry: { name: "", code: "" } });
              }}
              getOptionLabel={(option) => option.name}
              disableClearable
              style={{ width: "45%", marginRight: "10px" }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  style={{ color: "#fff" }}
                  variant="standard"
                  label={"Classification:"}
                />
              )}
            />
            <Autocomplete
              disablePortal
              id="select-industry"
              options={
                classification.industrialClassifications
                  ? classification.industrialClassifications
                  : []
              }
              value={industry}
              onChange={(event, value) => setIndustry(value)}
              getOptionLabel={(option) =>
                option.industry && option.industry.name !== ""
                  ? `${option.industry.code} - ${option.industry.name}`
                  : ""
              }
              disableClearable
              style={{ width: "45%" }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  style={{ color: "#fff" }}
                  variant="standard"
                  label={"Industry:"}
                />
              )}
              disabled={classification.name === ""}
            />
            <CustomTextField
              label="Risk Threshold:"
              variant="standard"
              style={{ margin: "10px 0px", width: "75%", color: "#fff" }}
              value={riskThreshold}
              onChange={(event) => setRiskThreshold(event.target.value)}
            />
            <Grid
              item
              container
              alignItems="center"
              style={{ marginTop: "20px" }}
            >
              <Typography>Is observable?</Typography>
              <FormControlLabel
                style={{ color: "white", marginLeft: "10px" }}
                control={
                  <CustomSwitch
                    checked={observable}
                    onChange={(event) => setObservable(event.target.checked)}
                    light
                  />
                }
                label={observable ? "YES" : "NO"}
              />
            </Grid>
            {observable && (
              <Autocomplete
                id="select-observable-sites"
                limitTags={3}
                options={
                  sites
                    ? sites.ownerSiteProjects
                        .filter((osp) => osp.site)
                        .sort(
                          (a, b) =>
                            a.owner.name.localeCompare(b.owner.name) ||
                            a.site.name.localeCompare(b.site.name)
                        )
                    : []
                }
                value={observableSites}
                onChange={(event, value) => setObservableSites(value)}
                getOptionLabel={(option) =>
                  `${option.site?.name} [${option.owner?.name}]`
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disableClearable
                style={{ width: "75%" }}
                ListboxComponent={ObservableListboxComponent}
                multiple
                renderOption={(props, option, state) => [props, option, state]}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    style={{ color: "#fff" }}
                    variant="standard"
                    label={"Observable Sites:"}
                  />
                )}
              />
            )}
            <Grid
              item
              container
              alignItems="center"
              style={{ marginTop: "20px" }}
            >
              <Typography>Owned by a parent company?</Typography>
              <FormControlLabel
                style={{ color: "white", marginLeft: "10px" }}
                control={
                  <CustomSwitch
                    checked={ownedParent}
                    onChange={(event) => setOwnedParent(event.target.checked)}
                    light
                  />
                }
                label={ownedParent ? "YES" : "NO"}
              />
            </Grid>
            {ownedParent && (
              <Autocomplete
                id="select-parent-company"
                // not current company and not any of the children
                options={
                  companies
                    ? [...companies.companies]
                        .filter(
                          (c) =>
                            c.id !== company.id &&
                            children.findIndex((child) => child.id === c.id) < 0
                        )
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                    : []
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={parent}
                onChange={(event, value) => setParent(value)}
                getOptionLabel={(option) => option.name}
                disableClearable
                loading={loadingCompanies}
                ListboxComponent={ListboxComponent}
                renderOption={(props, option, state) => [props, option, state]}
                style={{ width: "75%" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    variant="standard"
                    label={"Parent Company:"}
                  />
                )}
              />
            )}
            <Grid
              item
              container
              alignItems="center"
              style={{ marginTop: "20px" }}
            >
              <Typography>Owns a child company?</Typography>
              <FormControlLabel
                style={{ color: "white", marginLeft: "10px" }}
                control={
                  <CustomSwitch
                    checked={hasChild}
                    onChange={(event) => setHasChild(event.target.checked)}
                    light
                  />
                }
                label={hasChild ? "YES" : "NO"}
              />
            </Grid>
            {hasChild && (
              <Autocomplete
                id="select-child-companies"
                limitTags={3}
                // not current company or parent or company that already has parent
                options={
                  companies
                    ? [...companies.companies]
                        .filter(
                          (c) =>
                            c.id !== company.id &&
                            parent?.id !== c.id &&
                            !c.parent
                        )
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                    : []
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={children}
                onChange={(event, value) => setChildren(value)}
                getOptionLabel={(option) => option.name}
                disableClearable
                style={{ width: "75%" }}
                multiple
                loading={loadingCompanies}
                ListboxComponent={ListboxComponent}
                renderOption={(props, option, state) => [props, option, state]}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    variant="standard"
                    label={"Child Companies:"}
                  />
                )}
              />
            )}
          </Grid>
          <Paper sx={{ mt: 2, p: 1 }}>
            <StyledTabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
            >
              <StyledTab label="USERS" />
              <StyledTab label="TEMPLATES" />
            </StyledTabs>
            <UserAccordion
              value={value}
              companyUsers={companyUsers}
              setCompanyUsers={setCompanyUsers}
              addedUsers={addedUsers}
              setAddedUsers={setAddedUsers}
              setRemovedUsers={setRemovedUsers}
              users={users}
              supervisors={supervisors}
              setSupervisors={setSupervisors}
              loadingCompanyAdminUsers={loadingCompanyAdminUsers}
              loadingUsers={loadingUsers}
            />
            <TemplateAccordion
              value={value}
              companyTemplates={companyTemplates}
              setCompanyTemplates={setCompanyTemplates}
              addedTemplates={addedTemplates}
              setAddedTemplates={setAddedTemplates}
              setRemovedTemplates={setRemovedTemplates}
              templates={templates}
              loadingCompanyAdminTemplates={loadingCompanyAdminTemplates}
              loadingTemplates={loadingTemplates}
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="yellow0"
            sx={{ mr: 1 }}
            onClick={onSave}
          >
            SAVE
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={onCancel}
          >
            CANCEL
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
}
