import React from "react";

import {
  Grid,
  Typography,
  FormControl,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material";

import { useMutation, useQuery, useReactiveVar } from "@apollo/client";

import { onlineVar } from "../../../graphql/localVariables/user";
import { authMutations } from "../../../graphql/mutations";
import { BATCH_CREATE_USER } from "../../../graphql/mutations/userSettings";
import { GET_COMPANIES } from "../../../graphql/queries";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import { checkAccountInfo } from "./CreateNewUser";

export default function BatchUserCreation() {
  const { updateSnackbar } = authMutations;
  const [batchCreateUsers] = useMutation(BATCH_CREATE_USER, {
    onCompleted() {
      onCompletedFunc("Users successfully created");
      setNewUsers([]);
    },
    onError(error) {
      onErrorFunc(error);
    },
  });
  const online = useReactiveVar(onlineVar);
  const { data } = useQuery(GET_COMPANIES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [company, setCompany] = React.useState({ name: "" });
  const [newUsers, setNewUsers] = React.useState([]);

  const batchUserCreation = () => {
    if (newUsers.length > 0) {
      batchCreateUsers({
        variables: {
          bulkUser: newUsers.map((user) => ({
            username: user.username.toLowerCase(),
            email: user.email,
            companyId: Number(user.company.id),
          })),
        },
      });
    }
  };

  const addUser = () => {
    if (
      checkAccountInfo(
        username,
        "p1234567",
        "p1234567",
        email,
        company,
        onErrorFunc
      )
    ) {
      if (
        newUsers.findIndex((u) => u.username === username) >= 0 ||
        newUsers.findIndex((u) => u.email === email) >= 0
      ) {
        updateSnackbar({
          open: true,
          message:
            "Username and/or email is already taken by one of your created users.",
          severity: "error",
        });
      } else {
        setNewUsers((prev) => [...prev, { username, email, company }]);
        setUsername("");
        setEmail("");
      }
    }
  };

  const NewUser = ({ user }) => {
    const removeUser = () => {
      setNewUsers((prev) => prev.filter((u) => u.username !== user.username));
    };

    return (
      <Grid
        container
        direction="column"
        item
        xs={3}
        style={{
          border: "1px solid black",
          borderRadius: "4px",
          padding: "10px",
          margin: "10px",
        }}
      >
        <Typography>Username: {user.username}</Typography>
        <Typography>Email: {user.email}</Typography>
        <Typography>Company: {user.company.name}</Typography>
        <Button
          onClick={removeUser}
          variant="contained"
          color="primary"
          sx={{ mt: 1 }}
        >
          REMOVE
        </Button>
      </Grid>
    );
  };

  return (
    <FormControl fullWidth>
      <TextField
        variant="standard"
        label="Username:"
        value={username}
        onChange={(event) => setUsername(event.target.value)}
      />
      <Typography>
        150 characters or fewer. Letters, digits and @/./+/-/_ only.
      </Typography>
      <TextField
        variant="standard"
        label="Email Address:"
        type="email"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />
      <Grid container alignItems="center">
        <Grid item xs={11}>
          <Autocomplete
            disablePortal
            id="batch-users-company"
            options={
              data
                ? [...data.companies].sort((a, b) => (a.name > b.name ? 1 : -1))
                : []
            }
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => setCompany(newValue)}
            disableClearable
            renderInput={(params) => (
              <TextField {...params} variant="standard" label="Company:" />
            )}
            sx={{
              mb: 2,
            }}
          />
        </Grid>
        <Grid item xs>
          <Button
            onClick={addUser}
            sx={{
              width: "90%",
              ml: 2,
            }}
            variant="contained"
            color="secondary"
          >
            ADD
          </Button>
        </Grid>
      </Grid>
      <Typography
        sx={{
          mt: 3,
          fontSize: "1.1rem",
          fontWeight: "bold",
        }}
      >
        ADDED USERNAMES:
      </Typography>
      <Grid container>
        {newUsers.map((user) => (
          <NewUser key={user.username} user={user} />
        ))}
      </Grid>
      <Grid container sx={{ mt: 1 }}>
        <Button
          variant="contained"
          color="secondary"
          sx={{ mr: 1 }}
          onClick={batchUserCreation}
        >
          CREATE ALL
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setNewUsers([])}
        >
          CANCEL
        </Button>
      </Grid>
    </FormControl>
  );
}
