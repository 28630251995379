import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, CircularProgress, Grid, Paper } from "@mui/material";

import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";

import { permissionsVar } from "../../../graphql/localVariables/user";
import { authMutations } from "../../../graphql/mutations";
import {
  GET_ACTIVE_ARMS_ADMIN,
  GET_CATEGORIES,
  GET_COMPANY_ARMS_ADMIN,
  GET_GLOBAL_MIT,
  GET_GLOBAL_RISKS,
} from "../../../graphql/queries/admin/arm";
import { GET_COMPANY } from "../../../graphql/queries/admin/company";
import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import removeDuplicates from "../../../utils/removeDuplicates";
import TabPanel from "../../CustomComponents/TabPanels";
import { StyledTab, StyledTabs } from "../../CustomComponents/Tabs";
import Layout from "../../Layout";
import CARMTable from "./CARMTable";
import CompanySelect from "./CompanySelect";
import CreateARM from "./CreateARM";
import Merge from "./Merge";
import Share from "./Share";

export default function ARMAdmin() {
  const navigate = useNavigate();
  const permissions = useReactiveVar(permissionsVar);
  const { updateSnackbar } = authMutations;
  const { companyId } = useParams();
  const [getCompany, { data: company }] = useLazyQuery(GET_COMPANY, {
    fetchPolicy: "network-only",
  });
  const { data: currentUser } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: "network-only",
  });

  // Queries for tables
  const { data: categories, loading: loadingCategories } = useQuery(
    GET_CATEGORIES,
    {
      fetchPolicy: "network-only",
    }
  );
  const [getCompanyActions, { data: actions, loading: loadingAction }] =
    useLazyQuery(GET_COMPANY_ARMS_ADMIN, {
      fetchPolicy: "network-only",
    });
  const [getCompanyRisks, { data: risks }] = useLazyQuery(
    GET_COMPANY_ARMS_ADMIN,
    {
      fetchPolicy: "network-only",
    }
  );
  const [getGlobalRisks, { data: globalRisks, loading: loadingGlobalRisk }] =
    useLazyQuery(GET_GLOBAL_RISKS, {
      fetchPolicy: "network-only",
    });
  const [getGlobalMit, { data: globalMit, loading: loadingGlobalMit }] =
    useLazyQuery(GET_GLOBAL_MIT, {
      fetchPolicy: "network-only",
    });

  const { data: activeARMs, loading: loadingActiveARMs } = useQuery(
    GET_ACTIVE_ARMS_ADMIN,
    {
      fetchPolicy: "network-only",
    }
  );

  const [openCreate, setOpenCreate] = React.useState(false);
  const [openMerge, setOpenMerge] = React.useState(false);
  const [openShare, setOpenShare] = React.useState(false);

  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if (
      permissions.length === 0 ||
      (permissions.length > 0 &&
        !permissions.includes("VIEW_CARM_ADMINISTRATION"))
    ) {
      navigate("/");
    }
  }, []);

  const isSiteAdmin = currentUser && currentUser.currentUser.isStaff;

  React.useEffect(() => {
    if (isSiteAdmin) {
      getGlobalRisks();
      getGlobalMit();
    }
  }, [currentUser]);

  React.useEffect(() => {
    if (currentUser) {
      getCompany({
        variables: {
          id: companyId
            ? Number(companyId)
            : Number(currentUser.currentUser.company?.id),
        },
      });

      const idToUse = companyId
        ? Number(companyId)
        : Number(currentUser.currentUser.company?.id);

      getCompanyActions({
        variables: {
          companyId: Number(idToUse),
          rNull: true,
          mNull: true,
        },
      });
      getCompanyRisks({
        variables: {
          companyId: Number(idToUse),
          rNull: false,
          mNull: true,
        },
      });
    }
  }, [currentUser, companyId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const categoriesArr =
    categories && categories.categories ? categories.categories : [];
  const actionsArr =
    actions && actions.companyArms
      ? removeDuplicates(actions.companyArms, "arm", "id")
      : [];
  const risksArr = globalRisks ? globalRisks.risks : [];
  const mitigatorsArr = globalMit ? globalMit.mitigators : [];

  const includesCategory =
    permissions.includes("CATEGORY") ||
    permissions.includes("VIEW_CATEGORY") ||
    permissions.includes("EDIT_CATEGORY");

  return (
    <Layout>
      <Grid
        style={{
          height: "100%",
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <CompanySelect company={company} setOpenCreate={setOpenCreate} />
        </Grid>
        <Grid container style={{ margin: "20px 0px" }}>
          {permissions.includes("APPROVE_ARM") && (
            <Button
              variant="contained"
              color="secondary"
              sx={{ mr: 1 }}
              onClick={() =>
                navigate(
                  `/admin/arm/${
                    companyId ? `${companyId}/approvals` : "approvals"
                  }`,
                  { relative: "path" }
                )
              }
            >
              APPROVALS
            </Button>
          )}
          {permissions.includes("EDIT_ARM_RISK_SCORES") && (
            <Button
              variant="contained"
              color="secondary"
              sx={{ mr: 1 }}
              onClick={() =>
                navigate("/admin/arm/unscored", { relative: "path" })
              }
            >
              UNSCORED
            </Button>
          )}
          {includesCategory && (
            <Button
              variant="contained"
              color="primary"
              sx={{ mr: 1 }}
              onClick={() => setOpenShare(true)}
            >
              ARM SHARE
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            sx={{ mr: 1 }}
            onClick={() =>
              updateSnackbar({
                severity: "info",
                message: "This feature is still under development.",
                open: true,
              })
            }
          >
            ARM MERGE
          </Button>
        </Grid>
        <Paper style={{ padding: "20px" }}>
          <StyledTabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            style={{ color: "white" }}
          >
            {includesCategory && <StyledTab label="CATEGORIES" />}
            <StyledTab label="ARMs by ACTION" />
            {isSiteAdmin && <StyledTab label="GLOBAL RISKS" />}
            {isSiteAdmin && <StyledTab label="GLOBAL MITIGATORS" />}
          </StyledTabs>
          {includesCategory && (
            <TabPanel value={value} index={0}>
              {loadingCategories ? (
                <Grid container justifyContent="center">
                  <CircularProgress color="secondary" />
                </Grid>
              ) : (
                <CARMTable data={categoriesArr} type="Categories" />
              )}
            </TabPanel>
          )}
          <TabPanel value={value} index={includesCategory ? 1 : 0}>
            {loadingAction ? (
              <Grid container style={{ width: "100%" }} justifyContent="center">
                <CircularProgress color="secondary" />
              </Grid>
            ) : (
              <CARMTable
                data={actionsArr}
                type="Actions"
                loadingActiveARMs={loadingActiveARMs}
                activeARMs={activeARMs}
                company={company}
              />
            )}
          </TabPanel>
          {isSiteAdmin && (
            <TabPanel value={value} index={includesCategory ? 2 : 1}>
              {loadingGlobalRisk ? (
                <Grid
                  container
                  style={{ width: "100%" }}
                  justifyContent="center"
                >
                  <CircularProgress color="secondary" />
                </Grid>
              ) : (
                <CARMTable data={risksArr} type="Risks" />
              )}
            </TabPanel>
          )}
          {isSiteAdmin && (
            <TabPanel value={value} index={includesCategory ? 3 : 2}>
              {loadingGlobalMit ? (
                <Grid
                  container
                  style={{ width: "100%" }}
                  justifyContent="center"
                >
                  <CircularProgress color="secondary" />
                </Grid>
              ) : (
                <CARMTable data={mitigatorsArr} type="Mitigators" />
              )}
            </TabPanel>
          )}
        </Paper>
        {currentUser && (
          <CreateARM
            open={openCreate}
            handleClose={() => setOpenCreate(false)}
            user={currentUser}
            categories={categories}
            actions={actions}
            risks={risks}
            currentUser={currentUser}
            companySelected={companyId}
          />
        )}
        <Merge open={openMerge} handleClose={() => setOpenMerge(false)} />
        <Share
          open={openShare}
          handleClose={() => setOpenShare(false)}
          currentUser={currentUser}
        />
      </Grid>
    </Layout>
  );
}
