import React from "react";
import { useNavigate } from "react-router-dom";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
} from "@mui/material";

import { useQuery, useMutation, useReactiveVar } from "@apollo/client";
import moment from "moment";

import {
  createIncidentVar,
  editIncidentVar,
  createIncidentImagesVar,
  editIncidentImagesVar,
  initialState,
} from "../../../graphql/localVariables/investigation";
import { onlineVar } from "../../../graphql/localVariables/user";
import {
  investigationMutations,
  authMutations,
} from "../../../graphql/mutations";
import { GET_INVESTIGATION_FORM } from "../../../graphql/queries/incidentInvestigations";
import removeDuplicates from "../../../utils/removeDuplicates";
import { onErrorFunc } from "../../CustomComponents/OnErrorFunction";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import General from "./General";
import IncidentDetails from "./IncidentDetails";
import QuestionType from "./QuestionTypes";
import AddFiles from "./QuestionTypes/Notes";

export default function InvestigationForm({ type }) {
  const online = useReactiveVar(onlineVar);
  const navigate = useNavigate();

  // Topics and Questions
  const { data, loadingForm } = useQuery(GET_INVESTIGATION_FORM, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  // Global Variables
  const editIncident = useReactiveVar(editIncidentVar);
  const createIncident = useReactiveVar(createIncidentVar);
  const form = type === "EDIT" ? editIncident : createIncident;

  const editIncidentImages = useReactiveVar(editIncidentImagesVar);
  const createIncidentImages = useReactiveVar(createIncidentImagesVar);
  const updateIncidentImages =
    type === "EDIT" ? editIncidentImages : createIncidentImages;

  // Mutations
  const {
    updateKeyEditIncident,
    updateKeyCreateIncident,
    updateAllCreateIncident,
    updateAllEditIncident,
    INCIDENT_CREATE,
    INCIDENT_EDIT,
    updateCreateIncidentImages,
    updateEditIncidentImages,
  } = investigationMutations;
  const updateKeyFunction =
    type === "EDIT" ? updateKeyEditIncident : updateKeyCreateIncident;
  const { updateSnackbar, updateLoading } = authMutations;
  const updateIncidentImagesFunction =
    type === "EDIT" ? updateEditIncidentImages : updateCreateIncidentImages;
  // mutation to create Incident
  const [createIncidentMutation, { loading: loadingCreate }] = useMutation(
    INCIDENT_CREATE,
    {
      onCompleted(data) {
        if (data.incidentCreate.incidentReport) {
          updateSnackbar({
            open: true,
            message: "Incident investigation was sent successfully!",
            severity: "success",
          });
          navigate(`/view-incident/${data.incidentCreate.incidentReport.id}`);
          updateAllCreateIncident(initialState);
          updateCreateIncidentImages([]);
        } else {
          onErrorFunc(
            "There was an error when creating your incident investigation."
          );
        }
      },
      onError(error) {
        onErrorFunc(error);
      },
    }
  );

  // Mutation to edit Incident
  const [editIncidentMutation, { loading: loadingEdit }] = useMutation(
    INCIDENT_EDIT,
    {
      onCompleted(data) {
        if (data.incidentEdit.incidentReport) {
          updateSnackbar({
            open: true,
            message: "Incident investigation was edited successfully!",
            severity: "success",
          });
          navigate(`/view-incident/${data.incidentEdit.incidentReport.id}`);
          updateAllEditIncident(initialState);
        } else {
          onErrorFunc(
            "There was an error when editing your incident investigation."
          );
        }
      },
      onError(error) {
        onErrorFunc(error);
      },
    }
  );

  // Accordion variables and functions
  const [expanded, setExpanded] = React.useState(false);
  const [currentSection, setCurrentSection] = React.useState(1);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const checkNextSection = () => {
    if (
      currentSection === 1 &&
      (form.type === "" || !form.employeeDOB || form.employeePhoneNumber === "")
    ) {
      onErrorFunc("Please select incident type and involved employee.");
    } else if (currentSection === 2 && form.type !== "Injury") {
      setCurrentSection((prev) => prev + 2);
      setExpanded((prev) => prev + 2);
    } else {
      setCurrentSection((prev) => prev + 1);
      setExpanded((prev) => prev + 1);
    }
  };

  // Update incidentQuestionResponses State
  const updateState = (value) => {
    // If id exists in responses remove
    const filteredArray = form.incidentQuestionResponses.filter((question) => {
      return question.incidentQuestion?.id !== value.incidentQuestion.id;
    });

    // Add new question/answer to state
    updateKeyFunction("incidentQuestionResponses", [...filteredArray, value]);
  };

  const formatQuestionResponses = () => {
    return form.incidentQuestionResponses.map((r) => {
      if (r.multipleChoice) {
        return {
          incidentQuestion: Number(r.incidentQuestion.id),
          options: [Number(r.multipleChoice)],
        };
      } else if (r.checkbox) {
        return {
          incidentQuestion: Number(r.incidentQuestion.id),
          options: r.options ? r.options.map((o) => Number(o.option?.id)) : [],
        };
      } else if (r.boolean) {
        return {
          incidentQuestion: Number(r.incidentQuestion.id),
          boolean: r.boolean === "true" || r.boolean === true ? true : false,
        };
      } else {
        return {
          date: r.date,
          dateRanges: r.dateRanges
            ? r.dateRanges.map((dr) => ({
                startDate: dr.startDate,
                endDate: dr.endDate,
              }))
            : [],
          textBox: r.textBox,
          textInput: r.textInput,
          time: r.time,
          incidentQuestion: Number(r.incidentQuestion.id),
          ...(r.options
            ? {
                options: r.options.map((o) =>
                  Number(o.option ? o.option.id : o)
                ),
              }
            : null),
        };
      }
    });
  };

  // TODO more thorough check before submit
  const checkComplete = () => {
    return form.employeeDOB && form.employeePhoneNumber;
  };

  function groupBy(objectArray, property) {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
    }, {});
  }

  const formatFiles = (files) => {
    let newArray = [];
    const groupedFiles = groupBy(files, "topicId");
    Object.entries(groupedFiles).forEach(([key, value]) => {
      const topicId = key;
      value.forEach((file, index) => {
        if (file.file.name) {
          const newName = file.file.name.split(".").pop();
          const newFile = new File(
            [file.file],
            `topic_${topicId}_${index}.${newName}`,
            {
              type: file.file.type,
            }
          );
          newArray.push(newFile);
        }
      });
    });

    return newArray;
  };

  // Submit the Incident and send mutation
  const submit = () => {
    // Payload for mutation
    if (checkComplete()) {
      const fileTopics = updateIncidentImages.map((i) => Number(i.topicId));

      const formattedResponses = form.incidentTopicResponses.map((r) => {
        return {
          incidentTopic: Number(r.incidentTopic.id),
          note: r.note,
        };
      });

      const fileOnlyResponses = removeDuplicates(
        fileTopics
          .filter(
            (t) =>
              formattedResponses.findIndex((r) => r.incidentTopic === t) < 0
          )
          .map((t) => ({ incidentTopic: t })),
        "incidentTopic"
      );

      const input = {
        incident: 1,
        arms: [],
        ha: form.ha ? Number(form.ha.haId) : null,
        type: form.type,
        ownerSiteProject: Number(form.ospId),
        date: moment(form.date).format("YYYY-MM-DD"),
        time: moment(form.time).format("HH:mm"),
        employeePhoneNumber: form.employeePhoneNumber,
        employeeName: form.employeeName.name
          ? form.employeeName.name
          : form.employeeName,
        employeeJobTitle: form.employeeJobTitle.name
          ? form.employeeJobTitle.name
          : form.employeeJobTitle,
        employeeDob: moment(form.employeeDOB).format("YYYY-MM-DD"),
        injuryDate: moment(form.injuryDate).format("YYYY-MM-DD"),
        injuryTime: moment(form.injuryTime).format("HH:mm"),
        topicResponses: [...formattedResponses, ...fileOnlyResponses],
        questionResponses: formatQuestionResponses(),
        files: formatFiles(updateIncidentImages),
      };

      // Notify user if not online
      if (!online) {
        updateSnackbar({
          open: true,
          message:
            "Your device is offline. Your incident investigation will be saved when you are back online.",
          severity: "info",
        });
      }

      // Edit submission
      if (type === "EDIT") {
        // Add a few additional fields to edit input
        editIncidentMutation({
          variables: {
            id: Number(form.id),
            input: {
              ...input,
              removeTopicFiles: form.removeFiles,
              isCompleted: false,
              removeQuestionFiles: [],
            },
          },
        });
        // Create submission
      } else {
        createIncidentMutation({
          variables: { input },
        });
      }
    } else {
      onErrorFunc("Fill out complete employee information before submitting.");
    }
  };

  // Show loading wheel for edit call
  React.useEffect(() => {
    updateLoading(loadingEdit);
  }, [loadingEdit]);

  // Show loading wheel for create call
  React.useEffect(() => {
    updateLoading(loadingCreate);
  }, [loadingCreate]);

  // Show loading wheel for loading form
  React.useEffect(() => {
    updateLoading(loadingForm);
  }, [loadingForm]);

  // clear form to initial state
  const clearForm = () => {
    setCurrentSection(1);
    setExpanded(1);
    updateAllCreateIncident(initialState);
    updateIncidentImagesFunction([]);
  };

  // Render accordion topic
  const renderTopic = (topic, index) => {
    if (topic.name === "General") {
      return null;
    } else if (
      topic.name === "Incident Classification - US (OSHA)" &&
      form.type !== "Injury"
    ) {
      return null;
    } else {
      return (
        <Accordion
          expanded={expanded === index + 1}
          onChange={handleChange(index + 1)}
          disableGutters
          key={index + 2}
          disabled={currentSection < index + 1 && type !== "EDIT"}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${topic.name}-content`}
            id={`${topic.name}-header`}
          >
            <Typography>{topic.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row">
              {topic.incidentQuestions.map((q) => (
                <QuestionType
                  key={`question-${q.id}`}
                  question={q}
                  type={type}
                  updateState={updateState}
                />
              ))}
            </Grid>
            <Grid container direction="row">
              <AddFiles topic={topic} type={type} />
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      style={{
        height: "100%",
      }}
    >
      <Grid container style={{ maxWidth: "1000px" }}>
        <Grid item>
          <Typography sx={pageTitleStyles}>INCIDENT INVESTIGATION.</Typography>
        </Grid>
        {data?.incident?.incidentTopics ? (
          <Grid
            item
            xs={12}
            style={{
              overflowY: "auto",
              borderRadius: "5px",
              backgroundColor: "white",
            }}
          >
            <Accordion
              expanded={expanded === 1}
              onChange={handleChange(1)}
              disableGutters
              disabled={currentSection < 1 && type !== "EDIT"}
              key={1}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="general-content"
                id="general-header"
              >
                <Typography>General Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <General type={type} />
                <IncidentDetails type={type} />
                <Grid container direction="row">
                  {data.incident.incidentTopics
                    .find((t) => t.name === "General")
                    ?.incidentQuestions.map((q) => (
                      <QuestionType
                        key={`question-${q.id}`}
                        question={q}
                        type={type}
                        updateState={updateState}
                      />
                    ))}
                </Grid>
                <Grid container direction="row">
                  <AddFiles
                    topic={{ id: 1, name: "General Information" }}
                    type={type}
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>
            {data?.incident.incidentTopics.map((topic, index) =>
              renderTopic(topic, index)
            )}
          </Grid>
        ) : null}
      </Grid>
      <Grid
        container
        justifyContent="flex-end"
        sx={{
          position: "fixed",
          bottom: { xs: 100, md: 20 },
          width: { xs: "calc(100% - 40px)", md: "calc(100% - 200px)" },
        }}
      >
        <Grid item container justifyContent="space-between">
          {type === "CREATE" ? (
            <Button variant="contained" color="primary" onClick={clearForm}>
              CLEAR
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                navigate("/view-incident");
              }}
            >
              CANCEL
            </Button>
          )}
          {currentSection === data?.incident?.incidentTopics?.length ||
          type === "EDIT" ? (
            <Button
              variant="contained"
              color="yellow0"
              sx={{ mr: 1 }}
              onClick={submit}
            >
              SUBMIT
            </Button>
          ) : (
            <Button
              variant="contained"
              color="yellow0"
              sx={{ mr: 1 }}
              onClick={checkNextSection}
            >
              NEXT
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
