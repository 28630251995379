import React from "react";
import { useNavigate } from "react-router-dom";

import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Grid, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

import useStyles from "./styles";

function DashboardContent({ title, subtitle, score, unit, link }) {
  const navigate = useNavigate();
  const classes = useStyles()();

  let countElement = Math.round(score);

  if (Number.isNaN(score)) {
    countElement = "N/A";
  }
  let dollarSign = "";
  let unitStr = "";
  if (unit) {
    dollarSign = "$";
    unitStr = unit;
  }
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.contentRoot}
    >
      <Grid item xs={12}>
        <AddCircleRoundedIcon
          className={classes.cardAddIcon}
          onClick={() => navigate(link)}
        />
      </Grid>
      <Grid item xs={12} style={{ width: "100%" }}>
        <Typography
          className={classes.cardTitle}
          sx={{
            fontFamily: "barlowextrabold",
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={6} style={{ alignSelf: "center", width: "50%" }}>
        <Typography
          className={classes.cardSubtitle}
          sx={{ fontFamily: "barlowextrabold", fontSize: "0.65rem" }}
        >
          {subtitle}
        </Typography>
      </Grid>
      <Grid item xs={6} style={{ textAlign: "center", width: "50%" }}>
        <Typography className={classes.cardScore} sx={{ fontSize: "2rem" }}>
          {dollarSign}
          {countElement}
          {unitStr}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default function DashboardCard({ title, subtitle, score, unit, link }) {
  const theme = useTheme();

  return (
    <Paper
      elevation={5}
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "inherit",
        paddingLeft: "10px",
        paddingTop: "10px",
        borderRadius: "10px",
        boxShadow: `10px -10px 30px ${theme.palette.grey3.main}`,
      }}
    >
      <DashboardContent
        title={title}
        subtitle={subtitle}
        score={score}
        unit={unit}
        link={link}
      />
    </Paper>
  );
}
