import React from "react";

import { useQuery, useReactiveVar } from "@apollo/client";
import moment from "moment";

import { onlineVar } from "../../../graphql/localVariables/user";
import { GET_RECENT_HAS } from "../../../graphql/queries";
import RecentHA from "./RecentHA";

export default function RecentHAWrapper() {
  const yesterdayDate = moment(new Date())
    .subtract(1, "days")
    .format("YYYY-MM-DD");

  const online = useReactiveVar(onlineVar);
  const {
    data: ha,
    refetch: refetchHa,
    loading,
  } = useQuery(GET_RECENT_HAS, {
    variables: { after_date: yesterdayDate, first: 500 },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  return <RecentHA ha={ha} refetch={refetchHa} loading={loading} />;
}
