import React from "react";

import DoubleArrowIcon from "@mui/icons-material//DoubleArrow";
import { Grid, Typography, Divider, Button } from "@mui/material";

import { checkInstall } from "../../checkInstallPWA";
import { PWADialog } from "../../pwa";
import { useStyles } from "./styles";

export default function TopBar({ name }) {
  const classes = useStyles()();

  const getGreetingText = () => {
    const hour = new Date().getHours();
    if (hour < 12) {
      return "MORNING";
    } else if (hour < 18) {
      return "AFTERNOON";
    }
    return "EVENING";
  };

  const getDateStr = () => {
    const date = new Date();
    const month = date.toLocaleString("default", { month: "long" });
    const day = date.getDate();
    return month.concat(" ", day);
  };

  const greetingStr = "GOOD ".concat(getGreetingText(), ",");

  const [prompt, setPrompt] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [appInstalled, setAppInstalled] = React.useState(true);

  React.useEffect(() => {
    if (
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone
    ) {
      setAppInstalled(true);
    }

    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setPrompt(e);
      setAppInstalled(false);
    });

    window.addEventListener("appinstalled", () => {
      setAppInstalled(true);
    });
  }, []);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-end"
      className={classes.root}
    >
      <PWADialog open={open} setOpen={setOpen} />
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          className={classes.greetingText}
          sx={{
            fontFamily: "barlowextrabold",
            fontSize: "2.5rem",
          }}
        >
          {greetingStr}
          <span className={classes.name}>{` ${name.toUpperCase()}`}</span>
        </Typography>
        {!appInstalled && (
          <Button
            onClick={() => checkInstall(prompt, setOpen)}
            variant="contained"
            color="yellow0"
          >
            INSTALL APP
          </Button>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        style={{ alignSelf: "flex-start" }}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <DoubleArrowIcon className={classes.dateArrowIcon} />
        <Typography className={classes.dateText}>{getDateStr()}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>
    </Grid>
  );
}
