import * as React from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { useQuery } from "@apollo/client";
import moment from "moment";
import PropTypes from "prop-types";

import { GET_TEMPLATE_OSP } from "../../../../../../../../graphql/queries/admin/planning-template";
import EditTemplateDialog from "../../../Edit/EditTemplateDialog";

export default function Row({ row, company }) {
  const { data } = useQuery(GET_TEMPLATE_OSP, {
    variables: { id: Number(row.id) },
  });
  const [open, setOpen] = React.useState(false);

  const getAssociatedTo = (data) => {
    if (data.ownerSiteProjectTemplates[0].ownerSiteProject.project) {
      return "Project";
    } else if (data.ownerSiteProjectTemplates[0].ownerSiteProject.site) {
      return "Site";
    } else return "Company";
  };

  return (
    <>
      <TableRow hover key={row.id} style={{ cursor: "pointer" }}>
        <TableCell onClick={() => setOpen(true)}>{row.name}</TableCell>
        <TableCell onClick={() => setOpen(true)}>
          {moment(new Date(row.timeCreated)).format("MM/DD/YYYY hh:mm A")}
        </TableCell>
        <TableCell onClick={() => setOpen(true)}>
          {moment(new Date(row.lastUpdated)).format("MM/DD/YYYY hh:mm A")}
        </TableCell>
        <TableCell onClick={() => setOpen(true)}>
          {row.lastEditor?.firstName} {row.lastEditor?.lastName}
        </TableCell>
        <TableCell onClick={() => setOpen(true)}>
          {row.revisionNumber}
        </TableCell>
        <TableCell onClick={() => setOpen(true)}>
          {data && getAssociatedTo(data)}
        </TableCell>
      </TableRow>
      <EditTemplateDialog
        open={open}
        setOpen={setOpen}
        data={row}
        company={company}
      />
    </>
  );
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
  company: PropTypes.object,
};
