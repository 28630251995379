import * as React from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";

import { useReactiveVar } from "@apollo/client";
import PropTypes from "prop-types";

import {
  createAuditVar,
  editAuditVar,
} from "../../../graphql/localVariables/audit";
import { auditMutations } from "../../../graphql/mutations";

const styles = () => ({
  root: {},
  imageContainer: {
    position: "relative",
    zIndex: 10,
    textAlign: "center",
    "&:hover $image": {
      opacity: 0.3,
    },
    "&:hover $removeButton": {
      opacity: 1,
    },
    padding: "20px",
  },
  image: {
    maxWidth: "100%",
    width: "auto",
    maxHeight: "100%",
    height: "100px",
  },
  removeButton: {
    transition: ".5s ease",
    position: "absolute",
    top: 0,
    right: 0,
    opacity: 0,
    width: 40,
    height: 40,
    "&:focus": {
      opacity: 1,
    },
  },
});

function PreviewList({ fileObjects, classes, handleFilesChange, type }) {
  const editAudit = useReactiveVar(editAuditVar);
  const createAudit = useReactiveVar(createAuditVar);
  const updateAudit = type === "EDIT" ? editAudit : createAudit;
  const { updateCreateAudit, updateEditAudit } = auditMutations;
  const updateAuditFunction =
    type === "EDIT" ? updateEditAudit : updateCreateAudit;

  const isImage = (file) => {
    if (file.type && file.type.split("/")[0] === "image") {
      return true;
    }
  };

  const getPreviewIcon = (fileObject, classes) => {
    if (fileObject.id) {
      return (
        <img
          className={classes.image}
          src={fileObject.image}
          alt="Audit preview"
        />
      );
    } else {
      if (isImage(fileObject.image)) {
        return (
          <img
            className={classes.image}
            src={URL.createObjectURL(fileObject.image)}
            alt="Audit preview"
          />
        );
      }
      return <AttachFileIcon className={classes.image} />;
    }
  };

  const handleRemove = (fileIndex) => (event) => {
    event.stopPropagation();
    const arr = [...fileObjects];
    const image = fileObjects[fileIndex];
    if (image.id) {
      updateAuditFunction("removeImages", [
        ...updateAudit.removeImages,
        Number(image.id),
      ]);
    }
    arr.splice(fileIndex, 1);
    handleFilesChange(arr);
  };

  return (
    <>
      {fileObjects?.map((fileObject, i) => {
        return (
          <Grid
            item={true}
            key={`${fileObject.file?.name ?? "file"}-${i}`}
            className={classes.imageContainer}
          >
            {getPreviewIcon(fileObject, classes)}

            <Fab
              onClick={handleRemove(i)}
              aria-label="Delete"
              className={classes.removeButton}
            >
              <DeleteIcon />
            </Fab>
          </Grid>
        );
      })}
    </>
  );
}

PreviewList.propTypes = {
  type: PropTypes.string.isRequired,
  fileObjects: PropTypes.array.isRequired,
  handleFilesChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { name: "MuiDropzonePreviewList" })(
  PreviewList
);
