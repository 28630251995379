import { useEffect, useRef } from "react";

import { useQuery, useReactiveVar } from "@apollo/client";
import * as Sentry from "@sentry/react";
import axios from "axios";

import { authVar, onlineVar } from "../graphql/localVariables/user";
import { authMutations } from "../graphql/mutations";
import { GET_CURRENT_USER } from "../graphql/queries/auth";

export const useAuthRefresh = () => {
  const { updateAuth } = authMutations;
  const online = useReactiveVar(onlineVar);
  const auth = useReactiveVar(authVar);
  const { data: user } = useQuery(GET_CURRENT_USER);
  const intervalRef = useRef();

  useEffect(() => {
    const verify = () => {
      axios
        .post("/auth/jwt/refresh/")
        .then(() => {
          console.log("Token refreshed.");
        })
        .catch((e) => {
          if (online) {
            console.log(e);
            Sentry.captureMessage(
              `Refresh error for ${user ? user.username : "user not loaded"}:`
            );
            updateAuth(false);
          }
        });
    };

    if (auth && online) {
      const refreshInterval = setInterval(() => {
        verify();
      }, 60 * 1000); // every 1 minute
      intervalRef.current = refreshInterval;
    } else if (intervalRef.current) {
      console.log(
        "No longer authorized or online - canceling refresh token interval"
      );
      clearInterval(intervalRef.current);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [auth, online]);
};
