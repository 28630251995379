import * as React from "react";

import TableTemplate from "../../../../../../../CustomComponents/TableTemplate/TableTemplate";
import Row from "./Row";
import { filterData } from "./searchFilterFunction";

export default function Table({ data, company }) {
  const [searchTerm, setSearchTerm] = React.useState("");

  const [filteredData, setFilteredData] = React.useState([]);

  // useEffect for filter update
  React.useEffect(() => {
    setFilteredData(filterData(data, searchTerm));
  }, [data, searchTerm]);

  return (
    <TableTemplate
      hasPagination={true}
      data={filteredData}
      title="Projects"
      headCells={[
        {
          uid: "name",
          numeric: false,
          disablePadding: false,
          label: "Project Name",
          sort: true,
        },
        {
          uid: "timeCreated",
          numeric: true,
          disablePadding: false,
          label: "Date Created",
        },
        {
          uid: "numSites",
          numeric: true,
          disablePadding: false,
          label: "Number of Sites",
        },
        {
          uid: "dollarAmount",
          numeric: true,
          disablePadding: false,
          label: "Dollar Amount",
        },
      ]}
      initialOrderBy="name"
      getItemsFromOrderBy={(a, b, orderBy) => {
        let aItem, bItem;

        if (orderBy === "name") {
          aItem = a.project[orderBy].toLowerCase();
          bItem = b.project[orderBy].toLowerCase();
        } else {
          aItem = a[orderBy];
          bItem = b[orderBy];
        }

        return { aItem, bItem };
      }}
      hasFilters={false}
      renderFilterDialog={null}
      searchTerm={searchTerm}
      updateSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
      getBadgeCount={() => {}}
      renderRow={(row) => <Row key={row.id} row={row} company={company} />}
    />
  );
}
