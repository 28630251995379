import * as React from "react";
import { useNavigate } from "react-router-dom";

import { TableCell, TableRow } from "@mui/material";

import moment from "moment";
import PropTypes from "prop-types";

export default function Row({ row }) {
  const navigate = useNavigate();

  const calcNotifications = (usersArray) => {
    let sent = 0;
    let seen = 0;
    usersArray.map((u) => {
      if (u.notification) {
        sent++;
      }
      if (u.notification && u.notified) {
        seen++;
      }
    });
    return { seen, sent };
  };

  const d = new Date(row.date);
  const utc = d.getTime() + d.getTimezoneOffset() * 60000;
  const convertedDate = new Date(utc);

  let time = new Date();
  const arr = row.time.split(":");
  time.setHours(arr[0], arr[1], arr[2], 0);

  return (
    <TableRow
      hover
      onClick={() => navigate(`/view-obs/${row.obsId}`)}
      key={row.id}
      style={{ cursor: "pointer" }}
    >
      <TableCell component="th" scope="row">
        {row.name}
      </TableCell>
      <TableCell>{`${moment(convertedDate).format("MM/DD/YYYY")} ${moment(
        time
      ).format("hh:mm A")}`}</TableCell>
      <TableCell>
        {row.observationOutcomes?.map((o) => o.outcome?.name).join(", ")}
      </TableCell>
      <TableCell>{row.submitter?.username}</TableCell>
      <TableCell>{row.contractor?.name}</TableCell>
      <TableCell>
        {row.automaticCheck || row.manualCheck ? "Unplanned" : "Planned"}
      </TableCell>
      <TableCell>
        {row.ownerSiteProject?.site?.name} [{row.ownerSiteProject?.owner?.name}]
      </TableCell>
      <TableCell>{row.ha ? `${row.ha.name} [${row.ha.date}]` : ""}</TableCell>
      <TableCell>
        {row.observationUsers && row.observationUsers.length !== 0
          ? calcNotifications(row.observationUsers).sent
          : "None"}
      </TableCell>
      <TableCell>
        {row.observationUsers && row.observationUsers.length !== 0
          ? calcNotifications(row.observationUsers).seen
          : "None"}
      </TableCell>
    </TableRow>
  );
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
};
