import * as React from "react";
import { useNavigate } from "react-router-dom";

import { TableCell, TableRow } from "@mui/material";

import moment from "moment";
import PropTypes from "prop-types";

export default function Row({ row }) {
  const navigate = useNavigate();

  const d = new Date(row.date);
  const utc = d.getTime() + d.getTimezoneOffset() * 60000;
  const convertedDate = new Date(utc);

  return (
    <TableRow
      hover
      onClick={() => navigate(`/view-ha/${row.haId}`)}
      key={row.id}
      style={{ cursor: "pointer" }}
    >
      <TableCell component="th" scope="row">
        {row.haId}
      </TableCell>
      <TableCell>{row.name}</TableCell>
      <TableCell>{`${moment(convertedDate).format("MM/DD/YYYY")}`}</TableCell>
      <TableCell>{row.submitter?.username}</TableCell>
      <TableCell>{row.submitter?.company?.name}</TableCell>
      <TableCell>
        {row.haOwnerSiteProject && row.haOwnerSiteProject.length > 0
          ? `${row.haOwnerSiteProject[0].ownerSiteProject.site.name} 
                  [${row.haOwnerSiteProject[0].ownerSiteProject.owner.name}]`
          : ""}
      </TableCell>
      <TableCell>
        {row.haOwnerSiteProject
          .map((o) => o.ownerSiteProject.project?.name)
          .join(", ")}
      </TableCell>
      <TableCell>
        {row.haTemplate?.map((o) => o.template.name).join(", ")}
      </TableCell>
      <TableCell>{row.isCompleted ? "Closed" : "Open"}</TableCell>
      <TableCell>{row.revisionNumber}</TableCell>
      <TableCell>{row.hasInjury ? "With" : "Without"}</TableCell>
      <TableCell>{row.observation?.edges.length}</TableCell>
      <TableCell>{row.haCrewMembers?.length}</TableCell>
      <TableCell>{+row.raScore.toFixed(2)}</TableCell>
    </TableRow>
  );
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
};
