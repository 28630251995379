import React from "react";
import { useParams } from "react-router-dom";

import { CircularProgress, Grid, Typography } from "@mui/material";

import { useLazyQuery } from "@apollo/client";

import { auditMutations, authMutations } from "../../../graphql/mutations";
import { GET_AUDIT_SUBMISSION } from "../../../graphql/queries/audits";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Layout from "../../Layout";
import AuditForm from "../Form";

export default function EditAudit() {
  const { id } = useParams();
  const { updateEditAudit, updateEditAuditImages } = auditMutations;
  const [getAuditSubmission, { data, loading }] =
    useLazyQuery(GET_AUDIT_SUBMISSION);
  const { updateLoading } = authMutations;

  // When Id from url updates, fetch submission
  React.useEffect(() => {
    if (id) {
      getAuditSubmission({
        variables: { id: Number(id) },
        fetchPolicy: "network-only",
      });
    }
  }, [id]);

  // show loading
  React.useEffect(() => {
    updateLoading(loading);
  }, [loading]);

  // When fetch returns, update state
  React.useEffect(() => {
    if (data?.auditSubmission) {
      updateEditAudit("id", id);
      updateEditAudit(
        "location",
        data.auditSubmission.ownerSiteProject.location
      );
      updateEditAudit("ospId", data.auditSubmission.ownerSiteProject.id);
      updateEditAudit("audit", data.auditSubmission.audit);
      updateEditAudit(
        "contributors",
        data.auditSubmission.auditSubmissionUsers.map((user) => ({
          ...user.user,
        }))
      );
      updateEditAudit("date", data.auditSubmission.date);
      updateEditAudit("time", data.auditSubmission.time);
      updateEditAudit("raScore", data.auditSubmission.raScore);
      updateEditAudit("creator", data.auditSubmission.submitter);
      const imagesArr = [];
      const withImages = data.auditSubmission.auditTopicQuestionResponse.filter(
        (ques) => ques.auditTopicQuestionResponseImage.length > 0
      );
      withImages.forEach((response) => {
        const questionId = response.auditTopicQuestion.id;
        response.auditTopicQuestionResponseImage.forEach((i) => {
          imagesArr.push({
            id: i.id,
            image: i.image,
            thumbnail: i.thumbnail,
            questionId,
          });
        });
      });
      updateEditAuditImages(imagesArr);
      updateEditAudit(
        "auditResponses",
        data.auditSubmission.responses.map((ques) => {
          return {
            auditTopicQuestion: ques.auditTopicQuestion.id,
            response: ques.response,
            penalty: ques.auditTopicQuestion.penalty,
            comment: ques.comment,
            score: ques.score,
          };
        })
      );
      updateEditAudit("removeImages", []);
    }
  }, [data]);

  return (
    <Layout>
      <Grid>
        <Typography sx={pageTitleStyles}>EDIT AUDIT.</Typography>
        {loading && <CircularProgress />}
        {!loading && (
          <>
            {data?.auditSubmission ? (
              <AuditForm type={"EDIT"} audit={data?.auditSubmission?.audit} />
            ) : (
              <Typography sx={{ color: "#fff" }}>
                Audit cannot be found.
              </Typography>
            )}
          </>
        )}
      </Grid>
    </Layout>
  );
}
