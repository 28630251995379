import * as React from "react";

import { useReactiveVar } from "@apollo/client";
import PropTypes from "prop-types";

import { viewAuditFiltersVar } from "../../../../graphql/localVariables/audit";
import { auditMutations } from "../../../../graphql/mutations";
import TableTemplate from "../../../CustomComponents/TableTemplate/TableTemplate";
import Row from "./Row";
import TableFilterDialog from "./TableFilterDialog";
import { filterData } from "./searchFilterFunction";

export default function Table({ data }) {
  const filters = useReactiveVar(viewAuditFiltersVar);
  const { updateKeyViewAuditFilters } = auditMutations;

  const [filteredData, setFilteredData] = React.useState(data);

  React.useEffect(() => {
    setFilteredData(
      filterData(
        data,
        filters.searchterm ? filters.searchterm : "",
        filters.site ? filters.site : { owner: { name: "" } },
        filters.afterDate ? filters.afterDate : null,
        filters.beforeDate ? filters.beforeDate : null,
        filters.usernames ? filters.usernames : []
      )
    );
  }, [
    data,
    filters.searchterm,
    filters.site,
    filters.afterDate,
    filters.beforeDate,
    filters.usernames,
  ]);

  return (
    <TableTemplate
      hasPagination={true}
      data={filteredData}
      title="Audits"
      headCells={[
        {
          uid: "id",
          numeric: false,
          disablePadding: false,
          label: "Audit ID",
          sort: true,
        },
        {
          uid: "date",
          numeric: true,
          disablePadding: false,
          label: "Date",
          sort: true,
        },
        {
          uid: "timeCreated",
          numeric: true,
          disablePadding: false,
          label: "Time Created",
          sort: true,
        },
        {
          uid: "submitter.username",
          numeric: true,
          disablePadding: false,
          label: "Submitter Username",
          sort: true,
        },
        {
          uid: "Location",
          numeric: true,
          disablePadding: false,
          label: "Location",
        },
        {
          uid: "type",
          numeric: true,
          disablePadding: false,
          label: "Type",
          sort: true,
        },
      ]}
      initialOrderBy="id"
      getItemsFromOrderBy={(a, b, orderBy) => {
        let aItem, bItem;

        switch (orderBy) {
          case "submitter.username":
            aItem = a.submitter.username;
            bItem = b.submitter.username;
            break;
          case "date":
            aItem = new Date(`${a.date} ${a.time}`);
            bItem = new Date(`${b.date} ${b.time}`);
            break;
          case "type":
            aItem = a.audit.name;
            bItem = b.audit.name;
            break;
          case "id":
            aItem = Number(a.id);
            bItem = Number(b.id);
            break;
          default:
            aItem = a[orderBy];
            bItem = b[orderBy];
        }
        return { aItem, bItem };
      }}
      renderFilterDialog={(open, setOpen) => (
        <TableFilterDialog open={open} setOpen={setOpen} />
      )}
      searchTerm={filters.searchterm}
      updateSearchTerm={(searchTerm) =>
        updateKeyViewAuditFilters("searchterm", searchTerm)
      }
      getBadgeCount={() => {
        let count = 0;
        if (filters.site && filters.site.owner.name !== "") count++;
        if (filters.usernames && filters.usernames.length > 0) count++;
        if (filters.afterDate && filters.afterDate !== null) count++;
        if (filters.beforeDate && filters.beforeDate !== null) count++;
        return count;
      }}
      renderRow={(row) => <Row key={row.uid} row={row} />}
    />
  );
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
};
