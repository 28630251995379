import * as React from "react";

import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

import PropTypes from "prop-types";

import TableBodyTemplate from "./TableBody";
import TableHeader from "./TableHeader";
import Pagination from "./TablePagination";
import TableToolbar from "./TableToolbar";

export default function TableTemplate({
  title,
  hasPagination,
  data,
  headCells,
  initialOrderBy,
  initialSort,
  getItemsFromOrderBy,
  renderFilterDialog,
  searchTerm,
  updateSearchTerm,
  getBadgeCount,
  renderRow,
  handleFetchMore,
  paginationCount,
  loading,
}) {
  // Must have for filtering
  const [order, setOrder] = React.useState(initialSort ?? "asc");
  const [orderBy, setOrderBy] = React.useState(initialOrderBy);

  // Must have for pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Filtering with header
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    let { aItem, bItem } = getItemsFromOrderBy(a, b, orderBy);

    if (bItem < aItem) {
      return -1;
    }
    if (bItem > aItem) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Paper
        sx={{
          width: "100%",
          mb: 2,
        }}
      >
        <TableToolbar
          title={title}
          renderFilterDialog={renderFilterDialog}
          searchTerm={searchTerm}
          updateSearchTerm={updateSearchTerm}
          getBadgeCount={getBadgeCount}
          setPage={setPage}
        />
        <TableContainer>
          <Table
            sx={{
              minWidth: 750,
            }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <TableHeader
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {loading ? (
                <TableRow
                  style={{
                    height: 53 * rowsPerPage,
                  }}
                >
                  <TableCell colSpan={headCells.length}>
                    <Grid container justifyContent="center">
                      <CircularProgress />
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : (
                <TableBodyTemplate
                  renderRow={renderRow}
                  order={order}
                  orderBy={orderBy}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  getComparator={getComparator}
                  stableSort={stableSort}
                  data={data}
                  numColumns={headCells.length}
                />
              )}

              {emptyRows > 0 && !loading && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={headCells.length} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {hasPagination && (
          <Pagination
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            dataLength={data.length}
            handleFetchMore={handleFetchMore}
            paginationCount={paginationCount}
          />
        )}
      </Paper>
    </Box>
  );
}

TableTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  hasPagination: PropTypes.bool,
  data: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
  initialOrderBy: PropTypes.string.isRequired,
  initialSort: PropTypes.string,
  getItemsFromOrderBy: PropTypes.func.isRequired,
  renderFilterDialog: PropTypes.func,
  searchTerm: PropTypes.string.isRequired,
  updateSearchTerm: PropTypes.func.isRequired,
  getBadgeCount: PropTypes.func.isRequired,
  renderRow: PropTypes.func.isRequired,
  handleFetchMore: PropTypes.func,
  paginationCount: PropTypes.number,
  loading: PropTypes.bool,
};
