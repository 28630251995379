import React from "react";

import { Grid, Typography } from "@mui/material";

import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Layout from "../../Layout";
import Table from "./Table";

export default function ViewHAsContainer() {
  return (
    <Layout>
      <Grid
        style={{
          height: "100%",
        }}
      >
        <Typography
          sx={{ ...pageTitleStyles, fontSize: { xs: "2rem", md: "2.75rem" } }}
        >
          VIEW HAZARD ANALYSIS.
        </Typography>
        <Table />
      </Grid>
    </Layout>
  );
}
