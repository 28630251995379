export const filterData = (
  arr,
  searchTerm,
  site,
  afterDate,
  beforeDate,
  usernames
) => {
  let filteredArr = searchFilter(arr, searchTerm);

  if (site.owner.name !== "") {
    filteredArr = siteFilter(filteredArr, site);
  }
  if (afterDate) {
    filteredArr = dateFilter(filteredArr, "after", afterDate);
  }
  if (beforeDate) {
    filteredArr = dateFilter(filteredArr, "before", beforeDate);
  }
  if (usernames.length > 0) {
    filteredArr = usernameFilter(filteredArr, usernames);
  }
  return filteredArr;
};

const searchFilter = (arr, searchTerm) => {
  searchTerm = String(searchTerm).toLowerCase();
  return arr.filter((o) =>
    `${o.audit?.name} ${o.submitter.username}`
      .toLowerCase()
      .includes(searchTerm)
  );
};

const siteFilter = (arr, site) => {
  return arr.filter((o) => o.ownerSiteProject?.site?.id === site?.site?.id);
};

const dateFilter = (arr, order, date) => {
  return arr.filter((o) =>
    order === "before" ? new Date(o.date) < date : new Date(o.date) >= date
  );
};

const usernameFilter = (arr, usernames) => {
  const usernameIds = usernames.map((u) => Number(u.id));
  return arr.filter((o) => usernameIds.includes(Number(o.submitter.id)));
};
