import React from "react";
import { useNavigate } from "react-router-dom";

import { Button, Grid, Typography } from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import { permissionsVar } from "../../../../graphql/localVariables/user";
import { pageTitleStyles } from "../../../CustomStyles/pageTitle";
import Layout from "../../../Layout";
import CreateProject from "./CreateProject";
import Table from "./Table";

export default function ProjectAdmin() {
  const navigate = useNavigate();
  const permissions = useReactiveVar(permissionsVar);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (
      permissions.length === 0 ||
      (permissions.length > 0 &&
        !permissions.includes("VIEW_PLANNING_ADMINISTRATION"))
    ) {
      navigate("/");
    }
  }, []);

  return (
    <Layout>
      <Grid
        style={{
          height: "100%",
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography sx={pageTitleStyles}>
            PLANNING ADMIN - PROJECT.
          </Typography>

          {permissions.includes("CREATE_PROJECT") && (
            <Button
              variant="contained"
              color="yellow0"
              sx={{ mr: 1 }}
              onClick={() => setOpen(true)}
            >
              CREATE PROJECT
            </Button>
          )}
        </Grid>
        <Table />
        <CreateProject open={open} handleClose={() => setOpen(false)} />
      </Grid>
    </Layout>
  );
}
