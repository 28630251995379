import * as React from "react";

import { useReactiveVar } from "@apollo/client";

import { viewIncidentFiltersVar } from "../../../../graphql/localVariables/investigation";
import { investigationMutations } from "../../../../graphql/mutations";
import removeDuplicates from "../../../../utils/removeDuplicates";
import TableTemplate from "../../../CustomComponents/TableTemplate/TableTemplate";
import Row from "./Row";
import TableFilterDialog from "./TableFilterDialog";
import { filterData } from "./searchFilterFunction";

export default function Table({ data }) {
  const { updateAllViewIncidentFilters, updateKeyViewIncidentFilters } =
    investigationMutations;
  const filters = useReactiveVar(viewIncidentFiltersVar);

  const clearFilters = () => {
    updateAllViewIncidentFilters({
      site: { owner: { name: "" } },
      afterDate: null,
      beforeDate: null,
      username: { username: "" },
      type: "",
    });
  };

  const [filteredData, setFilteredData] = React.useState(data);

  // useEffect for filter update
  React.useEffect(() => {
    setFilteredData(
      filterData(
        data,
        filters.searchterm ? filters.searchterm : "",
        filters.site ? filters.site : { owner: { name: "" } },
        filters.afterDate ? filters.afterDate : null,
        filters.beforeDate ? filters.beforeDate : null,
        filters.username ? filters.username : { username: "" },
        filters.type ? filters.type : ""
      )
    );
  }, [
    filters.searchterm,
    filters.site,
    filters.afterDate,
    filters.beforeDate,
    filters.username,
    filters.type,
    data,
  ]);

  const headCells = [
    {
      uid: "id",
      numeric: false,
      disablePadding: false,
      label: "ID",
      sort: true,
    },
    {
      uid: "date",
      numeric: true,
      disablePadding: false,
      label: "Date",
      sort: true,
    },
    {
      uid: "type",
      numeric: true,
      disablePadding: false,
      label: "Incident Type",
      sort: true,
    },
    {
      uid: "Submitter Username",
      numeric: true,
      disablePadding: false,
      label: "Submitter Username",
      sort: true,
    },
    {
      uid: "isCompleted",
      numeric: true,
      disablePadding: false,
      label: "Is Completed?",
      sort: true,
    },
    {
      uid: "Location",
      numeric: true,
      disablePadding: false,
      label: "Location",
      sort: false,
    },
    {
      uid: "HA Name",
      numeric: true,
      disablePadding: false,
      label: "HA Name",
      sort: true,
    },
  ];

  return (
    <TableTemplate
      hasPagination={true}
      data={filteredData}
      title="Incident Investigations"
      headCells={headCells}
      initialOrderBy="date"
      initialSort="desc"
      getItemsFromOrderBy={(a, b, orderBy) => {
        let aItem, bItem;

        switch (orderBy) {
          case "Submitter Username":
            aItem = a.submitter.username;
            bItem = b.submitter.username;
            break;
          case "HA Name":
            aItem = a.ha?.name;
            bItem = b.ha?.name;
            break;
          default:
            aItem = a[orderBy];
            bItem = b[orderBy];
        }

        return { aItem, bItem };
      }}
      renderFilterDialog={(open, setOpen) => (
        <TableFilterDialog
          open={open}
          setOpen={setOpen}
          clearFilters={clearFilters}
          usernames={
            data
              ? removeDuplicates(
                  data
                    .filter((d) => d.submitter)
                    .map((d) => ({ ...d.submitter })),
                  "id"
                )
              : []
          }
          sites={
            data
              ? removeDuplicates(
                  data
                    .filter((d) => d.ownerSiteProject)
                    .map((d) => ({ ...d.ownerSiteProject })),
                  "id"
                )
              : []
          }
        />
      )}
      searchTerm={filters.searchterm}
      updateSearchTerm={(searchTerm) =>
        updateKeyViewIncidentFilters("searchterm", searchTerm)
      }
      getBadgeCount={() => {
        let count = 0;
        if (filters.site && filters.site.owner.name !== "") count++;
        if (filters.username && filters.username.username !== "") count++;
        if (filters.afterDate && filters.afterDate !== null) count++;
        if (filters.beforeDate && filters.beforeDate !== null) count++;
        if (filters.type && filters.type !== "") count++;
        return count;
      }}
      renderRow={(row) => <Row key={row.id} row={row} />}
    />
  );
}
