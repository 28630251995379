import React from "react";
import { BrowserView, isTablet, MobileView } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";

import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

import {
  useMutation,
  useQuery,
  useReactiveVar,
  useLazyQuery,
} from "@apollo/client";
import {
  Document,
  Image,
  Page,
  pdf,
  PDFDownloadLink,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import ExifReader from "exifreader";

import {
  onlineVar,
  permissionsVar,
} from "../../../graphql/localVariables/user";
import { OBSERVATION_EMAIL } from "../../../graphql/mutations/observations";
import { GET_COMPANY_ARMS_NO_RBAC } from "../../../graphql/queries";
import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import { GET_SINGLE_OBSERVATION } from "../../../graphql/queries/observations";
import checkbox from "../../../images/checkbox.png";
import logo from "../../../images/nixn-logo-white.png";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Layout from "../../Layout";
import ViewImages from "./ViewImages";

const styles = StyleSheet.create({
  page: {
    width: "100%",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Helvetica",
  },
  header: { fontSize: "12px", fontFamily: "Helvetica-Bold" },
  formSection: { width: "147.3px", border: "1px solid black" },
  armSectionHeader: { width: "185px" },
  column: { display: "flex", flexDirection: "column" },
  row: { display: "flex", flexDirection: "row" },
  text: { padding: "20px", fontSize: "10px" },
});

export default function ViewSingleObservation() {
  const navigate = useNavigate();
  const { id } = useParams();
  const online = useReactiveVar(onlineVar);
  const permissions = useReactiveVar(permissionsVar);

  const { data, loading } = useQuery(GET_SINGLE_OBSERVATION, {
    variables: { id: Number(id) },
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const { data: currentUser } = useQuery(GET_CURRENT_USER);
  const [getCompanyMit, { data: arms, loading: loadingArms }] = useLazyQuery(
    GET_COMPANY_ARMS_NO_RBAC
  );
  const [sendEmailMutation] = useMutation(OBSERVATION_EMAIL, {
    onCompleted() {
      onCompletedFunc("Email will be sent!");
    },
    onError(error) {
      onErrorFunc(error);
    },
  });

  React.useEffect(() => {
    if (
      data &&
      data.observation &&
      data.observation.submitter &&
      data.observation.submitter.company
    ) {
      const companyId = Number(data.observation.submitter.company.id);
      getCompanyMit({ variables: { companyId, rNull: false, mNull: false } });
    }
  }, [data]);

  const [open, setOpen] = React.useState(false);

  const navigateToEdit = () => {
    navigate(`/edit-obs/${id}`);
  };

  const getOutcomeColor = (observation) => {
    if (observation.observationOutcomes.length > 0) {
      const outcome = observation.observationOutcomes[0].outcome.name;
      switch (outcome) {
        case "Safe":
          return "green";
        default:
          return "red";
      }
    } else {
      return "white";
    }
  };

  const getOutcome = (observation) => {
    if (observation.observationOutcomes.length > 0) {
      const outcomes = observation.observationOutcomes.map(
        (obsOut) => obsOut.outcome.name
      );
      return outcomes.join(", ");
    } else {
      return "";
    }
  };

  const getRiskColor = (risk) => {
    if (risk >= 90) {
      return "red";
    } else if (risk >= 70) {
      return "orange";
    } else if (risk >= 50) {
      return "gold";
    } else return "green";
  };

  const [actions, setActions] = React.useState([]);
  const [images, setImages] = React.useState([]);

  React.useEffect(() => {
    if (data) {
      const unique = (arr) =>
        arr.reduce((results, item) => {
          if (
            item.isActive &&
            !results.some(
              (i) =>
                i.arm.a.name === item.arm.a.name && i.arm.a.id === item.arm.a.id
            )
          ) {
            results.push(item);
          }
          return results;
        }, []);
      setActions(
        unique(
          data.observation.observationArms.filter(
            (a) => a.isActive && !a.arm.r && !a.arm.m
          )
        )
      );

      const img = [];
      data.observation.observationArms.forEach((arm) =>
        arm.observationArmMediaFiles.forEach((i) => img.push(i))
      );
      setImages(img);
    }
  }, [data]);

  const findRisks = (a) => {
    const allRisks = data.observation.observationArms.filter(
      (oa) =>
        oa.arm.a.name === a.name &&
        oa.arm.a.id === a.id &&
        oa.arm.r &&
        !oa.arm.m
    );
    const unique = (arr) =>
      arr.reduce((results, item) => {
        if (
          !results.some(
            (i) =>
              i.arm.r &&
              item.arm.r &&
              i.arm.r.name === item.arm.r.name &&
              i.arm.r.id === item.arm.r.id
          )
        ) {
          results.push(item);
        }
        return results;
      }, []);
    return unique(allRisks);
  };

  const findMitigators = (ar) => {
    const allMitigators = data.observation.observationArms.filter(
      (oa) =>
        oa.arm.r &&
        oa.arm.r.name === ar.r.name &&
        oa.arm.a.name === ar.a.name &&
        oa.isActive === true
    );
    const unique = (arr) =>
      arr.reduce((results, item) => {
        if (item.arm.m !== null) {
          results.push(item);
        }
        return results;
      }, []);
    return unique(allMitigators);
  };

  const findUnselectedMitigators = (ar) => {
    const allMitigators = arms
      ? arms.companyArmsView
          .filter(
            (arm) =>
              arm.arm.isActive &&
              arm.arm.r.name === ar.r.name &&
              arm.arm.a.name === ar.a.name
          )
          .map((item) => item.arm)
      : [];
    return allMitigators.filter(
      (mit) =>
        data.observation.observationArms
          .filter((a) => a.isActive)
          .findIndex(
            (obsArm) =>
              obsArm.arm.r &&
              mit.r &&
              obsArm.arm.r.name === mit.r.name &&
              obsArm.arm.m?.name === mit.m.name
          ) < 0
    );
  };

  const checkCorrection = (m) => {
    const corrections = data.observation.observationArms.filter(
      (a) => a.isCorrection
    );
    return corrections.findIndex((c) => Number(c.arm.id) === Number(m.id)) >= 0;
  };

  const checkRootCauses = (m) => {
    const rootCauses = data.observation.observationArms.filter(
      (a) => a.isRootCause
    );
    const mitId = m.arm ? m.arm.id : m.id;
    return rootCauses.findIndex((c) => Number(c.arm.id) === Number(mitId)) >= 0;
  };

  const getCorrection = (m) => {
    const corrections = data.observation.observationArms.filter(
      (a) => a.isCorrection
    );
    return corrections.filter((c) => Number(c.arm.id) === Number(m.id));
  };

  const findExpected = (risk) => {
    if (risk.arm.m === null) {
      return risk.arm.isExpected;
    }
    const index = data.observation.observationArms.findIndex(
      (arm) => arm.arm.r && arm.arm.r.id === risk.arm.r.id && arm.arm.m === null
    );
    if (index >= 0) {
      return data.observation.observationArms[index].arm.isExpected;
    } else return false;
  };

  const getLastMit = (risk, index) => {
    if (
      findMitigators(risk).length - 1 === index &&
      findUnselectedMitigators(risk).length === 0
    ) {
      return true;
    }
    return false;
  };

  const getLastUnselectedMit = (risk, index) => {
    if (findUnselectedMitigators(risk).length - 1 === index) {
      return true;
    }
    return false;
  };

  const ObsImage = ({ img }) => {
    const [exif, setExif] = React.useState({});

    React.useEffect(() => {
      async function fetchData() {
        const res = await fetch(img.mediaFile.file);
        const blob = await res.blob();
        const arrBuff = await blob.arrayBuffer();
        const tags = ExifReader.load(arrBuff);
        setExif(tags);
      }
      fetchData();
    }, []);

    let style;
    const rotVal = exif.Orientation?.value;
    if (rotVal == 1 || rotVal == undefined) {
      style = { width: "175px" };
    } else if (rotVal == 3) {
      style = { width: "175px", transform: "rotate(180deg)" };
    } else if (rotVal == 6) {
      style = { width: "175px", transform: "rotate(90deg)" };
    } else if (rotVal == 8) {
      style = { width: "175px", transform: "rotate(270deg)" };
    }

    return (
      <Image
        key={img.id}
        style={{ ...style, marginTop: "10px" }}
        src={img.mediaFile.file}
      />
    );
  };

  const PDFDocument = () => {
    const unplanned =
      data.observation.automaticCheck || data.observation.manualCheck;

    return (
      <Document pageMode="fullScreen" title="Observation Report" author="NIXN">
        <Page size="A4">
          <View style={styles.page}>
            <View
              style={{
                padding: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{ fontFamily: "Helvetica-Bold", fontSize: "14px" }}
                >
                  OBSERVATION
                </Text>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>REPORT</Text>
              </View>
              <View
                style={{ width: "57%", borderBottom: "3px solid #FCA800" }}
              ></View>
              {data &&
              data.observation.submitter.company &&
              data.observation.submitter.company.logoBase64 !== "" ? (
                <Image
                  src={`data:image;base64,${data.observation.submitter.company.logoBase64}`}
                  style={{ width: "100px" }}
                />
              ) : (
                <Image src={logo} style={{ width: "100px" }} />
              )}
            </View>
            <Text style={{ margin: "10px 0px" }}>
              {data.observation.name} -{" "}
              <Text
                style={{
                  color: getOutcomeColor(data.observation),
                  paddingBottom: "20px",
                }}
              >
                {getOutcome(data.observation)}
              </Text>
            </Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <View style={styles.armSectionHeader}>
                <Text style={styles.header}>Owner</Text>
                <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                  {data.observation.ownerSiteProject.owner?.name}
                </Text>
              </View>
              <View style={styles.armSectionHeader}>
                <Text style={styles.header}>Site</Text>
                <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                  {data.observation.ownerSiteProject.site?.name}
                </Text>
              </View>
              <View style={styles.armSectionHeader}>
                <Text style={styles.header}>Project</Text>
                {data.observation.ownerSiteProject.project && (
                  <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                    {data.observation.ownerSiteProject.project?.name}
                  </Text>
                )}
              </View>
              <View style={styles.armSectionHeader}>
                <Text style={styles.header}>Observed</Text>
                <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                  {data.observation.contractor
                    ? data.observation.contractor.name
                    : ""}
                </Text>
              </View>
              <View style={styles.armSectionHeader}>
                <Text style={styles.header}>Submitted By</Text>
                <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                  {data.observation.submitter.username}
                </Text>
              </View>
              <View style={styles.armSectionHeader}>
                <Text style={styles.header}>Supervisor</Text>
                <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                  {" "}
                </Text>
              </View>
              <View style={styles.armSectionHeader}>
                <Text style={styles.header}>Outcome</Text>
                <Text
                  style={{
                    fontSize: "14px",
                    color: getOutcomeColor(data.observation),
                    padding: "10px 10px 10px 0px",
                  }}
                >
                  {getOutcome(data.observation)}
                </Text>
              </View>
              <View style={styles.armSectionHeader}>
                <Text style={styles.header}>Planned Status</Text>
                <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                  {/* check === false -> Planned work */}
                  {unplanned ? "Unplanned" : "Planned"}
                </Text>
              </View>
              {data.observation.dollarAmount ? (
                <View style={styles.armSectionHeader}>
                  <Text style={styles.header}>Dollar Amount</Text>
                  <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                    ${data.observation.dollarAmount}
                  </Text>
                </View>
              ) : null}
              <View
                style={{
                  marginTop: "40px",
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <View
                  style={{
                    width: "33.3%",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                    Event had Risk Assessment Score:
                  </Text>
                  <Text
                    style={{ color: getRiskColor(data.observation.raScore) }}
                  >
                    {data.observation.raScore.toFixed(2)}
                  </Text>
                </View>
                <View
                  style={{
                    width: "33.3%",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                    Event had Risk Assessment Score (Pre-Mitigation):
                  </Text>
                  <Text
                    style={{ color: getRiskColor(data.observation.raScoreMax) }}
                  >
                    {data.observation.raScoreMax.toFixed(2)}
                  </Text>
                </View>
                <View
                  style={{
                    width: "33.3%",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                    Event had Risk Assessment Score (Post-Corrections):
                  </Text>
                  <Text
                    style={{
                      color: getRiskColor(data.observation.raScoreCorr),
                    }}
                  >
                    {data.observation.raScoreCorr.toFixed(2)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </Page>

        <Page>
          <View style={styles.page}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <View style={styles.armSectionHeader}>
                <Text
                  style={{
                    padding: "20px",
                    fontSize: "12px",
                    fontFamily: "Helvetica-Bold",
                  }}
                >
                  Action
                </Text>
              </View>
              <View style={styles.armSectionHeader}>
                <Text
                  style={{
                    padding: "20px",
                    fontSize: "12px",
                    fontFamily: "Helvetica-Bold",
                  }}
                >
                  Risk
                </Text>
              </View>
              <View style={styles.armSectionHeader}>
                <Text
                  style={{
                    padding: "20px",
                    fontSize: "12px",
                    fontFamily: "Helvetica-Bold",
                  }}
                >
                  Mitigator
                </Text>
              </View>
            </View>
            <View style={styles.column}>
              {actions.map((arm) => {
                return (
                  <View
                    key={arm.arm.a.name}
                    style={[styles.row, { borderTop: "4px solid black" }]}
                  >
                    <View style={styles.armSectionHeader}>
                      <Text style={[styles.text]}>
                        {arm.arm.a.name}{" "}
                        {data.observation.ha
                          ? data.observation.ha.haArms.findIndex(
                              (harm) => harm.arm.a.id == arm.arm.a.id
                            ) < 0
                            ? "[Unplanned]"
                            : ""
                          : ""}
                      </Text>
                      {arm.observationArmMediaFiles.map((i) => (
                        <ObsImage key={i.id} img={i} />
                      ))}
                    </View>
                    <View style={styles.column}>
                      {findRisks(arm.arm.a).filter((r) => r.arm.r).length ===
                      0 ? (
                        <View
                          style={[
                            styles.row,
                            {
                              width: "370px",
                            },
                          ]}
                        ></View>
                      ) : (
                        findRisks(arm.arm.a)
                          .filter((r) => r.arm.r)
                          .map((rm, index) => {
                            const length =
                              findRisks(arm.arm.a).filter((r) => r.arm.r)
                                .length - 1;
                            if (rm.arm.r) {
                              return (
                                <View
                                  key={rm.arm.r.name}
                                  style={[
                                    styles.row,
                                    {
                                      borderBottom:
                                        index === length
                                          ? "none"
                                          : "3px solid grey",
                                    },
                                  ]}
                                >
                                  <View style={styles.armSectionHeader}>
                                    <Text style={styles.text}>
                                      {!findExpected(rm) && "(+)"}{" "}
                                      {rm.arm.r.name}
                                    </Text>
                                    {rm.observationArmMediaFiles.map((i) => (
                                      <ObsImage key={i.id} img={i} />
                                    ))}
                                  </View>
                                  <View style={styles.column}>
                                    {findMitigators(rm.arm).map((m, index) => {
                                      return (
                                        <View
                                          key={m.arm.m.name}
                                          style={[
                                            styles.armSectionHeader,
                                            {
                                              display: "flex",
                                              flexDirection: "column",
                                              alignItems: "center",
                                              borderBottom: getLastMit(
                                                rm.arm,
                                                index
                                              )
                                                ? "none"
                                                : "1px solid black",
                                            },
                                          ]}
                                          wrap={false}
                                        >
                                          <Text
                                            style={[
                                              styles.text,
                                              {
                                                width: "170px",
                                                color: "black",
                                              },
                                            ]}
                                          >
                                            {checkCorrection(m.arm) && (
                                              <View>
                                                <Image
                                                  src={checkbox}
                                                  style={{
                                                    width: "15px",
                                                    height: "15px",
                                                  }}
                                                />
                                              </View>
                                            )}
                                            {m.arm.m.name}{" "}
                                            {checkRootCauses(m) && (
                                              <Text
                                                style={{
                                                  fontFamily: "Helvetica-Bold",
                                                }}
                                              >
                                                (Root Cause)
                                              </Text>
                                            )}
                                          </Text>
                                          {m.observationArmMediaFiles.map(
                                            (i) => (
                                              <ObsImage key={i.id} img={i} />
                                            )
                                          )}
                                        </View>
                                      );
                                    })}
                                    {findUnselectedMitigators(rm.arm).map(
                                      (m, index) => {
                                        return (
                                          <View
                                            key={m.m.name}
                                            style={[
                                              styles.armSectionHeader,
                                              {
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                flexWrap: "wrap",
                                                borderBottom:
                                                  getLastUnselectedMit(
                                                    rm.arm,
                                                    index
                                                  )
                                                    ? "none"
                                                    : "1px solid black",
                                              },
                                            ]}
                                            wrap={false}
                                          >
                                            <View
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                              }}
                                              wrap={false}
                                            >
                                              {checkCorrection(m) && (
                                                <View>
                                                  <Image
                                                    src={checkbox}
                                                    style={{
                                                      width: "15px",
                                                      height: "15px",
                                                    }}
                                                  />
                                                </View>
                                              )}
                                              <Text
                                                style={[
                                                  styles.text,
                                                  {
                                                    width: "170px",
                                                    color: "red",
                                                  },
                                                ]}
                                              >
                                                {m.m?.name}{" "}
                                                {checkRootCauses(m) && (
                                                  <Text
                                                    style={{
                                                      fontFamily:
                                                        "Helvetica-Bold",
                                                    }}
                                                  >
                                                    (Root Cause)
                                                  </Text>
                                                )}
                                              </Text>
                                            </View>
                                            {getCorrection(m).map((c) => {
                                              if (
                                                c.observationArmMediaFiles
                                                  .length > 0
                                              ) {
                                                return c.observationArmMediaFiles.map(
                                                  (i) => (
                                                    <ObsImage
                                                      key={i.id}
                                                      img={i}
                                                    />
                                                  )
                                                );
                                              } else return null;
                                            })}
                                          </View>
                                        );
                                      }
                                    )}
                                  </View>
                                </View>
                              );
                            }
                          })
                      )}
                    </View>
                  </View>
                );
              })}
              {data.observation.observationArms.length > 0 ? (
                <View style={{ borderBottom: "4px solid black" }} />
              ) : null}
            </View>
          </View>
          <View
            style={{
              border: "3px solid black",
              marginTop: "20px",
              width: "120px",
              margin: "0 auto",
            }}
            wrap={false}
          >
            <Text
              style={[
                styles.text,
                {
                  padding: "5px",
                },
              ]}
            >
              Mitigator Legend:
            </Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                padding: "5px",
              }}
            >
              <Image
                src={checkbox}
                style={{
                  width: "15px",
                  height: "15px",
                }}
              />
              <Text
                style={[
                  styles.text,
                  {
                    color: "red",
                    padding: "0px",
                    marginLeft: "5px",
                  },
                ]}
              >
                Correction
              </Text>
            </View>{" "}
            <Text
              style={[
                styles.text,
                {
                  color: "red",
                  padding: "5px",
                },
              ]}
            >
              Unselected Mitigator
            </Text>
            <Text style={[styles.text, { padding: "5px" }]}>
              Selected Mitigator
            </Text>
          </View>
        </Page>
        {data.observation.observationMediaFiles.length > 0 ? (
          <Page>
            <View
              style={{
                padding: "10px",
                borderTop: "3px solid grey",
                margin: "20px auto",
                width: "95%",
              }}
            >
              <Text style={{ marginBottom: "20px" }}>INCLUDED IMAGES</Text>

              {data.observation.observationMediaFiles.map((oFile) => {
                const isImage = (file) => {
                  return ["png", "jpg", "jpeg"].some((v) => file.includes(v));
                };

                if (isImage(oFile.mediaFile.file)) {
                  return <ObsImage img={oFile} key={oFile.id} />;
                }
              })}
            </View>
          </Page>
        ) : null}
      </Document>
    );
  };

  const mobileView = (
    <Paper
      style={{
        width: "100%",
        height: "300px",
        marginTop: "20px",
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "300px" }}
      >
        <Typography>
          Cannot view PDF on mobile device. Download PDF in order to view.
        </Typography>
      </Grid>
    </Paper>
  );

  const sendEmail = async () => {
    if (data && arms) {
      let blobPdf = await pdf(PDFDocument()).toBlob();
      let file = new File([blobPdf], "pdf_observation", {
        type: "application/pdf",
      });

      sendEmailMutation({
        variables: {
          id: Number(id),
          file,
        },
      });
    } else
      onErrorFunc(
        "Please try again momentarily after all Observation data has loaded."
      );
  };

  const isOwner =
    data?.observation.submitter.username === currentUser?.currentUser.username;

  return (
    <Layout>
      <Grid container justifyContent="space-between" alignContent="center">
        <Typography
          sx={{ ...pageTitleStyles, fontSize: { xs: "2rem", md: "3rem" } }}
        >
          VIEW OBSERVATION.
        </Typography>
        <Button
          onClick={() => navigate("/view-obs")}
          style={{ color: "white" }}
        >
          GO BACK
        </Button>

        {loading || loadingArms ? (
          <Grid container justifyContent="center">
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <Grid container>
            <Grid
              item
              xs={12}
              container
              direction="row"
              alignItems="center"
              style={{ marginBottom: "20px" }}
            >
              {(isOwner || permissions.includes("EDIT_OTHER_OBSERVATION")) && (
                <Button
                  variant="contained"
                  color="yellow0"
                  onClick={navigateToEdit}
                >
                  EDIT
                </Button>
              )}
              <Button
                sx={{ mx: 2 }}
                variant="contained"
                color="secondary"
                onClick={sendEmail}
              >
                SEND TO EMAIL
              </Button>
              {data && arms && (
                <PDFDownloadLink
                  document={PDFDocument()}
                  fileName={`OBSERVATION_${data.observation.name}`.replace(
                    /[:<>*?".|\/\s]/g,
                    ""
                  )}
                  style={{ textDecoration: "none" }}
                >
                  {({ loading }) =>
                    loading ? (
                      <p style={{ color: "white" }}>Loading PDF...</p>
                    ) : (
                      <div>
                        <Button variant="contained" color="primary">
                          DOWNLOAD PDF
                        </Button>
                      </div>
                    )
                  }
                </PDFDownloadLink>
              )}
              <Button
                variant="contained"
                color="primary"
                sx={{ ml: 2 }}
                onClick={() => setOpen(true)}
              >
                VIEW IMAGES
              </Button>
              <ViewImages
                open={open}
                handleClose={() => setOpen(false)}
                images={images}
              />
            </Grid>
            <Grid item xs={12}>
              {data && arms && (
                <Grid>
                  {isTablet ? (
                    <>{mobileView}</>
                  ) : (
                    <>
                      <BrowserView>
                        {data?.observation && (
                          <PDFViewer width="100%" height="800px">
                            {PDFDocument()}
                          </PDFViewer>
                        )}
                      </BrowserView>
                      <MobileView>{mobileView}</MobileView>
                    </>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Layout>
  );
}
