import * as React from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import PropTypes from "prop-types";

import ViewObservedSite from "../ViewSite";

export default function Row({ row }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow
        hover
        onClick={() => setOpen(true)}
        key={row.id}
        style={{ cursor: "pointer" }}
      >
        <TableCell component="th" scope="row">
          {row.ownerSiteProject.site.name}
        </TableCell>
      </TableRow>
      <ViewObservedSite open={open} setOpen={setOpen} id={row.id} data={row} />
    </>
  );
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
};
